<template>
  <tr :data-object-id='department.id'>
    <td>{{department.name}}</td>
    <td>{{department.user_count}}</td>
    <td>
      <a href='#' @click.prevent='openModal' data-action='edit'>
        <i class='edit-icon'></i>
      </a>

      <a href='#' @click.prevent='deleteDepartment' data-action='delete' v-show='canDelete'>
        <i class='close-icon grey'></i>
      </a>
    </td>
    <department-modal
      v-if='modalOpen'
      :passed-department='department'
      @close='closeModal'/>
  </tr>
</template>
<script>
  import DepartmentModal from './DepartmentModal'
  export default {
    props: ['department'],
    data() {
      return { modalOpen: false }
    },
    methods: {
      openModal() {
        this.modalOpen = true
      },
      closeModal() {
        this.modalOpen = false
      },

      deleteDepartment() {
        if(confirm(this.t('components.management.department_row.delete_confirmation', {department_name: this.department.name }))) {
          this.$store.dispatch('deleteDepartment', this.department)
            .then(() => {
              this.$root.$emit(
                'flash',
                { notice: this.t('components.management.department_row.department_deleted', { department_name: this.department.name })}
              )
            })
            .catch(() => {
              this.$root.$emit(
                'flash',
                {
                  notice: this.t('components.management.department_row.department_deletion_error', { department_name: this.department.name }),
                  type: 'error'
                }
              )
            })
        }
      }
    },

    computed: {
      manager() {
        return this.$store.state.manager
      },

      canDelete() {
        return this.department.deletable
      }
    },

    components: {
      DepartmentModal
    }
  }
</script>
