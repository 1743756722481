<template>
  <div>
    <loading-indicator :visible='isLoading' />
    <router-view v-if='!isLoading'></router-view>
  </div>
</template>
<script>
  import router from './router'
  import store from './store'
  import LoadingIndicator from '../../utilityComponents/LoadingIndicator'
  export default {
    store: store,
    router: router,
    created() {
      this.$store.dispatch('initialize')
    },

    computed: {
      isLoading() {
        return this.$store.state.loading
      }
    },

    components: { LoadingIndicator }
  }
</script>
