import Chart from 'chart.js'
import { each, clone } from 'lodash'
const SingleValueDoughnutColors = {
  bad: {
    title: 'bad',
    min: 0,
    max: 30,
    color: '#EA0000'
  },
  could_be_better: {
    title: 'could be better',
    min: 30.0001,
    max: 40,
    color: '#F56300'
  },
  normal: {
    title: 'normal',
    min: 40.0001,
    max: 60,
    color: '#FFC600'
  },
  above_average: {
    title: 'above average',
    min: 60.0001,
    max: 70,
    color: '#C5D107'
  },
  good: {
    title: 'good',
    min: 70.0001,
    max: 100,
    color: '#8ADB0D'
  }
}

Chart.defaults.SingleValueDoughnut = clone(Chart.defaults.doughnut)
Chart.defaults.SingleValueDoughnut.legend = { display: false }
Chart.defaults.SingleValueDoughnut.tooltips = { enabled: false }

class SingleValueDoughnut extends Chart.controllers.doughnut {
  initialize (chart, datasetIndex) {
    let value = chart.config.data.datasets[0].data[0]
    chart.config.data.datasets[0].borderWidth = 0
    chart.config.data.datasets[0].backgroundColor = this.getDoughnutColor(value)

    chart.options.circumference =  value / 50 * Math.PI ,
    Chart.controllers.doughnut.prototype.initialize.call(this, chart, datasetIndex)
  }

  getCenterCoordinates () {
    let chart = this.chart
    let chartArea = chart.chartArea
    let opts = chart.options
    let arcOpts = opts.elements.arc
    let availableWidth = chartArea.right - chartArea.left - arcOpts.borderWidth
    let availableHeight = chartArea.bottom - chartArea.top - arcOpts.borderWidth
    return [ availableWidth / 2 , availableHeight / 2]
  }

  getDoughnutColor (value) {
    let color = '#000'

    each(SingleValueDoughnutColors, function(object, _){
      if(value > object.min && value <= object.max) {
        color = object.color
      }
    })
    return color
  }

  draw (ease) {
    let chart = this.chart
    let meta = this.getMeta()
    let chartArea = chart.chartArea
    let ctx = chart.chart.ctx
    let coordinates = this.getCenterCoordinates()
    let x = coordinates [0]
    let y = coordinates [1]
    let valuePrefix = chart.config.options.valuePrefix || ''
    let valuePostfix = chart.config.options.valuePostfix || ''
    let value = `${valuePrefix}${this.calculateTotal()}${valuePostfix}`
    let offsetBackgroundCircle = chart.config.options.offsetBackgroundCircle == undefined ? true : chart.config.options.offsetBackgroundCircle

    // Draw the full background circle
    ctx.beginPath();
    ctx.fillStyle = "#ECECEC";
    let backgroundCirlceWidth = chart.outerRadius - chart.innerRadius
    if(offsetBackgroundCircle){
      ctx.arc(x, y, chart.outerRadius - (backgroundCirlceWidth * 1.5), 0, 2 * Math.PI)
      ctx.arc(x, y, chart.innerRadius + (backgroundCirlceWidth * 0.5), 0, 2 * Math.PI, true)
    } else {
      ctx.arc(x, y, chart.outerRadius - backgroundCirlceWidth, 0, 2 * Math.PI)
      ctx.arc(x, y, chart.innerRadius + backgroundCirlceWidth, 0, 2 * Math.PI, true)
    }
    ctx.closePath();
    ctx.fill();
    ctx.save()

    Chart.controllers.doughnut.prototype.draw.call(this, ease)
  }

  update (reset) {
    let me = this;
    let chart = me.chart
    let chartArea = chart.chartArea
    let opts = chart.options
    let arcOpts = opts.elements.arc
    let availableWidth = chartArea.right - chartArea.left - arcOpts.borderWidth
    let availableHeight = chartArea.bottom - chartArea.top - arcOpts.borderWidth
    let minSize = Math.min(availableWidth, availableHeight)
    let offset = {
        x: 0,
        y: 0
      }

    let meta = me.getMeta()
    let cutoutPercentage = opts.cutoutPercentage
    let circumference = opts.circumference
    chart.borderWidth = 0
    chart.outerRadius = Math.max((minSize - chart.borderWidth) / 2, 0);
    chart.innerRadius = Math.max(cutoutPercentage ? (chart.outerRadius / 100) * (cutoutPercentage) : 0, 0);
    chart.radiusLength = (chart.outerRadius - chart.innerRadius) / chart.getVisibleDatasetCount();
    chart.offsetX = offset.x * chart.outerRadius;
    chart.offsetY = offset.y * chart.outerRadius;

    meta.total = me.calculateTotal();

    me.outerRadius = chart.outerRadius - (chart.radiusLength * me.getRingIndex(me.index));
    me.innerRadius = Math.max(me.outerRadius - chart.radiusLength, 0);

    Chart.helpers.each(meta.data, function(arc, index) {
      me.updateElement(arc, index, reset);
    })
  }
}
Chart.controllers.SingleValueDoughnut = SingleValueDoughnut
