<template>
  <div>
    <button @click.prevent='openModal' class='btn btn-primary btn-lg' :disabled='!nextSurveyDate'>{{buttonTitle}}</button>
    <learn-more-modal
      v-if='modalOpen'
      :next-survey-date='nextSurveyDate'
      @close='closeModal'
      @submitted='closeAndShowFlash'
      @failed='showErrorFlash'
      />
  </div>
</template>

<script>
  import LearnMoreModal from './LearnMoreModal'
  import { indexOf } from 'lodash'
  export default {
    props: ['buttonTitle', 'departments', 'nextSurveyDate'],

    created() {
      this.$store.state.feedbackDepartments = this.departments
    },

    data(){
      return {
        modalOpen: false,
      }
    },

    computed: {
      buttonDisabled() {
        let disabled = false
        this.focusGroupIds.forEach((id) => {
          if(indexOf(this.$store.state.queuedDepartmentIds, id.toString()) > -1) {
            disabled = true
            return
          }
        })
        return disabled
      }
    },

    methods: {
      openModal() {
        if(!this.nextSurveyDate) { return }
        this.modalOpen = true
      },
      closeModal() {
        this.modalOpen = false
      },
      closeAndShowFlash() {
        this.closeModal()
        this.$root.$emit('flash',
          {
            notice: this.t("components.analysis.components.learn_more_modal.success", { date: this.nextSurveyDate }),
            type: 'success'
          }
        )
      },

      showErrorFlash() {
        this.$root.$emit('flash',
          {
            notice: this.t("components.analysis.components.learn_more_modal.failed"),
            type: 'error'
          }
        )
      }
    },

    components: {
      LearnMoreModal
    }
  }
</script>
