<template>
  <div class='component-block-item feedback-row'>
    <div class='header-line' @click='toggleExpanded' :class='{opened: expanded}'>
      <div class="row">
        <div class="col-10">
          <div class="question-block ml-1">
            <div class='date'>
              {{l(cycle.cycle_date)}}
            </div>
            <div class='question'>
              {{cycle.question}}
            </div>
          </div>
        </div>
        <div class="col-2">
          <div class="row question-meta float-right mr-1">
            <div class='content'>
              <div class='count'>
                {{cycle.answer_count}}
              </div>
              <div class='sub-text'>{{t('analysis.components.show.responses')}}</div>
            </div>
            <i class="fa fa-chevron-down"></i>
          </div>
        </div>
      </div>
    </div>
    <div class='answer-list' v-if='expanded'>
      <div v-for='answer in cycle.answers' class='answer-item'>
        <div class='feedback-answer-block'>
          <div class="row">
            <div class="col-md-9">
              <div class='question'>
                {{answer.answer}}
              </div>
            </div>
            <div class="col-md-3">
              <div class='answer-box'>
                {{answer.department}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    props: ['cycle'],
    data() {
      return {
        expanded: false
      }
    },
    computed: {
      answerCount() {
        return this.component.answers.length
      }
    },
    methods: {
      toggleExpanded() {
        this.expanded = !this.expanded
      }
    }
  }
</script>
