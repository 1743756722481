<template>
  <div class='improved-select' :id='id'>
    <v-select
      :value.sync='selectValue'
      @input='emitValue'
      :options='options'
      :placeholder='placeholder'
      :disabled='disabled'
      :clearable='clearable'/>
    <input type='hidden' :value='value' :name='name'  />
  </div>
</template>
<script>
  import vSelect from 'vue-select'

  export default {
    props: ['options', 'value', 'id', 'name', 'placeholder', 'clearable', 'disabled'],
    computed: {
      selectValue() {
        return this.options.find(o => o.value == this.value) || null
      }
    },

    methods: {
      emitValue(val) {
        let value = typeof val == 'object' && val != null ? val.value : val
        if(val) {
          this.$emit('input', value)
        } else if(this.clearable) {
          this.$emit('input', null)
        }
      }
    },

    components: { vSelect }
  }
</script>
