<template>
  <modal @close='$emit("close")' class-name='big'>
    <div slot='header' class='small-header'>Overall progress description</div>
    <div slot='body'>
      <div class='top-text'>
        <div class='row'>
          <div class='col-3'>
            <div class='summary-overview-image' :style='{borderColor: data.background_color}'>
              <img :src='data.image_url' />
              <div class='summary-overview-image-badge' :style='{backgroundColor: data.background_color}'>
                <i class='fa' :class='data.badge'></i>
              </div>
            </div>
          </div>
          <div class='col-9'>
            <div class='heading'>{{data.heading}}</div>
            <div class='sub-text'>{{data.header_sentence}}</div>
          </div>
        </div>
      </div>
      <div class='text-block border-top'>
        {{data.engagement_satisfaction_sentence}}
      </div>
      <div class='text-block'>
        {{data.culture_sentence}}
      </div>
      <div class='text-block border-bottom'>
        {{data.loyalty_sentence}}
      </div>
    </div>
    <div slot='footer' class='text-center w-100 my-2'>
      <a href="/analysis/all_results" class="btn btn-primary btn-bordered btn-lg">All Results</a>
      <a href="#" class="btn btn-primary btn-lg" @click.prevent='$emit("close")'>Ok</a>
    </div>
  </modal>
</template>
<script>
  import Modal from '../../utilityComponents/Modal'
  export default {
    props: ['data'],

    components: { Modal }
  }
</script>
<style>
  .small-header {
    font-size: 18px;
    text-transform: none;
  }

  .text-block {
    color: #6A6C6E;
    font-size: 13px;
    margin-bottom: 20px;
  }

  .text-block.border-top {
    padding-top: 30px;
    border-top: 1px solid #D4D4D4;
  }

  .text-block.border-bottom {
    padding-bottom: 30px;
    border-bottom: 1px solid #D4D4D4;
  }

  .summary-overview-image {
    width: 120px;
    height: 120px;
    border: 4px solid green;
    border-radius: 95px;
    margin-bottom: 10px;
    position: relative;
  }

  .summary-overview-image img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }

  .summary-overview-image-badge {
    position: absolute;
    top: 0;
    right: 0;
    height: 30px;
    width: 30px;
    border-radius: 15px;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.5);
  }

  .summary-overview-image-badge .fa {
    color: white;
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
  }

  .top-text .heading {
    margin-top: 40px;
    font-size: 20px;
    font-weight:bold;
  }

  .top-text .sub-text {
    font-size: 13px;
    color: #ACAFB3;
  }
</style>
