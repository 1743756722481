<template>
  <div class="table-cell">
    <div :class="['nps', classValue]">
      <div class="nps-value">{{ value }}</div>
      <div class="nps-bar-container">
        <div class="nps-bar" :style="{ backgroundColor: colorValue, height: heightValue }"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['value', 'colorValue', 'classValue', 'heightValue']
}
</script>