import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
Vue.use(Vuex)
const AUTH_TOKEN = $('[name="csrf-token"]')[0].content

export default new Vuex.Store({
  state: {
    managers: [],
    roleOptions: [],
    canCreate: false,
    canImport: false,
    formErrors: {},
    analysisGroups: [],
    visibleAnalysisGroups: [],
    departmentOptions: [],
    visibleDepartmentOptions: [],
    loading: true
  },

  mutations: {
    addOrReplaceManager(state, manager) {
      let managerIndex = state.managers.findIndex(m => m.id == manager.id)

      if(managerIndex > -1) {
        state.managers.splice(managerIndex, 1, manager)
      } else {
        state.managers.push(manager)
      }
    },

    removeManager(state, manager) {
      let managerIndex = state.managers.findIndex(m => m.id == manager.id)

      if(managerIndex > -1) {
        state.managers.splice(managerIndex, 1)
      }
    },

    setManagerRolesSelect(state, roles) {
      state.roleOptions = roles
    },

    setCanCreate(state, canCreate) {
      state.canCreate = canCreate
    },

    setCanImport(state, canImport) {
      state.canImport = canImport
    }
  },

  actions: {
    initialize({ dispatch, state }) {
      return Promise.all([
        dispatch('fetchManagers'),
        dispatch('fetchAnalysisGroups'),
        dispatch('fetchVisibleAnalysisGroups'),
        dispatch('fetchDepartments'),
        dispatch('fetchVisibleDepartments')
      ]).then(() => state.loading = false)
    },

    fetchAnalysisGroups({ state }) {
      return new Promise((resolve, reject) => {
        axios.get('/admin/analysis_groups.json?all=true').
        then(({ data }) => {
          state.analysisGroups = data
          resolve(data)
        }).catch(reject)
      })
    },

    fetchVisibleAnalysisGroups({ state }) {
      return new Promise((resolve, reject) => {
        axios.get('/admin/analysis_groups.json').
        then(({ data }) => {
          state.visibleAnalysisGroups = data
          resolve(data)
        }).catch(reject)
      })
    },

    fetchDepartments({ state }) {
      return new Promise((resolve, reject) => {
        axios.get('/admin/departments.json?all=true').
          then(({ data }) => {
            state.departmentOptions = data.map( d => { return { label: d.name, value: d.id } })
            resolve(data)
          }).catch(reject)
      })
    },

    fetchVisibleDepartments({ state }) {
      return new Promise((resolve, reject) => {
        axios.get('/admin/departments.json').
        then(({ data }) => {
          state.visibleDepartmentOptions = data.map( d => { return { label: d.name, value: d.id } })
          resolve(data)
        }).catch(reject)
      })
    },

    fetchManagers({ state }) {
      return new Promise((resolve, reject) => {
        axios.get('/admin/managers.json')
          .then(({ data }) => {
            state.managers = data
            resolve(data)
          }).catch(reject)
      })
    },

    persistManager({ state, commit }, manager) {
      return new Promise((resolve, reject) => {
        axios({
          url: `/admin/managers/${manager.id || ''}`,
          method: manager.id ? 'PUT' : 'POST',
          data: {
            authenticity_token: AUTH_TOKEN,
            manager: manager
          }
        })
        .then(({ data }) => {
          commit('addOrReplaceManager', data)
          resolve(data)
        })
        .catch(({ response }) => {
          state.formErrors = response.data
        })
      })
    },

    deleteManager({ commit }, manager) {
      return new Promise((resolve, reject) => {
        axios({
          url: `/admin/managers/${manager.id}`,
          method: 'DELETE',
          data: { authenticity_token: AUTH_TOKEN }
        })
        .then((response) => {
          resolve(response)
          commit('removeManager', manager)
        })
        .catch(reject)
      })
    }
  }
})
