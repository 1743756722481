<template>
  <div class='test-survey-guide-container'>
    <div v-if='this.testSurveyStage == 1' class='row test-survey-guide-stage-1 pr-3'>
      <div class='col-sm-5 offset-2 pl-3'>
        <div class='guide-title'>{{ t('funnel.test_survey.send_survey_stage_1_title') }}</div>
        <div class='guide-text-stage-1'>
          <div>
            {{ t('funnel.test_survey.send_survey_stage_1_text_part_1') }}
            <span class='enme'>
              ENME
            </span>
            {{ t('funnel.test_survey.send_survey_stage_1_text_part_2') }}
          </div>
        </div>
      </div>
      <div class='col-sm-5 button-container'>
        <a id='submit' href='/admin/test_survey/send_test_survey' class='btn btn-warning submit-button'>{{ t('funnel.test_survey.send_test_survey_link') }}</a>
      </div>
    </div>
    <div v-if='this.testSurveyStage == 2' class='row test-survey-guide-stage-2 pr-3'>
      <div class='col-sm-7 pl-3'>
        <div class='guide-text-stage-2'>
          <span class='enme'>
            {{ t('funnel.test_survey.send_survey_stage_2_text_part_1') }}
          </span>
          {{ t('funnel.test_survey.send_survey_stage_2_text_part_2') }}
          {{ t('funnel.test_survey.send_survey_stage_2_text_part_3') }}
          {{ t('funnel.test_survey.send_survey_stage_2_text_part_4') }}
          {{ t('funnel.test_survey.send_survey_stage_2_text_part_5') }}
        </div>
      </div>
      <div class='col-sm-5 button-container'>
        <a id='submit' href='/admin/test_survey/complete_setup' class='btn btn-warning submit-button'>{{ t('funnel.test_survey.send_survey_link') }}</a>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  const AUTH_TOKEN = $('[name="csrf-token"]')[0].content

  export default {
    props: ['testSurveyStage'],

    data() {
      return {
        headerHeight: 0
      }
    },

    mounted() {
      this.$nextTick(() => {
        this.headerHeight = document.getElementById('header-row').clientHeight
        window.addEventListener('resize', this.assignHeaderHeight)
      })
    },

    destroyed() {
      window.removeEventListener('resize', this.assignHeaderHeight)
    },

    watch: {
      headerHeight(newHeight, oldHeight) {
        document.getElementById('main').style.paddingTop = (newHeight + 30) + 'px'
        document.getElementById('logo-wrapper').style.height = newHeight + 'px'
      }
    },

    methods: {
      assignHeaderHeight(event) {
        if (event.type == 'resize') {
          this.headerHeight = document.getElementById('header-row').clientHeight
        }
      },

      sendTestSurvey({}) {
        return new Promise((resolve, reject) => {
          axios({
            url: '/admin/test_survey/send_test_survey',
            method: 'GET',
            data: { authenticity_token: AUTH_TOKEN }
          })
          .then(() => {
            resolve()
            window.location = '/'
          })
          .catch(reject)
        })
      }
    }
  }
</script>

<style>
  .test-survey-guide-container {
    width: 100%;
    padding: 5px;
    p {
      font-size: 11px;
      line-height: 1.5;
      margin-bottom: 0;
    }

    .test-survey-guide-stage-1 {
      justify-content: right;
    }

    .submit-button {
      display: block;
      font-size: 14px;
      font-weight: bold;
      padding: 15px 0 16px;
      text-transform: none;
    }

    .guide-title {
      font-weight: bold;
      line-height: 1.6;
    }

    .guide-text-stage-1 {
      display: flex;
      flex-direction: column;
      align-self: flex-start;
      font-size: 11px;
      line-height: 1.6;
    }

    .guide-text-stage-2 {
      display: flex;
      flex-direction: column;
      align-self: flex-start;
      font-size: 11px;
      line-height: 1.4;
    }

    .button-container {
      height: 100%;
      align-self: center;
    }

    .underlined-link {
      line-height: 2;
      text-decoration: underline;
      color: #56bdc6;
      font-weight: bold;
    }
  }

</style>
