import '../config/BenchmarkLineChartConfig'

import Chart from 'chart.js'
import { keys, values } from 'lodash'
import { tooltipFunction } from '../config/helpers.js'
import 'chartjs-plugin-deferred/src/plugin.js'

// Allows to set chart area background
Chart.pluginService.register({
  beforeDraw: function (chart, easing) {
    if (chart.config.options.chartArea && chart.config.options.chartArea.backgroundColor) {
      let helpers = Chart.helpers
      let ctx = chart.chart.ctx
      let chartArea = chart.chartArea

      ctx.save()
      ctx.fillStyle = chart.config.options.chartArea.backgroundColor
      ctx.fillRect(chartArea.left, chartArea.top, chartArea.right - chartArea.left, chartArea.bottom - chartArea.top)
      ctx.restore()
    }
  }
});

export default class BenchmarkLineChart {
  constructor (domObject, keyValueSet = {}, benchmarkValue = 0) {
    let labels = keys(keyValueSet)
    let dataValues = values(keyValueSet)
    this.chart = new Chart(domObject, {
      type: 'BenchmarkLineChart',
      responsive: true,
      data: {
        labels: labels,
        datasets: [{
          fill: true,
          lineTension: 0.1,
          backgroundColor: "#D1D3DA",
          benchmark: benchmarkValue,
          showBenchmark: true,
          borderColor: "#9AA5C5",
          borderCapStyle: 'butt',
          borderWidth: 2,
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBackgroundColor: "#9AA5C5",
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: "#000047",
          pointHoverBorderColor: "#202F56",
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: dataValues,
          spanGaps: true,
        }]
      },
      options: {
        aspectRatio: 4,
        legend: { display: false },
        tooltips: {
          enabled: false,
          custom: tooltipFunction
        },
        chartArea: { backgroundColor: '#fff' },
        scales: {
          yAxes: [{
            gridLines: { display: false },
            ticks: {
              callback (value) { return value > 0 ? value : '' }, // Could not find easier way not to show 0 label
              beginAtZero: true,
              min: 0,
              max: 100,
              fixedStepSize: 50,
              maxTicksLimit: 2
            },
          }],
          xAxes: [{
            type: 'time',
            gridLines: { display: false },
            time: {
              displayFormats: { day: 'MMM YYYY' },
              unit: 'day',
              unitStepSize: 30,
              round: 'day',
              tooltipFormat: 'DD.MM.YYYY'
            }
          }]
        },
        plugins: {
          deferred: true
        }
      }
    })
  }
}
