<template>
  <div class='text-block'>
    <h3>{{heading}}</h3>
    <p>{{text}}</p>
  </div>
</template>
<script>
  export default {
    props: ['heading', 'text']
  }
</script>
<style scoped>
  .text-block {
    margin: 40px 0;
  }

  h3 {
    font-size: 1.5rem;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 20px;
  }
</style>
