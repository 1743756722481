<template>
  <modal @close='$emit("close")'>
    <h6 slot="header" class="m-auto">{{this.t('components.ad_hoc_surveys.send_survey_modal.send')}}</h6>
    <div slot="body">
      <b>{{this.t('components.ad_hoc_surveys.send_survey_modal.select_title')}}</b>
      <multiple-departments-select
        class="departments-select-holder mt-3"
        v-model="selectedDepartments"
        :analysis-groups="analysisGroups"
        :department-options="departmentOptions"
        :analysis-groups-title="t('components.ad_hoc_surveys.send_survey_modal.groups')"
        :label="t('components.ad_hoc_surveys.send_survey_modal.departments')"
        :errors='errors.teams && [t("components.ad_hoc_surveys.send_survey_modal.select_recipients")]'
      />
    </div>

    <div slot="footer" class="text-center w-100 my-2">
      <datetime
        id="choose-date"
        type="datetime"
        v-model="deliveryDatetime"
        format="dd.MM.yyyy HH:mm"
        :min-datetime="moment().toISOString()"
        ref="datepicker"
        input-class="vdatetime-input-custom"
      ></datetime>
      <a href class="btn btn-primary btn-bordered btn-md" @click.prevent='$emit("close")'>Cancel</a>
      <a
        href
        class="btn btn-primary btn-md"
        @click.prevent='sendSurvey("now")'
        data-action="send_now"
      >{{ this.t('components.ad_hoc_surveys.send_survey_modal.delivery_now') }}</a>
      <a
        href
        class="btn btn-primary btn-md"
        @click.prevent="test"
        data-action="send_later"
      >{{ this.t('components.ad_hoc_surveys.send_survey_modal.delivery_later') }}</a>
    </div>
  </modal>
</template>
<script>
import axios from "axios";
import { AUTH_TOKEN } from "../helpers/globals";
import Modal from "../utilityComponents/Modal";
import MultipleDepartmentsSelect from "../utilityComponents/MultipleDepartmentsSelect";
import Vue from "vue";
import { Datetime } from "vue-datetime";
import moment from "moment";
import "vue-datetime/dist/vue-datetime.css";

Vue.use(Datetime);

export default {
  components: { Modal, MultipleDepartmentsSelect, datetime: Datetime },
  props: ["survey"],
  data() {
    return {
      moment: moment,
      selectedDepartments: [],
      selectedValue: "now",
      deliveryDatetime: null,
      errors: {
        teams: false,
        dateTime: false
      }
    };
  },

  computed: {
    departmentOptions() {
      return this.$store.state.departments.map(d => {
        return { label: d.name, value: d.id };
      });
    },
    analysisGroups() {
      return this.$store.state.analysisGroups;
    },
    departments() {
      return this.$store.state.departments;
    }
  },

  watch: {
    deliveryDatetime() {
      if (this.deliveryDatetime && !this.$refs.datepicker.isOpen)
        this.sendSurvey("later");
    }
  },
  methods: {
    test() {
      this.$refs.datepicker.open({ target: { blur: () => {} } });
    },
    validate() {
      this.selectedDepartments.length > 0
        ? (this.errors.teams = false)
        : (this.errors.teams = true);

      return Object.values(this.errors).indexOf(true) > -1;
    },

    sendSurvey(selectedValue) {
      if (!this.validate()) {
        axios({
          url: `/admin/custom_surveys/${this.survey.id}/send_survey`,
          method: "POST",
          data: {
            authenticity_token: AUTH_TOKEN,
            survey_id: this.survey.id,
            department_ids: this.selectedDepartments.map(d => d.value),
            send_at: selectedValue === "now" ? moment() : null,
            sending_scheduled_at:
              selectedValue === "later" ? this.deliveryDatetime : null
          }
        })
          .then(({ data }) => {
            Vue.set(this.survey, "sent_at", data.sent_at);
            Vue.set(
              this.survey,
              "sending_scheduled_at",
              data.sending_scheduled_at
            );
            Vue.set(this.survey, "can_update", data.can_update);
            Vue.set(this.survey, "can_destroy", data.can_destroy);
            Vue.set(this.survey, "can_send_survey", data.can_send_survey);
            this.$emit("update", data);
            this.$router.go()
          })
          .catch(response => {
            alert(response);
          });
      } else return;
    }
  }
};
</script>
<style scoped>
  ::v-deep .date-error {
    margin-top: 3px;
    color: #ea0000;
  }

  ::v-deep input.vdatetime-input-custom {
    display: none;
  }
</style>
 