import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
Vue.use(Vuex)
import {
  findIndex
} from 'lodash'

const AUTH_TOKEN = $('[name="csrf-token"]')[0].content

export default new Vuex.Store({
  state: {
    surveys: [],
    analysisGroups: [],
    departments: [],
    filteredDepartmentIds: [],
    loading: true,
    localeOptions: []
  },
  mutations: {
    removeSurvey(state, id) {
      const index = state.surveys.findIndex(ag => ag.id == id)
      state.surveys.splice(index, 1)
    },

    addSurvey(state, ad_hoc_survey) {
      const surveyIndex = findIndex(state.surveys, (d) => d.id == ad_hoc_survey.id)
      if (surveyIndex > -1) {
        state.surveys.splice(surveyIndex, 1, ad_hoc_survey)
      } else {
        state.surveys.push(ad_hoc_survey)
      }
    },
  },
  actions: {
    initialize({
      dispatch,
      state
    }) {
      return Promise.all([
        dispatch('fetchSurveys'),
        dispatch('fetchAnalysisGroups'),
        dispatch('fetchDepartments')
      ]).then(() => state.loading = false)
    },


    postFilterData({ commit, state }, data) {
      const departmentIds = data.department_ids

      state.filteredDepartmentIds = departmentIds
      
      axios.post(
        '/sessions/filtered_department_ids',
        {
          authenticity_token: AUTH_TOKEN,
          department_ids: departmentIds
        }
      )
    },

    fetchSurveys({
      state
    }) {
      return new Promise((resolve, reject) => {
        axios.get('/admin/custom_surveys.json').
        then(({
          data
        }) => {
          state.surveys = data
          resolve(data)
        }).catch(reject)
      })
    },

    fetchSurveyStat({ state }, id) {
      return axios.get(`/admin/custom_surveys/${id}.json`)
    },
    fetchAnalysisGroups({
      state
    }) {
      return new Promise((resolve, reject) => {
        axios.get('/admin/analysis_groups.json').
        then(({
          data
        }) => {
          state.analysisGroups = data
          resolve(data)
        }).catch(reject)
      })
    },

    fetchDepartments({
      state
    }) {
      return new Promise((resolve, reject) => {
        axios.get('/admin/departments.json').
        then(({
          data
        }) => {
          state.departments = data
          resolve(data)
        }).catch(reject)
      })
    },


    persistSurvey({
      state,
      commit,
      dispatch
    }, ad_hoc_survey) {
      return new Promise((resolve, reject) => {
        axios({
            method: ad_hoc_survey.id ? 'PUT' : 'POST',
            url: `/admin/custom_surveys/${ad_hoc_survey.id || ''}`,
            data: {
              authenticity_token: AUTH_TOKEN,
              survey: ad_hoc_survey
            },
          })
          .then(({
            data
          }) => {
            commit('addSurvey', data)
            resolve(data)

          })
          .catch(reject)
      })
    },

    deleteSurvey({
      commit
    }, id) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'DELETE',
          url: `/admin/custom_surveys/${id}`,
          data: {
            authenticity_token: AUTH_TOKEN
          }
        }).then(() => {
          commit('removeSurvey', id)
          resolve()
        }).catch(({
          response
        }) => {
          reject()
          throw response
        })
      })
    }
  }
})