<template>
  <div class='col-12'>
    <div class='card card-dashboard mb-4'>
      <div class='card-header'>
        <div class='row'>
          <div class='col-md-6'>
            <strong class='text-uppercase'>{{t('components.analysis.summary.suggestions_list.title')}}</strong>
          </div>
          <div class='col-md-6 text-md-right'>
            <a class='summary-overview-link' :href='componentsLink'>
              {{t('components.analysis.summary.suggestions_list.see_more')}}
            </a>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class='loading-indicator' v-if='isLoading'></div>
        <div v-if='!isLoading && suggestions.length > 0' class='suggestion-list'>
          <div class='row'>
            <suggestion-item
              v-for='suggestion in suggestions'
              :item='suggestion'
              :key='suggestion.id'
              @click='openModal'
              @onRemove='destroyFavoriteSuggestion' />
          </div>
        </div>

        <div v-if='!isLoading && suggestions.length == 0' class='summary-list-placeholder text-center'>
          <a href='/analysis/components' class='fa fa-star-o fa-5x mb-2'></a>
          <strong class='summary-list-placeholder-heading mb-1'>{{t('components.analysis.summary.suggestions_list.placeholder_heading')}}</strong>
          <p class='summary-list-placeholder-text mb-0'>{{t('components.analysis.summary.suggestions_list.placeholder_text')}}</p>
        </div>

        <div v-if='!isLoading && isDemo' class='demo-improvement-suggestion-list d-flex justify-content-between'>
          <demo-suggestion-item
            v-for='demoSuggestion in demoSuggestions'
            :item='demoSuggestion'
            :key='demoSuggestion.id'
            @click='openModal' />
        </div>
      </div>
    </div>
    <improvement-suggestion-modal
      v-if='modalOpen'
      :suggestion='modalSuggestion'
      @close='closeModal'/>
  </div>
</template>

<script>
  import SuggestionItem from './SuggestionItem'
  import DemoSuggestionItem from './DemoSuggestionItem'
  import ImprovementSuggestionModal from '../components/ImprovementSuggestionModal'
  import axios from 'axios'
  import { AUTH_TOKEN } from '../../helpers/globals'
  export default {
    props: ['componentsLink', 'firstSurveySent'],

    mounted() {
      this.fetchFavoriteSuggestions()
    },

    data() {
      return {
        suggestions: [],
        demoSuggestions: [],
        isLoading: true,
        modalOpen: false,
        modalSuggestion: null,
        isDemo: false
      }
    },

    methods: {
      fetchFavoriteSuggestions() {
        axios.get('/manager_suggestions').
          then(({ data }) => {
            if (this.firstSurveySent == 'true') {
              this.suggestions = data
              this.isLoading = false
              this.isDemo = false
            } else {
              this.demoSuggestions = data
              this.isLoading = false
              this.isDemo = true
            }
          })
      },

      destroyFavoriteSuggestion(suggestionId) {
        axios({
          url: '/manager_suggestions',
          method: 'DELETE',
          data: {
            authenticity_token: AUTH_TOKEN,
            suggestion_id: suggestionId
          }
        }).
        then(() => this.removeSuggestion(suggestionId))
      },

      removeSuggestion(id) {
        let suggestionIndex = this.suggestions.findIndex((s) => s.id == id)
        this.suggestions.splice(suggestionIndex, 1)
      },

      closeModal() {
        this.modalOpen = false
        this.modalSuggestion = null
      },

      openModal(suggestion) {
        this.modalOpen = true
        this.modalSuggestion = suggestion
      }
    },

    components: {
      SuggestionItem,
      DemoSuggestionItem,
      ImprovementSuggestionModal
    }
  }
</script>
