<template>
  <div class='card card-dashboard improvement-suggestion-item' :class='{locked: isLocked}'>
    <div class='improvement-suggestion-image-holder'>
      <img :src='suggestion.picture_url' />
      <i v-if='isLocked' class="fa fa-lock fa-4x"></i>
    </div>
    <i class='fa fa-3x'
      v-if='isVisible'
      :class='[{"fa-star": suggestion.is_favorite}, {"fa-star-o": !suggestion.is_favorite}]'
      @click='toggleFavoriteSuggestion'></i>

    <h5 class="improvement-suggestion-title">
      {{suggestion.title || this.t('components.analysis.components.improvement_suggestion.locked_in_trial')}}
    </h5>

    <a v-if='isVisible' href='#' class='improvement-suggestion-link' @click.prevent='emitReadMore'>
      {{this.t('components.analysis.components.improvement_suggestion.read_more')}}
    </a>
  </div>
</template>
<script>
  import axios from 'axios'
  import { AUTH_TOKEN } from '../../helpers/globals'

  export default {
    props: ['suggestion'],

    computed: {
      isVisible() {
        return !!this.suggestion.title
      },

      isLocked() {
        return !this.isVisible
      }
    },

    methods: {
      emitReadMore() {
        if(this.isLocked) { return }
        this.$emit("onReadMore", this.suggestion)
      },

      toggleFavoriteSuggestion() {
        if(this.suggestion.is_favorite) {
          this.removeFromFavorites()
        } else {
          this.addToFavorites()
        }
      },

      addToFavorites() {
        axios({
          url: '/manager_suggestions',
          method: 'POST',
          data: {
            authenticity_token: AUTH_TOKEN,
            suggestion_id: this.suggestion.id
          }
        }).
        then(({ data }) => this.$emit('update', data))
      },

      removeFromFavorites() {
        axios({
          url: '/manager_suggestions',
          method: 'DELETE',
          data:{
            authenticity_token: AUTH_TOKEN,
            suggestion_id: this.suggestion.id
          }
        })
        .then(({ data }) => this.$emit('update', data))
      }
    }
  }
</script>
