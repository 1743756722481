import '../config/BenchmarkLineChartConfig'

import Chart from 'chart.js'
import { keys, values, cloneDeep } from 'lodash'
import { tooltipFunction } from '../config/helpers.js'
import 'chartjs-plugin-deferred/src/plugin.js'

// Allows to set chart area background
Chart.pluginService.register({
  beforeDraw: function (chart, easing) {
    if (chart.config.options.chartArea && chart.config.options.chartArea.backgroundColor) {
      let helpers = Chart.helpers
      let ctx = chart.chart.ctx
      let chartArea = chart.chartArea

      ctx.save()
      ctx.fillStyle = chart.config.options.chartArea.backgroundColor
      ctx.fillRect(chartArea.left, chartArea.top, chartArea.right - chartArea.left, chartArea.bottom - chartArea.top)
      ctx.restore()
    }
  }
});

export default class MultiLineChart {
  constructor (domObject, dataSet = [], labels = []) {
    this.dataLength = labels.length
    this.chart = new Chart(domObject, {
      type: 'BenchmarkLineChart',
      responsive: true,

      data: {
        labels: labels,
        datasets: this.normalizedDataSet(dataSet)
      },
      options: {
        legend: { display: false },
        tooltips: {
          enabled: false,
          custom: tooltipFunction
        },
        chartArea: { backgroundColor: '#F5F5F5' },
        scales: {
          yAxes: [{
            gridLines: { display: false },
            ticks: {
              callback (value) { return value > 0 ? value : '' }, // Could not find easier way not to show 0 label
              beginAtZero: true,
              min: 0,
              max: 100,
              fixedStepSize: 50,
              maxTicksLimit: 2
            },
          }],
          xAxes: [{
            type: 'time',
            gridLines: { display: false },
            time: {
              displayFormats: { day: 'MMM YYYY' },
              unit: 'day',
              unitStepSize: 30,
              round: 'day',
              tooltipFormat: 'DD.MM.YYYY'
            }
          }]
        },
        plugins: {
          deferred: true
        }
      }
    })
  }
  normalizedDataSet(rawDataSet) {
    let dataSet = cloneDeep(rawDataSet)
    return dataSet.map( (set) => {
      return {
        fill: false,
        lineTension: 0.1,
        backgroundColor: '#fff',
        borderColor: set.color,
        color: set.color, // Custom attribute
        benchmark: set.benchmark, // Custom attribute
        showBenchmark: set.showBenchmark, // Custom attribute
        benchmarkColor: set.benchmarkColor, // Custom attribute
        borderCapStyle: 'butt',
        borderWidth: 2,
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBackgroundColor: set.color,
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: set.color,
        pointHoverBorderColor: set.color,
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: this.normalizedValues(set.values),
        spanGaps: true,
      }
    })
  }
  normalizedValues(valueSet) {
    if(this.dataLength > valueSet.length) {
      let difference = this.dataLength - valueSet.length
      let fillerArray = new Array(difference).fill(0)
      valueSet.unshift(...fillerArray)
      return valueSet
    } else {
      return valueSet
    }
  }
  update(newDataSet, labels) {
    this.chart.data.datasets = this.normalizedDataSet(newDataSet)
    this.chart.data.labels = labels
    this.chart.update()

  }
}
