<template>
  <div id='onboarding-progress' class="onboarding-progress mb-5">
    <template v-for='stage in stages'>
      <div class='stage-item' :class='{active: activeStage(stage)}'>
        {{stageTranslation(stage)}}
      </div>
    </template>
  </div>
</template>
<script>
  import { stageMap } from './helpers'
  export default {
    computed: {
      stages() {
        return stageMap
      }
    },
    methods: {
      stageTranslation(stage) {
        // downcase capital letters, add underscore before them, remove first underscore (before the first letter)
        const underscoreStage = stage.replace(/([A-Z])/g, (x,y) => {return "_" + y.toLowerCase()} ).replace(/^_/, "")
        return this.t(`components.onboarding.progress_bar.${underscoreStage}`)
      },

      activeStage(stage) {
        return this.$store.state.currentStage == stageMap.findIndex(s => s == stage)
      }
    }
  }
</script>
