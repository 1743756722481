<template>
  <div class='col-12'>
    <a class='summary-overview-link d-inline-block mb-3' href='#' @click.prevent='toggleChartBlock'>
      {{linkText}}
    </a>
    <div v-if='lineChartsVisible' class='row'>
      <div v-for='metric in metrics' class='col-md-6'>
        <benchmark-line-chart :title='metric.name' :benchmark='metric.benchmark' :data='metric.data' />
      </div>
    </div>
  </div>
</template>
<script>
  import BenchmarkLineChart from '../../charts/BenchmarkLineChart'
  import axios from 'axios'
  export default {
    props: ['departmentIds'],

    created() {
      this.fetchMetricsData()
    },

    data() {
      return {
        lineChartsVisible: false,
        metrics: []
      }
    },

    computed: {
      linkText() {
        if(this.lineChartsVisible) {
          return this.t('components.analysis.summary.line_chart_metrics_block.hide_charts')
        } else {
          return this.t('components.analysis.summary.line_chart_metrics_block.show_charts')
        }
      }
    },

    methods: {
      toggleChartBlock() {
        this.lineChartsVisible = !this.lineChartsVisible
      },

      fetchMetricsData() {
        axios.get('/analysis/metrics', {
          params: {
            department_ids: this.departmentIds
          }
        }).
          then(({ data }) => this.metrics = data)
      }
    },
    components: { BenchmarkLineChart }
  }
</script>
