<template>
  <div class='answer-comments-container'>
    <span class='list-toggle-link pointer' @click='toggleList'>
      {{toggleLinkText}}
    </span>
    <div class='answer-comments-list' v-show='showList'>
      <div class='comments-table-header'>
        <div class='date'>
          {{t('components.analysis.components.answer_comments_container.date')}}
        </div>
        <div class='comment'>
          {{t('components.analysis.components.answer_comments_container.comment')}}
        </div>
        <div class='value'>
          {{t('components.analysis.components.answer_comments_container.value')}}
        </div>
        <div class='department'>
          {{t('components.analysis.components.answer_comments_container.department')}}
        </div>
      </div>
      <answer-comment v-for='comment in sortedComments' :comment='comment' :key='comment.id' />
    </div>
  </div>
</template>
<script>
  import axios from 'axios'
  import { AUTH_TOKEN } from '../../helpers/globals'

  import AnswerComment from './AnswerComment'

  export default {
    props: ['comments', 'allExpanded'],
    data() {
      return {
        showList: false,
        allExpandedData: this.allExpanded
      }
    },
    watch: {
      allExpandedData: {
        handler(newVal, oldVal) {
          if (newVal){
            this.toggleList()
          }
        },
        immediate: true
      }
    },

    computed: {
      toggleLinkText() {
        if(this.showList) {
          return this.t('components.analysis.components.answer_comments_container.hide_comments')
        } else {
          return this.t('components.analysis.components.answer_comments_container.show_comments')
        }
      },

      sortedComments() {
        return this.comments.sort((c1, c2) => new Date(c2.cycle_date) - new Date(c1.cycle_date)) //Desc by date
      }
    },

    methods: {
      toggleList() {
        axios({
          url: `/analysis/components/${this.$store.state.componentId}/read_comments`,
          method: 'POST',
          data: {
            authenticity_token: AUTH_TOKEN,
            answers_ids: this.comments.map(c => c.id)
          }
        }).
        then(({ data }) => {
          setTimeout(() => {
            this.$store.dispatch('fetchQuestionStatistics')
          }, 3000)
        })

        this.showList = !this.showList
      }
    },

    components: { AnswerComment }
  }
</script>
