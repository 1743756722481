<template>
  <div id='departments' class='tab-pane fade in active show' role="tabpanel">
    <div class='icon-block'>
      <i class='structure-icon'></i>
    </div>
    <department-form />
    <departments-table />
  </div>
</template>
<script>
  import DepartmentsTable from './DepartmentsTable'
  import DepartmentForm from './DepartmentForm'
  import store from './store'

  export default {
    store: store,
    props: ['parentOptions', 'manager'],

    created() {
      this.$store.commit('setManager', this.manager)
      this.$store.dispatch('fetchDepartments')
    },

    watch: {
      '$store.state.manager': function(newVal, oldVal) {
        if (newVal.role === 'group_admin') {
          this.$store.dispatch('fetchAnalysisGroups')
        }
      }
    },

    components: {
      DepartmentsTable,
      DepartmentForm
    }
  }
</script>
