<template>
  <div class="onboarding-modal">
    <div class='onboarding-container'>
      <div class="row">
        <div class="col-10 offset-1">
          <progress-bar v-if='!skipScreenVisible'/>
        </div>
      </div>
      <div class='stage-container'>
        <transition name="component-fade" mode="out-in">
          <component :is='currentView' />
        </transition>
        <div v-if='!skipScreenVisible && !showFinishButtonsContainer' class='skip-link-container'>
          <a href='#' @click.prevent='showReallySkipScreen' class='skip-link'>
            {{t('components.onboarding.onboarding_wizard_modal.skip_link')}}
          </a>
        </div>

        <transition name="component-max-height" appear>
          <div v-if='showFinishButtonsContainer' class='final-button-container'>
            <div class="final-button-container-inner">
              <a href='#' @click.prevent='finishOnboarding(false)' class='btn btn-warning btn-bordered btn-small-text btn-lg mr-2'>
                <strong>{{t('components.onboarding.onboarding_wizard_modal.send_later')}}</strong>
              </a>

              <a href='#' @click.prevent='finishOnboarding(true)' class='btn btn-warning btn-small-text btn-lg'>
                <strong>{{t('components.onboarding.onboarding_wizard_modal.send_first_survey')}}</strong>
              </a>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
  import { stageMap } from './helpers'
  import store from './store'
  import UsersStage from './UsersStage'
  import DepartmentsStage from './DepartmentsStage'
  import ManagersStage from './ManagersStage'
  import SummaryStage from './SummaryStage'
  import ReallySkipScreen from './ReallySkipScreen'
  import ProgressBar from './ProgressBar'

  export default {
    props: ['onboardingStage', 'localeOptions'],
    store: store,

    created() {
      this.$store.dispatch('fetchDepartments')
      this.$store.dispatch('fetchUsers')
      this.$store.dispatch('fetchManagers')
      this.$store.commit('setStartingStage', this.onboardingStage)
      this.$store.commit('setLocaleOptions', this.localeOptions)
    },

    computed: {
      skipScreenVisible() {
        return this.$store.state.reallySkipScreenVisible
      },

      showFinishButtonsContainer() {
        return this.currentView == 'SummaryStage'
      },

      currentView() {
        if(this.skipScreenVisible) {
          return 'ReallySkipScreen'
        } else {
          return stageMap[this.$store.state.currentStage]
        }
      }
    },

    methods: {
      showReallySkipScreen() {
        this.$store.commit('showReallySkipScreen')
      },

      finishOnboarding(sendSurvey) {
        this.$store.dispatch('finishOnboarding', sendSurvey)
      }
    },

    components: {
      UsersStage,
      DepartmentsStage,
      ManagersStage,
      SummaryStage,
      ProgressBar,
      ReallySkipScreen
    }
  }
</script>

<style>
  .component-fade-enter-active {
    transition: all .4s ease-in;
  }
  .component-fade-enter,
  .component-fade-leave-to {
    opacity: 0;
  }
</style>
