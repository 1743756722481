<template>
  <modal @close='$emit("close")'>
    <div slot='header'>
      {{t('components.management.user_modal.edit', {user_email: this.passedUser.email})}}
    </div>
    <div slot='body'>
      <div class='row'>
        <bootstrap-input-field
          :label="t('activerecord.attributes.user.first_name')"
          v-model='user.first_name'
          inputName='user[first_name]'
          inputId='user_first_name'
          :errors='user.errors.first_name'
          wrapperClass='col-12'
        />
        <bootstrap-input-field
          :label="t('activerecord.attributes.user.last_name')"
          v-model='user.last_name'
          inputName='user[last_name]'
          inputId='user_last_name'
          :errors='user.errors.last_name'
          wrapperClass='col-12'
        />
        <bootstrap-input-field
          :label="t('activerecord.attributes.user.email')"
          v-model='user.email'
          inputName='user[email]'
          inputId='user_email'
          :errors='user.errors.email'
          wrapperClass='col-12'
        />
        <bootstrap-select
          :label="t('activerecord.attributes.user.department')"
          v-model='user.department_id'
          inputName='user[department_id]'
          inputId='user_department_id'
          :errors='user.errors.department_id'
          :options='departmentOptions'
          :clearable='true'
          wrapperClass='col-12'
        />
        <bootstrap-select
          :label="t('activerecord.attributes.user.locale')"
          v-model='user.locale'
          inputName='user[locale]'
          inputId='user_locale'
          :errors='user.errors.locale'
          :options='localeOptions'
          :clearable='false'
          wrapperClass='col-12'
        />
      </div>

    </div>
    <div slot='footer' class="buttons">
      <input type='submit' :value='t("save")' @click.prevent='persistUser' class='btn btn-primary btn-lg' :disabled='loading'>
      <button class='btn btn-primary btn-bordered btn-lg' @click='$emit("close")'>
        {{t('cancel')}}
      </button>
    </div>
  </modal>
</template>
<script>
  import Modal from '../../utilityComponents/Modal'
  import BootstrapInputField from '../../utilityComponents/BootstrapInputField'
  import BootstrapSelect from '../../utilityComponents/BootstrapSelect'
  import { cloneDeep } from 'lodash'
  export default {
    props: ['passedUser'],
    data() {
      return {
        user: cloneDeep(this.passedUser),
        loading: false,
        errors: {}
      }
    },
    computed: {
      localeOptions() {
        return this.$store.state.localeOptions
      },
      departmentOptions() {
        return this.$store.state.departmentOptions
      }
    },
    methods: {
      initializeNewUser() {
        return {
          email: null,
          first_name: null,
          last_name: null,
          department_id: null,
          locale: null,
          errors: {}
        }
      },
      persistUser() {
        this.loading = true
        this.$store.dispatch('persistUser', this.user).
          then(() => {
            this.loading = false
            this.errors = {}
            this.$emit('close')
            this.$root.$emit('flash', {notice: this.t('components.management.user_row.successful_update_message', { user_name: this.user.email})})
          }).catch((data) => {
            this.loading = false
            this.errors = data.responseJSON
          })
      }
    },
    components: {
      Modal,
      BootstrapInputField,
      BootstrapSelect
    }
  }
</script>
