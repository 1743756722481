<template>
  <div class='table-responsive'>
    <table class='table management-table'>
      <thead>
        <th class='active'>
          <span>
            {{t('activerecord.attributes.department.name')}}
          </span>
        </th>
        <th>
          <span>
            {{t('activerecord.attributes.department.user_count')}}
          </span>
        </th>
        <th></th>
      </thead>
      <tbody>
        <department-row v-for='department in departments' :department='department' :key='department.updated_at'/>
      </tbody>
    </table>
  </div>
</template>
<script>
  import DepartmentRow from './DepartmentRow'

  export default {
    computed: {
      departments() {
        return this.$store.state.departments
      }
    },
    components: {
      DepartmentRow
    }
  }
</script>
