<template>
  <div class='table-cell mood-cell' v-bind:title='rollingData.deltaFormula'>
    <div class='visualized-value' v-bind:class='valueColor'>
      <span v-if="rollingData.deltaValue != null">
        {{ rollingData.deltaValue }}
      </span>
      <span v-else>
        No data
      </span>
    </div>
  </div>
</template>

<script>
  export default {
    props: ['rollingData'],
    computed: {
      valueColor() {
        return {
          'orange': this.rollingData.deltaValue < -5,
          'light-orange': this.rollingData.deltaValue <= -1 && this.rollingData.deltaValue >= -5,
          'yellow': this.rollingData.deltaValue == 0,
          'green': this.rollingData.deltaValue >= 1 && this.rollingData.deltaValue <= 5,
          'dark-green': this.rollingData.deltaValue > 5,
          'no-data': this.rollingData.deltaValue == null
        }
      }
    }
  }
</script>

<style scoped>
.orange {
  background-color: #F56300;
}
.light-orange {
  background-color: #FA9500;
}
.yellow {
  background-color: #FFC600;
}
.green {
  background-color: #8ADB0D;
}
.dark-green {
  background-color: #1AD371;
}
.no-data {
  background-color: #808080;
}
</style>
