<template>
  <div id='users' class='tab-pane fade in active show' role="tabpanel">
    <div class='icon-block'>
      <i class='clerk-icon'></i>
      <i class='fa fa-plus-circle fa-4x'></i>
    </div>
    <user-form :default-locale='defaultLocale' />
    <user-table />
  </div>
</template>
<script>
  import UserTable from './UserTable'
  import UserForm from './UserForm'
  import store from './store'

  export default {
    props: ['departmentOptions', 'localeOptions', 'defaultLocale', 'manager'],
    store: store,

    created() {
      this.$store.commit('setDepartmentOptions', this.departmentOptions)
      this.$store.commit('setLocaleOptions', this.localeOptions)
      this.$store.commit('setManager', this.manager)
      this.$store.dispatch('fetchUsers')
    },

    components: {
      UserTable,
      UserForm
    }
  }
</script>
