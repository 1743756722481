<template>
  <button class='btn btn-default filter-btn' :class='{active: isActive}' @click='$parent.$emit("filter", metricCode)'>
    {{title.toUpperCase()}}
  </button>
</template>
<script>
  export default {
    props: ['title', 'metricCode'],
    computed: {
      isActive() {
        return this.metricCode == this.$store.state.activeMetric
      }
    }
  }
</script>