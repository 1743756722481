<template>
  <div class='manager-form stage-form'>
    <bootstrap-input-field
      :placeholder="t('activerecord.attributes.manager.first_name')"
      v-model='manager.first_name'
      inputName='manager[first_name]'
      :inputId='inputId("first_name")'
      :errors='manager.errors.first_name'
    />
    <bootstrap-input-field
      :placeholder="t('activerecord.attributes.manager.last_name')"
      v-model='manager.last_name'
      inputName='manager[last_name]'
      :inputId='inputId("last_name")'
      :errors='manager.errors.last_name'
    />
    <bootstrap-input-field
      :placeholder="t('activerecord.attributes.manager.email')"
      v-model='manager.email'
      inputName='manager[email]'
      :inputId='inputId("email")'
      :errors='manager.errors.email'
    />
    <div class='button-holder'>
      <a href='#' class='onboarding-add-button' @click.prevent='addManager' data-action='save-manager'>
        <div class="onboarding-add-button-icon-holder">
          <i class='fa fa-plus'></i>
        </div>
        {{t('components.onboarding.add')}}
      </a>
    </div>
  </div>
</template>

<script>
  import BootstrapInputField from '../utilityComponents/BootstrapInputField'

  export default {
    data() {
      return {
        manager: this.initializeManager()
      }
    },

    methods: {
      inputId(name) {
        return `manager_${name}`
      },

      initializeManager() {
        return {
          first_name: '',
          last_name: '',
          email: '',
          role: 'admin',
          skip_invitation: true,
          errors: {}
        }
      },

      addManager() {
        this.$store.dispatch('persistManager', this.manager)
        .then(() => this.resetManagerInputs())
        .catch(({ response }) => this.manager.errors = response.data )
      },

      resetManagerInputs() {
        this.manager = this.initializeManager()
      }
    },

    components: {
      BootstrapInputField
    }
  }
</script>

