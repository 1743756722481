<template>
  <div class="answer-statistics">
    <div class="question" @click="toggle" :class="{opened: expanded, large: !surveySent}">
      <div class="row w-100 d-flex align-items-center">
        <div class="col-7 d-flex">
          <div class="question-block ml-1">
            <div class="title">{{questionNumber}}. {{question.title}}</div>
          </div>
        </div>
        <div class="col-5 main-statistics">
          <small-progress-bar-chart
            class="col-9 mr-10"
            v-if="showRestults"
            :value="value"
            :withAverage="true"
          />
          <div class="question-meta mr-1 col-3">
            <div v-if="surveySent" class="content">
              <div class="count">{{filteredQuestionResponses.length}}</div>
              <div class="sub-text">{{t('analysis.components.show.responses')}}</div>
            </div>
            <i class="fa fa-chevron-down"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="answer-list" v-if="expanded">
      <div v-for="(option, i) in question.question_options" class="option-row" v-bind:key="i">
        <div class="row">
          <div class="col-2">
            <div class="title float-right">{{option.title}}</div>
          </div>
          <div class="progress">
            <div class="filled" :style='{width: answerPercentage(option) + "%"}'></div>
          </div>
          <div class="col-3">
            <div class="percentage">{{answerPercentage(option)}}%</div>
          </div>
        </div>
      </div>

      <div class="answer-comments-container">
        <span class="list-toggle-link pointer" @click="toggleComments">
          {{ comments.length }}
          COMMENTS
          <i
            :class="commentIconClassObject"
            v-show="comments.length > 0"
          ></i>
        </span>
        <div class="answer-comments-list" v-show="expandedComments">
          <div class="comments-table-header">
            <div class="date">No.</div>
            <div
              class="value"
            >{{t('components.analysis.components.answer_comments_container.value')}}</div>
            <div
              v-if="question.type !== 'open_ended'"
              class="comment"
            >{{t('components.analysis.components.answer_comments_container.comment')}}</div>
          </div>
          <div v-for="(comment, index) in comments" class="answer-comment" v-bind:key="index">
            <div class="date">{{ index + 1 }}</div>
            <div class="value">{{ commentAnswer(comment) }}</div>
            <div v-if="question.type !== 'open_ended'" class="comment">{{ comment.comment }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["question", "questionResponses", "questionNumber", "surveySent"],
  data() {
    return {
      expanded: false,
      expandedComments: this.question.type == "open_ended",
      questionOptionResponses: {},
      questionOptions: {},
      comments: []
    };
  },
  watch: {
    question: {
      immediate: true,
      handler(question) {
        const questionOptions = {};
        question.question_options.forEach(qo => {
          questionOptions[qo.id] = qo;
        });
        this.questionOptions = questionOptions;
      }
    },
    filteredQuestionResponses: {
      immediate: true,
      handler(questionResponses) {
        const optionResponses = {};
        const comments = [];

        questionResponses.forEach(qr => {
          if (!optionResponses[qr.question_option_id]) {
            optionResponses[qr.question_option_id] = [];
          }
          optionResponses[qr.question_option_id].push(qr);
          if (
            (qr.comment && qr.comment.length > 0) ||
            (qr.answer_text && qr.answer_text.length > 0) ||
            (this.question.type === "open_ended" && qr.answer_text === null)
          ) {
            comments.push(qr);
          }
        });

        this.questionOptionResponses = optionResponses;
        this.comments = comments;
      }
    }
  },

  methods: {
    toggle() {
      this.expanded = !this.expanded;
    },
    toggleComments() {
      if (this.comments.length > 0) {
        this.expandedComments = !this.expandedComments;
      }
    },
    answerPercentage(option) {
      const optionResponses = this.questionOptionResponses[option.id];
      const optionResponsesCount = optionResponses ? optionResponses.length : 0;
      const totalCount = this.questionResponses.length;
      if (totalCount) {
        return Math.round((100 * optionResponsesCount) / totalCount);
      } else {
        return 0;
      }
    },
    commentAnswer(comment) {
      if (comment.question_option_id) {
        return this.questionOptions[comment.question_option_id].title;
      } else {
        if (comment.answer_text) return comment.answer_text;
        else return "*Empty answer*";
      }
    }
  },
  computed: {
    showRestults() {
      return (
        this.surveySent &&
        this.question.type !== "custom" &&
        this.question.type !== "open_ended" &&
        this.filteredQuestionResponses.length !== 0
      );
    },

    filteredQuestionResponses() {
      const departmentIds = this.filteredDepartmentIds;

      return this.questionResponses.filter(a => {
        if (departmentIds.length == 0) {
          return true;
        }
        return (
          departmentIds.findIndex(id => {
            return a.department_id === id;
          }) > -1
        );
      });
    },
    filteredDepartmentIds() {
      return this.$store.state.filteredDepartmentIds;
    },
    commentIconClassObject() {
      return {
        fa: true,
        "fa-chevron-up": this.expandedComments,
        "fa-chevron-down": !this.expandedComments
      };
    },
    value() {
      const scores = this.filteredQuestionResponses.map(a =>
        parseFloat(a.score)
      );
      if (scores.length > 0 && this.filteredQuestionResponses.length > 0) {
        const totalValue = scores.reduce((t, v) => t + v);
        return Math.round(totalValue / this.filteredQuestionResponses.length);
      } else {
        return 0;
      }
    }
  }
};
</script>
<style>
.mr-10 {
  margin-right: 10px;
}
</style>
