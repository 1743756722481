<template>
  <div id='component-container'>
    <!-- <div class='header-navigation'>
      <div class='dropdown component-select'>
        <button class='btn btn-default dropdown-toggle' id='component-select' type='button' data-toggle='dropdown' aria-haspopup='true' aria-expanded='true'>
          {{component.name}}
          <span class='caret'></span>
        </button>
        <ul class='dropdown-menu' aria-labelledby='component-select'>
          <li v-for='selectableComponent in componentsForSelect' class='{active: component.id == selectableComponent.id}'>
            <a :href='selectableComponent.path'>
              {{selectableComponent.name}}
            </a>
          </li>
        </ul>
      </div>
      <a v-if='previousComponent' :href='previousComponent.path' class='header-navigation-link'>
        <i class='fa-chevron-left fa'></i>
      </a>
      <a v-if='nextComponent' :href='nextComponent.path' class='header-navigation-link'>
        <i class='fa-chevron-right fa'></i>
      </a>
    </div>
    <div class='divider'></div>
    <div class='summary-content'>
      <div class='row'>
        <div class='col-9'>
          <div class='title'>{{component.name}}</div>
          <div class='chart'>
            <small-progress-bar-chart v-if='rollingComponent' :value='rollingComponent.value' />
          </div>
          <div class='description'>
            {{component.description}}
          </div>
          <div class='more-info'>
            <a :href='component.read_more_url' target='_blank'>
              {{t('analysis.components.show.more_info')}}
            </a>
          </div>
        </div>
        <div class='col-3'>
          <div class='float-right'>
            <component-learn-more-button
              :button_title="t('analysis.components.show.ask_question')"
              :component_id='component.id'
              :next-survey-date='nextSurveyDate'
              :departments='departments' />
            <div class='help-popover more-info'>
              <a href='#'>{{t('analysis.components.show.what_is_open_question')}}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class='divider'></div>
    <div class='chart-block-container'>
      <component-line-chart-block
        v-if='rollingComponent.value'
        :component_id='component.id'
        :labels='labels'
        :benchmark='component.benchmark'/>
      <div v-else class='no-data mt-3'>
        <div class='empty-chart-icon'>
          <img :src='noResultsImagePath'></img>
        </div>
        {{t('not_enough_data')}}
      </div>
    </div>
    <div class='feedback-block-container md-2'>
      <div class='title'>
        {{t('analysis.components.show.feedback')}}
      </div>
      <div class='block'>
        <component-feedback-container :component-id='component.id'/>
      </div>
    </div>
    <div id='improvement-suggestions' class='improvement-suggestions-block'>
      <div class='title'>
        {{t('analysis.components.show.tips_and_tricks')}}
      </div>
      <div class='block'>
        <component-improvement-suggestions-block :passed-suggestions='passedSuggestions' />
      </div>
    </div> TODO: Put all components page in this component-->
    <slot></slot>
  </div>
</template>
<script>
  import store from './store'

  export default {
    store: store,
    props: ['componentId', 'labels', 'benchmark'],

    created() {
      this.$store.state.componentId = this.componentId
      this.$store.state.labels = this.labels
      this.$store.state.benchmark = this.benchmark
      this.$store.dispatch('fetchSurveyDates').then(() => {
        this.$store.dispatch('toggleDepartmentChecked', null)
      })
    },
  }
</script>
