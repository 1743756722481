<template>
  <div class='department-input'>
    <input type='text' class='form-control' id='department_name'
      v-model='department.name'
      :placeholder="t('components.onboarding.departments_stage.input_placeholder')" >
    <a href='#' class='onboarding-add-button' @click.prevent='persistDepartment' data-action='save-department'>
      <div class="onboarding-add-button-icon-holder">
        <i class='fa fa-plus'></i>
      </div>
      {{t('components.onboarding.add')}}
    </a>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        department: {
          name: ''
        }
      }
    },

    methods: {
      persistDepartment() {
        this.$store.dispatch('persistDepartment', this.department).
        then(() => this.department.name = '')
      }
    }
  }
</script>
