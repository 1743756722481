export default {
  mounted() {
    this.$on('flash', this.showFlash)
    this.$on('closeFlash', this.closeFlash)
  },
  data() {
    return {
      flashObject: {},
      flashVisible: false
    }
  },

  methods: {
    showFlash(flashObject) {
      this.flashObject = flashObject
      this.flashVisible = true
    },

    closeFlash() {
      this.flashVisible = false
      this.flashObject = {}
    }
  }
}
