<template>
  <a class='responsive-menu d-lg-none' href='#' v-on:click='setVisibility'>
    <i class='fa fa-bars' />
  </a>
</template>

<script>
  export default {
    data() {
      return {
        isVisible: false
      }
    },
    methods: {
      setVisibility() {
        this.isVisible = !this.isVisible
      }
    },
    watch: {
      isVisible(value) {
        const className = 'responsive-menu-visible'
        if (value) {
          document.querySelector('.sidebar-content').classList.add(className)
        } else {
          document.querySelector('.sidebar-content').classList.remove(className)
        }
      }
    }
  }
</script>
