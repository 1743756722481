<template>
  <modal @close='$emit("close")' class='improvement-suggestion-modal' className='big'>
    <h6 slot='header' class='m-auto'>
      {{suggestion.title}}
    </h6>
    <div slot='body'>
      <i class='fa fa-3x'
        v-if='showStar'
        :class='[{"fa-star": suggestion.is_favorite}, {"fa-star-o": !suggestion.is_favorite}]'
        @click='toggleFavoriteSuggestion'></i>
      <div class='improvement-suggestion-image-holder mb-5'>
        <img :src='suggestion.picture_url' />
      </div>
      {{suggestion.summary}}
      <hr />
      {{suggestion.body}}
    </div>
    <div slot='footer' class='text-center w-100 my-2'>
      <a href="" class="btn btn-primary btn-lg" @click.prevent='$emit("close")'>Got it</a>
    </div>
  </modal>
</template>
<script>
  import axios from 'axios'
  import { AUTH_TOKEN } from '../../helpers/globals'
  import Modal from '../../utilityComponents/Modal'

  export default {
    props: ['suggestion', 'showStar'],

    created() {
      this.incrementMethod = setTimeout(this.incrementOpenedCount, 5000)
    },

    beforeDestroy() {
      clearTimeout(this.incrementMethod)
    },

    methods: {
      toggleFavoriteSuggestion() {
        if(this.suggestion.is_favorite) {
          this.removeFromFavorites()
        } else {
          this.addToFavorites()
        }
      },

      addToFavorites() {
        axios({
          url: '/manager_suggestions',
          method: 'POST',
          data: {
            authenticity_token: AUTH_TOKEN,
            suggestion_id: this.suggestion.id
          }
        }).
        then(({ data }) => this.$emit('update', data))
      },

      removeFromFavorites() {
        axios({
          url: '/manager_suggestions',
          method: 'DELETE',
          data:{
            authenticity_token: AUTH_TOKEN,
            suggestion_id: this.suggestion.id
          }
        })
        .then(({ data }) => this.$emit('update', data))
      },

      incrementOpenedCount() {
        axios({
          url: `/improvement_suggestions/${this.suggestion.id}/increment_opened_count`,
          method: 'PUT',
          data:{ authenticity_token: AUTH_TOKEN }
        })
      }
    },

    components: { Modal }
  }
</script>
