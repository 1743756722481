<template>
  <div class='flash-notice' :class='[type, size]'>
    {{notice}}
    <a href='#' @click.prevent='closeFlash' class='close-notice'>
      <i class='close-icon grey'></i>
    </a>
  </div>
</template>
<script>
  export default {
    props: ['flashObject', 'instantFlash'],
    mounted() {
      setTimeout(() => { this.closeFlash() }, 10000)
    },

    computed: {
      notice() {
        if(this.instantFlash) {
          return this.instantFlash.notice
        } else {
          return this.flashObject.notice || ''
        }
      },

      type() {
        if(this.instantFlash) {
          return this.instantFlash.type
        } else {
          return this.flashObject.type || 'success'
        }

      },

      size() {
        if(this.instantFlash) {
          return this.instantFlash.size
        } else {
          return this.flashObject.size || 'big'
        }
      },
    },

    methods: {
      closeFlash() {
        if(this.instantFlash){
          this.$el.remove()
        } else {
          this.$root.$emit('closeFlash')
        }
      }
    }
  }
</script>
