import 'bootstrap'
import Rails from 'rails-ujs'

import ProgressBarChart from './charts/ProgressBarChart'
import ThinDoughnut from './charts/ThinDoughnut'
import PlainFatDoughnut from './charts/PlainFatDoughnut'
import ChangeIndicator from './analysis/ChangeIndicator'
import ResponseRateDiagram from './analysis/ResponseRateDiagram'
import DepartmentOverview from './analysis/DepartmentOverview'
import LabeledDoughnut from './charts/LabeledDoughnut'
import AllResultsWrapper from './special/all_results/Wrapper'
import FilterButton from './special/all_results/FilterButton'
import ComponentLearnMoreButton from './analysis/components/LearnMoreButton'
import ComponentLineChartBlock from './analysis/components/LineChartBlock'
import ComponentFeedbackContainer from './analysis/components/FeedbackContainer'
import ComponentImprovementSuggestionsBlock from './analysis/components/ImprovementSuggestionsBlock'
import ComparisonsMainContainer from './analysis/comparisons/MainContainer'
import UserManagementContainer from './management/users/Container'
import DepartmentManagementContainer from './management/departments/Container'
import TextBlock from './utilityComponents/TextBlock'
import ImprovedSelect from './utilityComponents/ImprovedSelect'
import PaymentForm from './billing/PaymentForm'
import ManagersContainer from './management/managers/Container'
import FlashNotice from './utilityComponents/FlashNotice'
import OnboardingWizardModal from './onboarding/OnboardingWizardModal'
import SummaryPrioritiesBlock from './analysis/summary/PrioritiesBlock'
import SummaryLineChartMetricBlock from './analysis/summary/LineChartMetricBlock'
import SummarySuggestionList from './analysis/summary/SuggestionList'
import SummaryFeedbackQuestionsList from './analysis/summary/FeedbackQuestionsList'
import SummaryFilterResultsDropdown from './analysis/summary/FilterResultsDropdown'
import AnalysisGroupsContainer from './management/analysis_groups/Container'
import BootstrapSelect from './utilityComponents/BootstrapSelect'
import CustomCheckbox from './utilityComponents/CustomCheckbox'
import DatePicker from './utilityComponents/DatePicker'
import SmallProgressBarChart from './charts/SmallProgressBarChart'
import ComponentPageContainer from './analysis/components/MainContainer'
import ComponentSurveyQuestionContainer from './analysis/components/SurveyQuestionContainer'
import ComponentFilterResultsDropdown from './analysis/components/FilterResultsDropdown'
import MenuIcon from './sidebar/MenuIcon'
import WelcomeModal from './onboarding/WelcomeModal'
import TestSurveyGuide from './testSurvey/TestSurveyGuide'
import HowItWorks from './onboarding/HowItWorks'
import DemoChangeIndicator from './analysis/DemoChangeIndicator'
import SimpleSelect from './utilityComponents/SimpleSelect'
import ExportCommentsButton from './analysis/ExportCommentsButton'
import ComparisonFilterResultsDropdown from './analysis/comparisons/FilterResultsDropdown'
import OrganizationFilterResultsDropdown from './analysis/organizations/FilterResultsDropdown'
import ValueCell from './analysis/organizations/ValueCell'
import NpsComponentCell from './analysis/organizations/NpsComponentCell'
import OrganizationTableContainer from './analysis/organizations/TableContainer'
import OrganizationMainContainer from './analysis/organizations/MainContainer'

import AdHocSurveysContainer from './ad_hoc_surveys/Container'

import Vue from  'vue'
import I18nInVue from './plugins/i18n_in_vue'
import VTooltip from 'v-tooltip'

Rails.start()
Vue.use(I18nInVue)
// Pollyfills for v-tooltip
import 'core-js/stable/object/assign'
import 'core-js/stable/get-iterator'
import 'core-js/stable/symbol'

Vue.use(VTooltip, { defaultPlacement: 'auto', })

Vue.component('ProgressBarChart', ProgressBarChart)
Vue.component('ThinDoughnut', ThinDoughnut)
Vue.component('PlainFatDoughnut', PlainFatDoughnut)
Vue.component('ChangeIndicator', ChangeIndicator)
Vue.component('ResponseRateDiagram', ResponseRateDiagram)
Vue.component('DepartmentOverview', DepartmentOverview)
Vue.component('LabeledDoughnut', LabeledDoughnut)
Vue.component('ComponentLearnMoreButton', ComponentLearnMoreButton)
Vue.component('ComponentLineChartBlock', ComponentLineChartBlock)
Vue.component('MenuIcon', MenuIcon)
Vue.component('SummaryFilterResultsDropdown', SummaryFilterResultsDropdown)
Vue.component('ComponentFilterResultsDropdown', ComponentFilterResultsDropdown)
Vue.component('ComparisonFilterResultsDropdown', ComparisonFilterResultsDropdown)
Vue.component('OrganizationFilterResultsDropdown', OrganizationFilterResultsDropdown)
Vue.component('ValueCell', ValueCell)
Vue.component('NpsComponentCell', NpsComponentCell)
Vue.component('OrganizationTableContainer', OrganizationTableContainer)
Vue.component('OrganizationMainContainer', OrganizationMainContainer)
Vue.component('TestSurveyGuide', TestSurveyGuide)
Vue.component('HowItWorks', HowItWorks)
Vue.component('DemoChangeIndicator', DemoChangeIndicator)
// Wrapper components for individual pages
Vue.component('AllResultsWrapper', AllResultsWrapper)
Vue.component('FilterButton', FilterButton)
Vue.component('ComponentFeedbackContainer', ComponentFeedbackContainer)
Vue.component('ComparisonsMainContainer', ComparisonsMainContainer)
Vue.component('UserManagementContainer', UserManagementContainer)
Vue.component('DepartmentManagementContainer', DepartmentManagementContainer)
Vue.component('ManagersContainer', ManagersContainer)
Vue.component('TextBlock', TextBlock)
Vue.component('ImprovedSelect', ImprovedSelect)
Vue.component('FlashNotice', FlashNotice)
Vue.component('OnboardingWizardModal', OnboardingWizardModal)
Vue.component('ComponentImprovementSuggestionsBlock', ComponentImprovementSuggestionsBlock)
Vue.component('SummaryPrioritiesBlock', SummaryPrioritiesBlock)
Vue.component('SummaryLineChartMetricBlock', SummaryLineChartMetricBlock)
Vue.component('SummarySuggestionList', SummarySuggestionList)
Vue.component('SummaryFeedbackQuestionsList', SummaryFeedbackQuestionsList)
Vue.component('AnalysisGroupsContainer', AnalysisGroupsContainer)
Vue.component('BootstrapSelect', BootstrapSelect)
Vue.component('CustomCheckbox', CustomCheckbox)
Vue.component('DatePicker', DatePicker)
Vue.component('SmallProgressBarChart', SmallProgressBarChart)
Vue.component('ComponentPageContainer', ComponentPageContainer)
Vue.component('ComponentSurveyQuestionContainer', ComponentSurveyQuestionContainer)
Vue.component('WelcomeModal', WelcomeModal)
Vue.component('AdHocSurveysContainer', AdHocSurveysContainer)
Vue.component('SimpleSelect', SimpleSelect)
Vue.component('ExportCommentsButton', ExportCommentsButton)

// Billing
Vue.component('PaymentForm', PaymentForm)

import containsFlash from './mixins/containsFlash'
window.addEventListener('load', function() { // If VUE somehow initializes before CSS is loaded, charts got resized wrong as their size depend on grid
  if(document.querySelector('#vue-container')) { // Don't raise error if vue is not needed
    new Vue({
      mixins: [containsFlash]
    }).$mount('#vue-container')
  }
})
