<template>
  <div v-if='showBlock' class='col-12'>
    <div class='card card-dashboard mb-5 summary-overview'>
      <div class='row'>
        <div class='col-md-6'>
          <div class='summary-overview-left'>
            <div class='summary-overview-image' :style='{borderColor: data.background_color}'>
              <img :src='data.image_url' />
              <div class='summary-overview-image-badge' :style='{backgroundColor: data.background_color}'>
                <i class='fa fa-2x' :class='data.badge'></i>
              </div>
            </div>
            <h2 class='summary-overview-heading'>{{data.heading}}</h2>
            <p class='summary-overview-text'>{{data.header_sentence}}</p>
            <div class='text-center'>
              <a class='summary-overview-link' href='#' @click.prevent='openModal'>{{t('components.analysis.summary.priorities_block.read_more')}}</a>
            </div>
          </div>
        </div>
        <div class='col-md-6'>
          <div class='summary-overview-right'>
            <div class='summary-overview-top' v-if='showHighest'>
              <h5 class='summary-overview-top-heading'>{{t('components.analysis.summary.priorities_block.top_strengths')}}</h5>
              <div v-for='component in data.highest_components' class='summary-overview-top-item strength'>
                <i class='fa fa-plus fa-lg mr-2'></i>
                <a :href='component.link'>{{component.name}}</a>
                <span class='summary-overview-top-item-grey'>
                  ({{resultText(component)}})
                </span>
              </div>
            </div>

            <div class='summary-overview-top' v-if='showLowest'>
              <h5 class='summary-overview-top-heading'>{{t('components.analysis.summary.priorities_block.top_weaknesses')}}</h5>
              <div v-for='component in data.lowest_components' class='summary-overview-top-item weakness'>
                <i class='fa fa-minus fa-lg mr-2'></i>
                <a :href='component.link'>{{component.name}}</a>
                <span class='summary-overview-top-item-grey'>
                  ({{resultText(component)}})
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <general-description-modal v-if='modalOpen' :data='data' @close='closeModal' />
  </div>
</template>
<script>
  import GeneralDescriptionModal from './GeneralDescriptionModal'
  export default {
    props: ['data'],

    data() {
      return {
        modalOpen: false
      }
    },

    computed: {
      showBlock() {
        return this.showHighest || this.showLowest
      },

      showHighest() {
        return this.data.highest_components.length > 0
      },

      showLowest() {
        return this.data.lowest_components.length > 0
      }
    },

    methods: {
      resultText(component) {
        if(component.direction != 'at') {
          return `${component.benchmark_difference} ${component.direction} ENME benchmark`
        } else {
          return `at ENME benchmark`
        }
      },

      openModal() {
        this.modalOpen = true
      },

      closeModal() {
        this.modalOpen = false
      }
    },

    components: { GeneralDescriptionModal }
  }
</script>
