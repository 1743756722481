const tooltipFunction = function(tooltip){
  // Tooltip Element
  let canvasId = this._chart.canvas.id
  let tooltipId = `chartjs-tooltip-${canvasId}`
  var tooltipEl = document.getElementById(tooltipId);
  if (!tooltipEl) {
    tooltipEl = document.createElement('div');
    tooltipEl.style.opacity = 0
    tooltipEl.id = tooltipId
    this._chart.canvas.parentNode.appendChild(tooltipEl);
  }
  // Hide if no tooltip
  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = 0
    return
  }
  // Set caret Position
  tooltipEl.classList.remove('above', 'below', 'no-transform');
  if (tooltip.yAlign) {
    tooltipEl.classList.add(tooltip.yAlign);
  } else {
    tooltipEl.classList.add('no-transform');
  }

  // Set Text
  if (tooltip.body) {
    let titleLines = tooltip.title || []
    let bodyLines  = tooltip.body.map((bodyItem) => bodyItem.lines)
    let innerHtml  = ''
    titleLines.forEach((title) => innerHtml += `${title} <br>` )
    bodyLines.forEach((body, i) => innerHtml += `<div class="chartjs-tooltip-key">${parseInt(body[0])}</div>`)
    tooltipEl.innerHTML = innerHtml;
  }
  let positionY = this._chart.canvas.offsetTop
  let positionX = this._chart.canvas.offsetLeft
  // Display, position, and set styles for font
  tooltipEl.style.left = positionX + tooltip.caretX + 'px'
  tooltipEl.style.top = positionY + tooltip.caretY - 65 +'px'
  tooltipEl.style.padding = tooltip.yPadding + 'px ' + tooltip.xPadding + 'px'
  tooltipEl.style.opacity = 1;
}

export { tooltipFunction }
