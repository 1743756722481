<template>
<!-- parent click event is meant for all results page -->
  <div class='card card-dashboard' :class='[{"margin-bottom": !noMargin}, classNames]' @click='$parent.$emit("filter", metricCode)'>
    <div class='card-body flex-column'>
      <div class='header'>
        {{title.toUpperCase()}}
        <div v-if='description' class='f-right tooltip-icon'>
          <i v-tooltip='{content: description}'class='fa fa-lg fa-question-circle'></i>
        </div>
      </div>
      <div class='body'>
        <div class='row'>
          <div class='col-lg-6 offset-lg-3 col-sm-12'>
            <canvas :id='canvasId' :data-value='chartValue' class='thin-doughnut' :class='moodClass'></canvas>
            <div class="thin-doughnut-value-wrapper">
              <span :class="{'actual-value': dataExists, 'no-data': !dataExists}">{{displayableValue}}</span>
              <span class="max-value">/ 100</span>
            </div>
          </div>
        </div>
        <slot></slot>
      </div>
    </div>
  </div>


</template>
<script>
  import ThinDoughnut from './prototypes/ThinDoughnut'
  import { uniqueId } from 'lodash'
  import { moodName } from '../helpers/chart_helpers'
  export default {
    props: ['value', 'headerIcon', 'title', 'description', 'metricCode', 'noMargin', 'classNames', 'dataExists'],
    mounted() {
      new ThinDoughnut($(`#${this.canvasId}`))
    },
    data() {
      return {
        canvasId: `thin-doughnut-${uniqueId()}`
      }
    },
    computed: {
      moodClass() {
        if(this.dataExists) {
          return moodName(this.value)
        } else {
          return 'no-data'
        }
      },

      displayableValue() {
        if(this.dataExists) {
          return parseInt(this.value)
        } else {
          return '-'
        }
      },

      chartValue() {
        if(this.dataExists) {
          return parseInt(this.value)
        } else {
          return 0
        }
      }
    }
  }
</script>
<style>
  .filter-faded {
    -moz-filter: grayscale(100%);
    -webkit-filter: grayscale(100%);
    filter: gray; /* IE6-9 */
    filter: grayscale(100%);
  }

  .header {
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #061C3F;
    font-size: 13px;
    margin-bottom: 5px;
  }

  .tooltip-icon {
    position: absolute;
    top: 1.25rem;
    right: 1.25rem;
  }
</style>
