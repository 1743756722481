<template>
  <div class="options-inputs">
    <draggable :list="options" handle=".move-icon" animation="150">
      <transition-group>
        <div v-for="(option, i) in options" v-bind:key="i + 0" class="option-inputs">
          <div class="row">
            <div class="w-100">
              <div class="option-input">
                <div v-if="!disabled" class="move-icon">
                  <i class="fa fa-th fa-2x"></i>
                </div>
                <div class="title-input" :class="{disabled: disabled}">
                  <bootstrap-input-field
                    placeholder="Enter an answer choice"
                    v-model="option.title"
                    inputName="ahsq[title]"
                    :inputId="`answer_option_${questionIndex}_${i}`"
                    :disabled="disabled"
                    :errors='errors && (errors[i] && [t("components.ad_hoc_surveys.survey.blank")])'
                  />
                </div>
                <div class="option-input" v-if="!disabled && !blockRemoval">
                  <div
                    :data-action="`remove_option_${questionIndex}_${i}`"
                    class="delete-icon"
                    @click="removeOption(i)"
                  >
                    <i class="fa fa-times"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition-group>
    </draggable>
    <div
      data-action="add-option"
      v-if="!disabled"
      class="pointer new-option"
      @click="addOption()"
    >+ Add option</div>
  </div>
</template>
 
<script>
import BootstrapInputField from "../utilityComponents/BootstrapInputField";
import draggable from "vuedraggable";

export default {
  name: "optionForm",
  props: ["options", "errors", "disabled", "questionIndex"],
  data() {
    return {
      blockRemoval: this.options.length === 1
    };
  },
  components: {
    BootstrapInputField,
    draggable
  },
  watch: {
    options() {
      this.blockRemoval = this.options.length === 1;
    }
  },
  methods: {
    clearErrors() {
      this.$emit("clearErrors");
    },
    addOption() {
      this.clearErrors();
      this.options.push({
        title: ""
      });
    },

    removeOption(optionId) {
      this.clearErrors();
      if (!this.blockRemoval) this.options.splice(optionId, 1);
    }
  },
  mounted() {
    if (this.options.length == 0) {
      this.addOption();
    }
  }
};
</script>
<style>
.title-input {
  width: 100%;
}
.title-input.disabled {
  margin-left: 50px;
}
.options-inputs {
  margin-right: 50px;
}
</style> 

