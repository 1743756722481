<template>
  <no-header-modal class-name='big'>
    <div slot='body'>
      <div class='row'>
        <div class='col-8 top-text'>
          <div class='compass-image'>
            <img :src='compass' />
          </div>
          <div class='headings'>
            <div class='heading'>{{ t('funnel.welcome_modal.title') }}</div>
            <div class='sub-heading'>{{ t('funnel.welcome_modal.subtitle') }}</div>
          </div>
        </div>
        <div class='col-9 bottom-text'>
          <div class='row list-row'>
            <div class='col-3 row-image'>
              <img :src='icon1' />
            </div>
            <div class='col-9 row-text'>
              {{ t('funnel.welcome_modal.row_1') }}
            </div>
          </div>
          <div class='row list-row'>
            <div class='col-3 row-image'>
              <img :src='icon2' />
            </div>
            <div class='col-9 row-text'>
              {{ t('funnel.welcome_modal.row_2') }}
            </div>
          </div>
          <div class='row list-row'>
            <div class='col-3 row-image'>
              <img :src='icon3' />
            </div>
            <div class='col-9 row-text'>
              {{ t('funnel.welcome_modal.row_3') }}
            </div>
          </div>
          <div class='row list-row'>
            <div class='col-3 row-image'>
              <img :src='icon4' />
            </div>
            <div class='col-9 row-text'>
              {{ t('funnel.welcome_modal.row_4') }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div slot='footer' class='text-center w-100 my-2 pb-4'>
      <a href="admin/test_survey/to_test_survey_guide" class="btn btn-primary btn-lg">{{ t('funnel.welcome_modal.start') }}</a>
    </div>
  </no-header-modal>
</template>
<script>
  import NoHeaderModal from '../utilityComponents/NoHeaderModal'
  import compass from '../../../assets/images/welcome-compass.svg'
  import icon1 from '../../../assets/images/welcome-icon-1.svg'
  import icon2 from '../../../assets/images/welcome-icon-2.svg'
  import icon3 from '../../../assets/images/welcome-icon-3.svg'
  import icon4 from '../../../assets/images/welcome-icon-4.svg'
  export default {
    components: { NoHeaderModal },
    data () {
      return {
        compass: compass,
        icon1: icon1,
        icon2: icon2,
        icon3: icon3,
        icon4: icon4
      }
    },
    methods: {
      openModal() {
        this.modalOpen = true
      },

      closeModal() {
        this.modalOpen = false
      }
    }
  }
</script>
<style scoped>
  .top-text, .bottom-text {
    font-family: 'Montserrat';
    display: block;
    margin: 0 auto;
  }

  .row-text {
    display: flex;
    color: #6A6C6E;
    font-size: 14px;
    text-align: left;
    align-items: center;
  }

  .compass-image {
    width: 73px;
    height: 73px;
    margin: 35px auto 15px;
  }

  .top-text, .headings {
    display: block;
    margin: 0 auto;
  }

  .top-text .heading {
    margin: auto;
    font-size: 20px;
    font-weight:bold;
    text-align: center;
  }

  .top-text .sub-heading {
    font-size: 14px;
    color: #6A6C6E;
    text-align: center;
    font-style: italic;
  }

  .row-image {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      height: 40px;
    }
  }

  .list-row {
    margin: 30px 0;
  }
</style>
