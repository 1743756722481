<template>
  <form ref="form" action="/admin/people_importer" method="POST" enctype="multipart/form-data"> 
    <input type='hidden' name='authenticity_token' :value='authToken' />
    <input ref="file" type='file' name='file' hidden @change="onFileChange" />
    <a href="#" class="link-with-icon" @click.prevent="onButtonClick">
      <i class='fa fa-plus-circle fa-3x' />
      {{t('admin.managers.index.import_from_xlsx')}}
    </a>
  </form> 
</template>
<script>
  import { AUTH_TOKEN } from '../../helpers/globals'
  export default {
    data() {
      return {
        authToken: AUTH_TOKEN,
      }
    },

    methods: {
      onButtonClick() {
        this.$refs.file.click()
      },
      onFileChange() {
        this.$refs.form.submit()
      },
    },
  }
</script>
