<template>
  <tr :data-object-id="survey.id">
    <td>
      <router-link class="link-with-icon" :to='{name: "survey", params: { id: survey.id}}'>
        <i class="survey-icon"></i>
        {{survey.name}}
      </router-link>
    </td>
    <td>{{survey.questions.length}}</td>
    <td>{{survey.responses_count}}</td>
    <td>{{responseRate}}%</td>
    <td>{{sentAtField}}</td>
    <td class="action-icons">
      <router-link
        v-if="survey.can_update"
        class="link-with-icon"
        :to='{name: "editSurvey", params: { id: survey.id}}'
      >
        <i class="edit-icon"></i>
      </router-link>
      <a
        href="javascript:void(0)"
        v-if="survey.can_send_survey"
        class="link-with-icon"
        @click="openSendSurveyModal"
      >
        <i class="send-icon"></i>
      </a>
      <a
        href="javascript:void(0)"
        v-if="survey.can_destroy"
        class="link-with-icon"
        @click="destroySurvey"
        data-action="delete"
      >
        <i class="close-icon grey"></i>
      </a>
    </td>
  </tr>
</template>
<script>
export default {
  props: ["survey"],

  computed: {
    sentAtField() {
      if (this.survey.sent_at) {
        return (
          this.t("components.ad_hoc_surveys.survey_row.sent") + " " +
          this.l(this.survey.sent_at, { format: "time.formats.full" })
        );
      } else if (this.survey.sending_scheduled_at) {
        return (
          this.t("components.ad_hoc_surveys.survey_row.scheduled") + " " +
          this.l(this.survey.sending_scheduled_at, {
            format: "time.formats.full"
          })
        );
      } else {
        return this.t("components.ad_hoc_surveys.survey_row.not_sent");
      }
    },
    responseRate() {
      if (this.survey.recipients_count) {
        return Math.round(
          (100 * this.survey.responses_count) / this.survey.recipients_count
        );
      } else {
        return 0;
      }
    }
  },

  methods: {
    openSendSurveyModal() {
      this.$emit("onSendSurvey", this.survey);
    },

    destroySurvey() {
      if (
        confirm(
          this.t("components.ad_hoc_surveys.survey_row.delete_confirmation")
        )
      ) {
        this.$store
          .dispatch("deleteSurvey", this.survey.id)
          .then(() =>
            this.$root.$emit("flash", {
              notice: this.t(
                "components.ad_hoc_surveys.survey_row.succesfully_deleted"
              )
            })
          )
          .catch(() =>
            this.$root.$emit("flash", {
              notice: this.t(
                "components.ad_hoc_surveys.survey_row.failed_to_delete"
              ),
              type: "error"
            })
          );
      }
    }
  }
};
</script>

<style scoped>
.fa-list-ol {
  margin-right: 12px;
  color: #56bdc6;
}
.action-icons {
  text-align: right;
}
.link-with-icon {
  display: inline-block;
}
.action-icons .link-with-icon {
  margin-left: 10px;
}
.send-icon {
  background-image: url("./images/ahs-send.svg");
  background-size: 20px 20px;
  background-position: center;
  background-repeat: no-repeat;
  display: inline-block;
  width: 20px;
  height: 20px;
  transition: background 0.2s;
  vertical-align: middle;
}
.send-icon:hover {
  filter: contrast(0%);
}

.survey-icon {
  background-image: url("./images/ahs-survey.svg");
  background-size: 20px 20px;
  background-position: center;
  background-repeat: no-repeat;
  display: inline-block;
  width: 20px;
  height: 20px;
  transition: background 0.2s;
  vertical-align: middle;
  margin-right: 10px;
}
</style>
