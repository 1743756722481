<template>
  <div class='row'>
    <div :class='[widthClass, offsetClass]'>
      <canvas :id='canvasId'></canvas>
    </div>
  </div>
</template>
<script>
  import FatDoughnut from './prototypes/FatDoughnut'
  import { uniqueId } from 'lodash'
  export default {
    props: ['value', 'width'],
    mounted() { new FatDoughnut($(`#${this.canvasId}`), parseInt(this.value)) },
    data() {
      return {
        canvasId: `fat-doughnut-${uniqueId()}`,
        dataWidth: this.width || 12
      }
    },
    computed: {
      widthClass() {
        return `col-md-${this.dataWidth}`
      },
      offsetClass() {
        let offset = (12 - this.dataWidth) / 2
        if(offset > 0){
          return `offset-md-${offset}`
        }
      }
    }

  }
</script>
