<template>
  <button class="btn btn-primary export-button" @click="download">{{t('analysis.components.show.export_comments_button')}}</button>
</template>

<style scoped>
  .export-button {
    margin-right: 15px;
    margin-bottom: 16px;
    line-height: 21px;
    padding-top: 9px;
  }
</style>

<script>
  import qs from 'qs'

  export default {
    props: ['language', 'filteredDepartmentIds'],
    computed: {
      dynamicDownloadUrl() {
        let baseUrl = '/analysis/feedback_answers/export'
        const params = {
            language: this.language,
            department_ids: this.filteredDepartmentIds,
            filtered_labels: this.$store.getters.currentSurveyQuestionTimePeriodFilter
        }
        let stringifiedParams = qs.stringify(params)
        return `${baseUrl}?` + stringifiedParams
      }
    },
    methods: {
      download() {
        window.location = this.dynamicDownloadUrl
      }
    }
  }
</script>