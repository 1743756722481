<template>
  <modal @close='$emit("close")'>
    <h6 slot='header'>
      {{t('components.analysis.components.learn_more_modal.ask_question')}}
    </h6>
    <div slot='body'>
      <div class='title'>{{t('components.analysis.components.learn_more_modal.choose_target_departments')}}</div>
      <div class='department-select'>
        <div v-for='department in departments' class='form-group'>
          <input :name="'department-' + department.id" :id="'department-' + department.id" type='checkbox' :value='department.id || " "' v-model='checkedDepartments' :disabled='department.question_scheduled' class='default'/>
          <label :for="'department-' + department.id" :class='{disabled: department.question_scheduled}'>{{department.name}}</label>
        </div>
      </div>
      <div class='question'>
        "{{question.title}}"
      </div>
    </div>
    <div slot='footer'>
      <div class="modal-footer-text">{{t('components.analysis.components.learn_more_modal.receive_date', { date: l(nextSurveyDate)} )}}</div>
      <div class='buttons'>
        <a href='#' @click.prevent='$emit("close")' class='btn btn-primary btn-bordered'>{{t('close')}}</a>
        <button type='button' class='btn btn-primary' :disabled='checkedDepartments.length == 0' @click.prevent='planFeedbackQuestion'>
          {{t('send')}}
        </button>
      </div>
    </div>
  </modal>
</template>

<script>
  import Modal from '../../utilityComponents/Modal'
  import { AUTH_TOKEN } from '../../helpers/globals'
  import axios from 'axios'

  export default {
    props: ['nextSurveyDate'],
    mounted() {
      this.$store.dispatch('fetchFeedbackQuestions')
    },
    data() {
      return {
        checkedDepartments: []
      }
    },

    computed: {
      departments() {
        return this.$store.state.feedbackDepartments
      },

      question() {
        if(this.$store.state.feedbackQuestions.length > 0){
          return this.$store.state.feedbackQuestions[0]
        } else {
          return {} //Return empty object so there is no error when calling `question.title`
        }

      }
    },

    methods: {
      planFeedbackQuestion() {
        this.$store.dispatch('planFeedbackQuestion', this.checkedDepartments).
          then(() => this.$emit('submitted')).
          catch(() => this.$emit('failed'))
      }
    },
    components: { Modal }
  }
</script>

<style scoped>
  .form-group label.disabled {
    text-decoration: line-through;
  }

  .form-group input {
    appearance: checkbox;
    -webkit-appearance: checkbox;
    -moz-appearance: checkbox;
    -ms-progress-appearance: checkbox;
  }

  .form-group {
    margin-bottom: 5px;
  }
  .question {
    font-weight: bold;
    font-size: 1.1rem;
  }

  .department-select {
    max-height: 200px;
    overflow-y: auto;
  }
</style>
