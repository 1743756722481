<template>
  <div>
    <div class='multi-line-chart-container'>
      <canvas :id='canvasId'>
      </canvas>
    </div>
  </div>
</template>
<script>
  import { uniqueId } from 'lodash'
  import MultiLineChart from './prototypes/MultiLineChart'

  export default {
    props: ['dataSets', 'labels', 'benchmarkValue'],
    mounted() {
      this.initializeChart()
    },
    data() {
      return {
        canvasId: `line-chart-${uniqueId()}`,
        chartObject: null
      }
    },
    watch: {
      dataSets() {
        if(this.chartObject) {
          const data = this.dataSets
          // BenchmarkValue is used when there is only one benchmark
          // possible on the chart
          if(this.benchmarkValue && data[0]) {
            data[0].benchmark = this.benchmarkValue
            data[0].showBenchmark = true
            data[0].benchmarkColor = '#000'
          }

          this.chartObject.chart.options.scales.xAxes[0].time.unitStepSize = this.$store.state.unitStepSize
          this.chartObject.chart.options.scales.xAxes[0].time.displayFormats = this.$store.state.displayFormats
          this.chartObject.update(data, this.labels)
        }
      }
    },
    methods: {
      initializeChart() {
        this.chartObject = new MultiLineChart($(`#${this.canvasId}`), this.dataSets)
      }
    }
  }
</script>

<style>
</style>

