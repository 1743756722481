<template>
  <div v-show="!loading">
    <div class="main-title" v-if="!ad_hoc_survey.id">
      {{t(
      "components.ad_hoc_surveys.survey.create")}}
    </div>
    <div class="main-title" v-if="ad_hoc_survey.id">
      {{t(
      "components.ad_hoc_surveys.survey.edit")}}
    </div>

    <div class="survey-form">
      <div class="name-input">
        <div class="icon-block">
          <i class="fa fa-plus-circle fa-4x"></i>
        </div>
        <div class="input-wrapper">
          <form class="w-100">
            <bootstrap-input-field
              label="Title"
              placeholder="Enter survey title here"
              v-model="ad_hoc_survey.name"
              inputName="ahs[name]"
              inputId="name"
              :errors='errors.name && [t("components.ad_hoc_surveys.survey.blank")]'
            />
            <bootstrap-select
              label="Language"
              :label-tooltip="t('components.ad_hoc_surveys.survey.language_tooltip')"
              :options="localeOptions"
              :clearable="false"
              inputId="locale"
              inputName="ahs[locale]"
              v-model="ad_hoc_survey.locale"
              @input="initializeOptions(ad_hoc_survey.locale)"
            />
            <bootstrap-input-field
              label="Survey Description"
              placeholder="Enter a short email memo for survey recipients. Describe the purpose of this custom survey."
              v-model="ad_hoc_survey.description"
              inputName="ahs[description]"
              inputId="description"
              :errors='errors.description && [t("components.ad_hoc_surveys.survey.blank")]'
              :textarea="true"
            />
          </form>
        </div>
      </div>

      <question-form
        v-bind:questions="ad_hoc_survey.questions"
        v-bind:errors="errors.questions"
        v-bind:defaultOptions="question_options"
        @clearErrors="clearErrors"
      />

      <div class="action-buttons pt-3">
        <router-link
          class="btn btn-primary btn-bordered btn-lg pointer mr-3"
          :to="{name: 'surveys'}"
        >{{t('cancel')}}</router-link>

        <span
          class="btn btn-primary btn-lg pointer"
          :disabled="loading"
          @click.prevent="persistSurvey"
          data-action="save"
        >{{t('save')}}</span>
      </div>
    </div>
  </div>
</template>
<script>
import BootstrapInputField from "../utilityComponents/BootstrapInputField";
import BootstrapSelect from "../utilityComponents/BootstrapSelect";
import QuestionForm from "./QuestionForm";
import { flatMap } from "lodash";

export default {
  created() {
    // fetch the data when the view is created and the data is
    // already being observed
    this.fetchSurvey();
  },
  watch: {
    // call again the method if the route changes
    $route: "fetchSurvey"
  },
  data() {
    return {
      ad_hoc_survey: this.initializeSurvey(),
      errors: this.initializeErrors(),
      loading: true,
      question_options: this.initializeOptions("en")
    };
  },
  computed: {
    localeOptions() {
      return this.$store.state.localeOptions;
    }
  },
  components: {
    BootstrapInputField,
    BootstrapSelect,
    QuestionForm
  },
  methods: {
    initializeOptions(locale) {
      I18n.locale = locale;
      this.question_options = {
        labeled_step_slider_1: [
          { title: I18n.t(`ad_hoc.strongly_disagree`) },
          { title: I18n.t(`ad_hoc.disagree`) },
          { title: I18n.t(`ad_hoc.neutral`) },
          { title: I18n.t(`ad_hoc.agree`) },
          { title: I18n.t(`ad_hoc.strongly_agree`) }
        ],
        labeled_step_slider_2: [
          { title: I18n.t(`ad_hoc.never`) },
          { title: I18n.t(`ad_hoc.rarely`) },
          { title: I18n.t(`ad_hoc.sometimes`) },
          { title: I18n.t(`ad_hoc.mostly`) },
          { title: I18n.t(`ad_hoc.always`) }
        ],
        step_slider: [
          { title: "1" },
          { title: "2" },
          { title: "3" },
          { title: "4" },
          { title: "5" }
        ]
      };

      return this.question_options;
    },
    initializeSurvey() {
      return {
        id: null,
        name: null,
        locale: "en",
        description: null,
        questions: [],
        sent_at: null
      };
    },

    initializeErrors() {
      return {
        name: false,
        description: false,
        questions: []
      };
    },

    clearErrors() {
      return (this.errors = this.initializeErrors());
    },

    fetchSurvey() {
      const surveyId =
        this.$route.params.id && parseInt(this.$route.params.id, 10);
      this.loading = true;
      if (surveyId) {
        this.$store
          .dispatch("fetchSurveys")
          .then(() => {
            this.ad_hoc_survey = this.$store.state.surveys.find(
              s => s.id === surveyId
            );
            this.initializeOptions(this.ad_hoc_survey.locale)
            this.loading = false;
          })
          .catch(({ response }) => {
            this.loading = false;
          });
      } else {
        this.ad_hoc_survey = this.initializeSurvey();
        this.loading = false;
      }
    },
    
    validateSurvey() {
      this.errors = this.initializeErrors();

      const { name, description, questions } = this.ad_hoc_survey;
      let errorFlag = false;

      const errors = {
        name: !!!name,
        description: !!!description,
        questions: questions.map(question => ({
          title: !!!question.title,
          type: !!!question.type,
          question_options:
            question.type === "custom" && question.question_options
              ? question.question_options.map(option => !!!option.title)
              : false
        }))
      };
      this.errors = errors;

      if (errors.name || errors.description) errorFlag = true;

      if (questions.length > 0)
        errors.questions.map(question => {
          if (question.title || question.type) errorFlag = true;
          if (question.question_options.length > 0)
            question.question_options.map(option => {
              if (option) errorFlag = true;
            });
        });

      return errorFlag;
    },

    persistSurvey() {
      this.loading = true;
      if (this.validateSurvey()) {
        this.loading = false;
        this.$root.$emit("flash", {
          notice: this.t(
            "components.ad_hoc_surveys.send_survey_modal.cannot_save"
          ),
          type: "error"
        });
      } else
        this.$store
          .dispatch("persistSurvey", this.ad_hoc_survey)
          .then(data => {
            this.ad_hoc_survey = data;
            this.loading = false;
            this.$emit("surveyPersisted");
            this.$root.$emit("flash", {
              notice: this.t(
                "components.ad_hoc_surveys.send_survey_modal.saved"
              )
            });
            this.$router.push({
              name: "survey",
              params: { id: this.ad_hoc_survey.id }
            });
          })
          .catch(({ response }) => {
            this.$root.$emit("flash", {
              notice: this.t(
                "components.ad_hoc_surveys.send_survey_modal.cannot_save"
              ),
              type: "error"
            });
            this.loading = false;
          });
    }
  }
};
</script>
