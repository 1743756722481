<template>
  <div class='analysis-form'>
    <h2 class='sub-title mb-4'>
      {{managerModalTitle}}
    </h2>
    <div class='row'>
      <bootstrap-input-field
        :label="t('activerecord.attributes.manager.first_name')"
        v-model='first_name'
        inputName='manager[first_name]'
        inputId='manager_first_name'
        :errors='errors.first_name'
        wrapperClass='col-6'
      />
      <bootstrap-input-field
        :label="t('activerecord.attributes.manager.last_name')"
        v-model='last_name'
        inputName='manager[last_name]'
        inputId='manager_last_name'
        :errors='errors.last_name'
        wrapperClass='col-6'
      />
    </div>
    <div class='row'>
      <bootstrap-input-field
        :label="t('activerecord.attributes.manager.email')"
        v-model='email'
        inputName='manager[email]'
        inputId='manager_email'
        :errors='errors.email'
        wrapperClass='col-6'
      />
      <bootstrap-select
        :label="t('activerecord.attributes.manager.role')"
        v-model='role'
        inputName='manager[role]'
        inputId='manager_role'
        :options="managerRoles"
        :errors='errors.role'
        :label-tooltip="t('components.management.manager_form.role_tooltip')"
        wrapperClass='col-6'
        :clearable='false'
        :disabled='!canChangeRole'
      />
       <bootstrap-input-field
        :label="t('activerecord.attributes.manager.phone_number')"
        v-model='phone_number'
        inputName='manager[phone_number]'
        inputId='manager_phone_number'
        :errors='errors.phone_number'
        wrapperClass='col-6'
      />
    </div>
    <div class='form-separator'></div>
    <div id='departments-container'>
      <div class='departments-overlay' v-show='role != "regular"' v-tooltip.top='t("components.management.manager_form.departments_select_tooltip")'></div>
      <h5 class='responsible-departments mb-2'>
        {{t('components.management.manager_form.responsible_departments')}}
      </h5>
      <multiple-departments-select
        class='departments-select-holder'
        v-model='selectedDepartments'
        :department-options='departmentOptions'
        :selectable-department-options='visibleDepartmentOptions'
        :errors='errors.department_ids'
        :analysis-groups='visibleAnalysisGroups'
        :analysis-groups-title='t("components.management.manager_form.add_from_analysis_groups")'
        :label="t('activerecord.attributes.manager.departments')" />
    </div>
    <div class="analysis-groups-container" v-show='role == "group_admin"'>
      <h5 class='responsible-departments mb-2'>
        {{t('components.management.manager_form.responsible_analysis_groups')}}
      </h5>
      <div class="row">
        <div class="col-md-6">
          <multiple-analysis-group-select
            class='analysis-group-select-holder'
            v-model='selectedAnalysisGroups'
            :errors='errors.analysis_group_ids'
            :analysis-group-options='analysisGroupOptions'
            :selectable-analysis-group-options='visibleAnalysisGroupOptions'
            :label="t('activerecord.attributes.manager.analysis_groups')" />
        </div>
      </div>
    </div>
    <div class='action-buttons pt-3'>
      <router-link class='btn btn-primary btn-bordered btn-lg pointer mr-3' :to="{name: 'managers'}">{{t('cancel')}}</router-link>
      <span class='btn btn-primary btn-lg pointer' @click='persistManager'>{{t('save')}}</span>
    </div>
  </div>
</template>
<script>
  import BootstrapInputField from '../../utilityComponents/BootstrapInputField'
  import BootstrapSelect from '../../utilityComponents/BootstrapSelect'
  import MultipleDepartmentsSelect from '../../utilityComponents/MultipleDepartmentsSelect'
  import MultipleAnalysisGroupSelect from '../../utilityComponents/MultipleAnalysisGroupSelect'

  export default {
    created() {
      this.updateState()
    },

    mounted() {
      const container = document.querySelector('#departments-container')
      const overlay = document.querySelector('.departments-overlay')
      overlay.style.height = container.offsetHeight + 'px'
    },

    beforeRouteUpdate(to, from, next) {
      this.updateState()
      next()
    },

    watch: {
      role() {
        if(this.role != 'regular') {
          this.selectedDepartments = []
        }
        if (this.role != 'group_admin') {
          this.selectedAnalysisGroups = []
        }
      }
    },

    data() {
      return {
        selectedDepartments: [],
        selectedAnalysisGroups: [],
        first_name: '',
        last_name: '',
        role: 'admin',
        email: '',
        id: null,
        phone_number: '',
        canChangeRole: true
      }
    },

    computed: {
      managerModalTitle() {
        if(this.id) {
          return this.t('components.management.manager_modal.edit_title')
        } else {
          return this.t('components.management.manager_modal.new_title')
        }
      },

      errors() {
        return this.$store.state.formErrors
      },

      successfulManagerNotice() {
        if(this.id) {
          // Full name attribute might not be up to date
          return this.t('components.management.manager_modal.successfully_updated', { manager_name: this.first_name + ' ' + this.last_name })
        } else {
          // Full name attribute not yet available
          return this.t('components.management.manager_modal.successfully_saved', { manager_name: this.first_name + ' ' + this.last_name })
        }
      },

      managerRoles() {
        return this.$store.state.roleOptions
      },

      visibleAnalysisGroups() {
        return this.$store.state.visibleAnalysisGroups
      },

      analysisGroupOptions() {
        return this.$store.state.analysisGroups.map( group => { return { label: group.name, value: group.id } })
      },

      visibleAnalysisGroupOptions() {
        return this.$store.state.visibleAnalysisGroups.map( group => { return { label: group.name, value: group.id } })
      },

      departmentOptions() {
        return this.$store.state.departmentOptions
      },

      visibleDepartmentOptions() {
        return this.$store.state.visibleDepartmentOptions
      }
    },

    methods: {
      persistManager() {
        this.$store.dispatch('persistManager', {
          id:             this.id,
          email:          this.email,
          role:           this.role,
          first_name:     this.first_name,
          last_name:      this.last_name,
          phone_number:   this.phone_number,
          department_ids: this.selectedDepartments.map(d => d.value),
          analysis_group_ids: this.selectedAnalysisGroups.map(ag => ag.value)
        })
          .then(() => {
            this.$root.$emit('flash', { notice: this.successfulManagerNotice, type: 'success' })
            this.$router.replace({name: 'managers'})
          })
      },

      updateState(nextId) {
        const id = nextId || this.$route.params.id

        if(id) {
          const manager = this.$store.state.managers.find(m => m.id == parseInt(id))
          if(manager) {
            this.first_name = manager.first_name
            this.last_name = manager.last_name
            this.email = manager.email
            this.role = manager.role
            this.id = manager.id
            this.phone_number = manager.phone_number
            this.selectedDepartments = this.departmentOptions.filter(dep => manager.department_ids.indexOf(dep.value) > -1)
            this.selectedAnalysisGroups = this.analysisGroupOptions.filter(group => manager.analysis_group_ids.indexOf(group.value) > -1)
            this.canChangeRole = manager.can_change_role
          } else {
            // If can't find analysisGroup, go back to index
            this.$router.replace({name: 'managers'})
          }
        }
      }
    },

    components: {
      BootstrapInputField,
      BootstrapSelect,
      MultipleDepartmentsSelect,
      MultipleAnalysisGroupSelect
    }
  }
</script>

<style>
  .departments-overlay {
    width: 100%;
    opacity: 0.8;
    background-color: #fff;
    cursor: not-allowed;
    position: absolute;
    z-index: 1000;
  }
</style>
