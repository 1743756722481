<template>
  <div id='departments-stage' class='stage-container departments-stage'>
    <div class="stage-description text-center">
      <img src="/departments_logo.svg" alt="">
      <h2 class="stage-description-title">{{t('components.onboarding.departments_stage.title')}}</h2>
      <h4 class="stage-description-subtitle">{{t('components.onboarding.departments_stage.subtitle')}}</h4>
    </div>

    <transition name="component-max-height" appear v-on:appear="matchHeight" v-on:after-appear="removeHelper">
      <div class='overflow-hidden' ref="infoBox" v-bind:style='{maxHeight: maxHeightHelper}'>
        <div class="stage-content">
          <department-form />
          <div class='stage-list departments-list'>
            <department-row
              v-for='department in departments'
              :department='department'
              :key='department.id' />
          </div>
        </div>
        <wizard-buttons
          :hideBack='true'
          :allowNext='true'
          :allowSkip='true' />
      </div>
    </transition>
  </div>
</template>
<script>
  import DepartmentForm from './DepartmentForm'
  import DepartmentRow from './DepartmentRow'
  import WizardButtons from './WizardButtons'

  export default {
    data() {
      return {
        maxHeightHelper: '1000px'
      }
    },

    computed: {
      departments() {
        return this.$store.state.departments
      }
    },

    methods: {
      matchHeight() {
        this.maxHeightHelper = `${this.$refs.infoBox.scrollHeight}px`
      },

      removeHelper() {
        this.maxHeightHelper = 'none'
      }
    },

    components: {
      DepartmentRow,
      DepartmentForm,
      WizardButtons
    }
  }
</script>
