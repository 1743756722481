<template>
  <div id='all-results'>
    <slot></slot>
  </div>
</template>
<script>
  import store from './store'
  import { each, indexOf } from 'lodash'

  export default {
    store: store,
    data() {
      return {
        filterOn: false,
      }
    },
    mounted() {
      this.$on('filter', this.catchFilter)
    },
    methods: {
      catchFilter(code){
        this.$store.commit('setActiveMetric', code)
        this.removeFade()
        if (code == null) return

        this.filterOn = true
        _.each(this.$children, (child) =>{
          if(this.shouldBeFaded(child, code)) {
            child.$el.classList.add('filter-faded')
          }
        })
      },
      shouldBeFaded(comp, code) {
        // Does not include metric or is not the metric
        return (!((comp.includedMetrics && indexOf(comp.includedMetrics, code) > -1) ||
          (comp.metricCode == code)))
      },
      removeFade() {
        this.filterOn = false
        _.each(this.$children, (child) => {
          child.$el.classList.remove('filter-faded')
        })
      },
    }
  }
</script>
