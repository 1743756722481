<template>
  <div class='form-group' :class='wrapperComputedClass'>
    <abbr v-if='requiredStar' title="required">*</abbr>
    <label class='control-label' :for='inputId' :class='labelClass'>
      {{label}}
      <i v-if='labelTooltip' v-tooltip='labelTooltip' class="fa fa-question-circle fa-lg"></i>
    </label>
    <improved-select
      :id='inputId'
      :name='inputName'
      :options='options'
      :value='value'
      :placeholder='placeholder'
      :clearable='clearable'
      :disabled='disabled'
      @input='emitInput' />
    <span v-if='hasErrors' class='help-block'>{{errors[0]}}</span>
    <input type='hidden' :name='inputName' :value='selectedValue' />
  </div>
</template>

<script>
  import ImprovedSelect from './ImprovedSelect'
  export default {
    props: ['value', 'label', 'wrapperClass', 'options', 'labelClass',
    'inputName', 'inputId', 'errors', 'labelTooltip',
    'placeholder', 'clearable', 'disabled', 'requiredStar'],

    data() {
      return {
        selectedValue: this.value
      }
    },

    computed: {
      wrapperComputedClass() {
        if(this.hasErrors) {
          return this.wrapperClass + ' has-errors'
        } else {
          return this.wrapperClass
        }
      },
      hasErrors() {
        return this.errors && this.errors.length > 0
      }
    },

    methods: {
      emitInput(value) {
        this.$emit('input', value)
        this.selectedValue = value
      }
    },

    components: { ImprovedSelect }
  }
</script>
