<template>
  <tr :data-object-id='analysisGroup.id'>
    <td>{{analysisGroup.name}}</td>
    <td>{{analysisGroup.users_count}}</td>
    <td class='help-cursor' v-tooltip='departmentsList'>
      {{analysisGroup.departments.length}}
    </td>
    <td class='text-right'>
      <router-link class='mr-2' :to='{name: "editAnalysisGroup", params: { id: analysisGroup.id }}' data-action='edit'>
        <i class='edit-icon'></i>
      </router-link>
      <span class='pointer' @click='destroyAnalysisGroup' data-action='delete'>
        <i class='close-icon grey'>
        </i>
      </span>
    </td>
  </tr>
</template>
<script>
  export default {
    props: ['analysisGroup'],

    computed: {
      departmentsList() {
        return this.analysisGroup.departments.map(d => d.name).sort().join('</br>')
      }
    },

    methods: {
      destroyAnalysisGroup() {
        if(confirm(this.t('components.management.analysis_group_row.delete_confirmation'))) {
          this.$store.dispatch('deleteAnalysisGroup', this.analysisGroup.id).
            then(() => this.$root.$emit('flash', { notice: this.t('components.management.analysis_group_row.succesfully_deleted') })).
            catch(() => this.$root.$emit('flash', { notice: this.t('components.management.analysis_group_row.failed_to_delete'), type: 'error' }))
        }
      }
    }

  }
</script>
