import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
import { findIndex } from 'lodash'
import axios from 'axios'
const AUTH_TOKEN = $('[name="csrf-token"]')[0].content

export default new Vuex.Store({
  state: {
    departments: [],
    parentOptions: [],
    analysisGroupOptions: [],
    manager: null
  },
  mutations: {
    addOrReplaceDepartment(state, department) {
      const departmentIndex = findIndex(state.departments, (d) => d.id == department.id)
      if(departmentIndex > -1) {
        state.departments.splice(departmentIndex, 1, department)
      } else {
        state.departments.push(department)
      }
    },

    removeDepartment(state, department) {
      const departmentIndex = state.departments.findIndex((d) => d.id == department.id)
      state.departments.splice(departmentIndex, 1)
    },

    setManager(state, manager) {
      state.manager = manager
    }
  },
  actions: {
    fetchDepartments({state}) {
      axios.get('/admin/departments.json', {})
        .then(({ data }) => state.departments = data)
    },

    persistDepartment({state, commit, dispatch}, department) {
      return new Promise((resolve, reject) => {
        axios({
          method: department.id ? 'PUT' : 'POST',
          url: `/admin/departments/${department.id || ''}`,
          data: {
            authenticity_token: AUTH_TOKEN,
            department: department
          },
        })
        .then(({data}) => {
          commit('addOrReplaceDepartment', data)
          resolve(data)

        })
        .catch(reject)
      })
    },

    deleteDepartment({ commit }, department) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'DELETE',
          url: `/admin/departments/${department.id}`,
          data: { authenticity_token: AUTH_TOKEN }
        })
        .then((response) => {
          commit('removeDepartment', department)
          resolve(response)
        })
        .catch(reject)
      })
    },

    fetchAnalysisGroups({ state }) {
      return new Promise((resolve, reject) => {
        axios.get('/admin/analysis_groups.json').
        then(({ data }) => {
          state.analysisGroupOptions = data.map( group => { return { label: group.name, value: group.id } })
          resolve(data)
        }).catch(reject)
      })
    }
  }
})
