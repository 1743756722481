<template>
  <form>
    <div class='row'>
      <bootstrap-input-field
        :label="t('activerecord.attributes.department.name')"
        v-model='department.name'
        inputName='department[name]'
        inputId='department_name'
        :errors='department.errors.name'
        wrapperClass='col-md-6'
      />
      <div class='form-group col-md-6'>
        <div class='form-buttons pull-right'>
          <input type='submit' class='btn btn-primary btn-lg pull-right'
            :value='t("save")'
            @click.prevent='persistDepartment'
            :disabled='loading'/>
          <button type='reset' class='btn btn-primary btn-bordered btn-lg pull-right'>
            {{t('reset')}}
          </button>
        </div>
      </div>
    </div>
    <div class="row">
      <div v-show='manager.role === "group_admin"' class='form-group col-md-6'>
        <multiple-analysis-group-select
          class='analysis-group-select-holder'
          v-model='selectedAnalysisGroups'
          :errors='department.errors.analysis_group_ids'
          :analysis-group-options='analysisGroupOptions'
          :label="t('activerecord.attributes.manager.analysis_groups')" />
      </div>
    </div>
  </form>
</template>

<script>
  import BootstrapInputField from '../../utilityComponents/BootstrapInputField'
  import MultipleAnalysisGroupSelect from '../../utilityComponents/MultipleAnalysisGroupSelect'

  export default {
    data() {
      return {
        department: this.initializeDepartment(),
        loading: false,
        selectedAnalysisGroups: [],
      }
    },

    methods: {
      initializeDepartment() {
        return {
          name: null,
          errors: {}
        }
      },

      persistDepartment() {
        this.loading = true
        this.$store.dispatch('persistDepartment', {
          name: this.department.name,
          analysis_group_ids: this.selectedAnalysisGroups.map(ag => ag.value)
        })
          .then(() => {
            this.loading = false
            this.department = this.initializeDepartment()
            this.selectedAnalysisGroups = []
            this.$emit('departmentPersisted')
            this.$root.$emit('flash', {notice: this.t('components.management.department_modal.saved')})
          })
          .catch(({response}) => {
            this.loading = false
            this.department.errors = response.data
          })
      }
    },

    computed: {
      analysisGroupOptions() {
        return this.$store.state.analysisGroupOptions
      },

      manager() {
        return this.$store.state.manager
      }
    },

    components: {
      BootstrapInputField,
      MultipleAnalysisGroupSelect
    }
  }
</script>

<style>
  .form-buttons {
    position: absolute;
    bottom: 0;
  }
</style>
