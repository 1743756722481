<template>
  <div class='row'>
    <div class='col-12 change'>
      <div v-if='dataExists' class='row'>

        <div class='col-3'>
          <div class='change' :class='changeDirection'>
            <i class='fa fa-circle'></i>
          </div>
        </div>
        <div class='col-9 change-content'>
          <div class='change-text'>
            {{changeText}}
          </div>
        </div>
      </div>
      <span v-else>{{t('no_data')}}</span>
    </div>
  </div>
</template>
<script>
  export default {
    props: ['change', 'small', 'metricCode', 'dataExists'],
    computed: {
      changeText() {
        if (this.small) {
          return this.change
        } else {
          return this.t(`shared.${this.changeDirection}`, {value: `${this.change}`}).toUpperCase()
        }
      },
      changeDirection() {
        if(this.change > 0){
          return 'increase'
        }else if(this.change < 0){
          return 'decrease'
        } else {
          return 'still'
        }
      }
    }

  }
</script>

<style>
  .filter-faded {
    -moz-filter: grayscale(100%);
    -webkit-filter: grayscale(100%);
    filter: gray; /* IE6-9 */
    filter: grayscale(100%);
  }

</style>
