<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container" :class='className'>

          <div class="modal-header">
            <slot name="header">
              default header
            </slot>
            <a class="modal-close" href='#' @click.prevent='$emit("close")'>
              <i class='close-icon'></i>
            </a>
          </div>

          <div class="modal-body">
            <slot name="body">
              default body
            </slot>
          </div>

          <div class="modal-footer">
            <slot name="footer">
              default footer
              <button class="modal-default-button" @click="$emit('close')">
                OK
              </button>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
  export default {
    props: ['className']
  }
</script>

<style scoped>
 a i{
  color: #000;
 }
</style>
