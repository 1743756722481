<template>
  <tr :data-object-id='manager.id'>
    <td>{{manager.first_name}}</td>
    <td>{{manager.last_name}}</td>
    <td>{{manager.email}}</td>
    <td>{{manager.translated_role}}</td>
    <td class='text-right'>
      <router-link v-if='canEdit' class='mr-2' :to="{name: 'manager', params: { id: manager.id}}" data-action='edit'>
        <i class='edit-icon'></i>
      </router-link>
      <a v-if='canDelete' href='#' @click.prevent='deleteManager' data-action='delete'>
        <i class='close-icon grey'>
        </i>
      </a>
    </td>
  </tr>
</template>
<script>
  export default {
    props: ['manager'],

    computed: {
      canEdit() {
        return this.manager.can_update;
      },
      canDelete() {
        return this.manager.can_destroy;
      }
    },

    methods: {
      deleteManager() {
        const confirmationText = this.t('components.management.manager_row.deletion_confirmation', { manager_name: this.manager.full_name })
        if(confirm(confirmationText)) {
          const successFlash = {
            notice:  this.t('components.management.manager_row.successfully_deleted', { manager_name: this.manager.full_name }),
            type: 'success'
          }
          const errorFlash = {
            notice:  this.t('components.management.manager_row.error_deleting_manager', { manager_name: this.manager.full_name }),
            type: 'error'
          }

          this.$store.dispatch('deleteManager', this.manager)
          .then(() => this.$root.$emit('flash', successFlash))
          .catch(() => this.$root.$emit('flash', errorFlash))
        }
      }
    }
  }
</script>

<style>
  .close-icon {
    margin-right: 10px;
  }
</style>
