
import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
const AUTH_TOKEN = $('[name="csrf-token"]')[0].content
export default new Vuex.Store({
  state: {
    activeMetric: null
  },
  mutations: {
    setActiveMetric(state, val) {
      return state.activeMetric = val
    }
  }
})
