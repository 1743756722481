<template>
  <div>
    <loading-indicator :visible='isLoading' />
    <router-view v-if='!isLoading'></router-view>
  </div>
</template>
<script>
  import router from './router'
  import store from './store'
  import LoadingIndicator from '../../utilityComponents/LoadingIndicator'
  export default {
    props: ['managerRoles', 'canCreate', 'canImport'],
    store: store,
    router: router,

    created() {
      this.$store.commit('setManagerRolesSelect', this.managerRoles)
      this.$store.commit('setCanCreate', this.canCreate)
      this.$store.commit('setCanImport', this.canImport)
      this.$store.dispatch('initialize').
        catch(() => { this.$root.$emit('flash', { notice: this.t('components.errors.load_error'), type: 'error' })})
    },

    computed: {
      isLoading() {
        return this.$store.state.loading
      }
    },

    components: { LoadingIndicator }
  }
</script>

