<template>
  <div class='users-stage'>
    <div class="stage-description text-center">
      <img src="/users_logo.svg" alt="">
      <h2 class="stage-description-title">{{t('components.onboarding.users_stage.title')}}</h2>
      <h4 class="stage-description-subtitle">{{t('components.onboarding.users_stage.subtitle')}}</h4>
    </div>

    <transition name="component-max-height" appear v-on:before-appear='transitionStarted' v-on:appear="matchHeight" v-on:after-appear="removeHelper">
      <div :class='{"overflow-hidden": transitionInProcess}' ref="infoBox" v-bind:style='{maxHeight: maxHeightHelper}'>
        <div class="stage-content">
          <user-form />
          <div class='stage-list'>
            <user-row v-for='(user, index) in users' :index='index' :user='user' :key='user.id' />
          </div>
          <wizard-buttons
            :allow-back='true'
            :allow-next='true'
            :manually-set-next-stage='true'
            @next='nextClickHandler' />
            <div class="user-notification" v-if='!hasUsers && showNotification'>
              <div class='dismiss-notification' @click='hideNotification'>
                <i class='fa fa-close fa-lg'></i>
              </div>
              <div class='user-notification-title'>{{t('components.onboarding.users_stage.not_enough_title')}}</div>
              <div class='user-notification-subtitle'>{{t('components.onboarding.users_stage.not_enough_subtitle')}}</div>
            </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
  import UserRow from './UserRow'
  import UserForm from './UserForm'
  import WizardButtons from './WizardButtons'

  export default {
    data(){
      return {
        transitionInProcess: false,
        showNotification: false,
        maxHeightHelper: '1000px'
      }
    },

    computed: {
      users() {
        return this.$store.state.users
      },

      hasUsers() {
        return this.$store.state.users.length > 0
      },

      allowNext() {
        return this.$store.state.users.length > 0
      }
    },

    methods: {
      nextClickHandler() {
        if (this.$store.state.users.length === 0) {
          this.showNotification = true
        } else {
          this.$store.dispatch('moveToNextStage')
        }
      },

      hideNotification() {
        this.showNotification = false
      },

      matchHeight() {
        this.maxHeightHelper = `${this.$refs.infoBox.scrollHeight}px`
      },

      removeHelper(el) {
        this.maxHeightHelper = 'none'
        this.transitionInProcess = false
      },

      transitionStarted() {
        this.transitionInProcess = true
      }
    },

    components: {
      UserRow,
      UserForm,
      WizardButtons
    }
  }
</script>
