<template>
  <div class="form-group" :class="wrapperComputedClass">
    <i v-if="inputIcon" class="fa" :class="inputIcon"></i>
    <label class="control-label" :for="inputId" :class="labelClass">
      {{label}}
      <i v-if="labelTooltip" v-tooltip="labelTooltip" class="fa fa-question-circle fa-lg"></i>
    </label>
    <textarea
      v-if="textarea"
      class="form-control"
      :class="inputClass"
      :name="inputName"
      :id="inputId"
      :value="value"
      :placeholder="placeholder"
      @input="emitInput"
    />
    <input
      v-else
      type="text"
      class="form-control"
      :class="inputClass"
      :name="inputName"
      :id="inputId"
      :value="value"
      :placeholder="placeholder"
      :disabled="disabled"
      @input="emitInput"
    >
    <span v-if="hasErrors" class="help-text">{{errors[0]}}</span>
  </div>
</template>
<script>
export default {
  props: [
    "value",
    "label",
    "wrapperClass",
    "inputClass",
    "labelClass",
    "inputName",
    "inputId",
    "errors",
    "labelTooltip",
    "placeholder",
    "inputIcon",
    "textarea",
    "disabled"
  ],

  computed: {
    wrapperComputedClass() {
      let returnableClass = this.wrapperClass || "";

      if (this.hasErrors) {
        returnableClass += " has-errors";
      }

      if (this.inputIcon) {
        returnableClass += " inner-addon left-addon";
      }

      return returnableClass;
    },
    hasErrors() {
      return this.errors && this.errors.length > 0;
    }
  },

  methods: {
    emitInput(inputEvent) {
      this.$emit("input", inputEvent.target.value);
    }
  }
};
</script>
