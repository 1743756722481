<template>
  <div class='payment-form-wrapper'>
    <LoadingIndicator :visible='loading' />
    <div class="global-notification" v-show="errors.card">
      {{errors.card}}
    </div>
    <form v-show='!loading' @submit='submitPaymentMethod' method='POST' action='/admin/payment'>
      <input type='hidden' name='authenticity_token' :value='authToken' />
      <input type='hidden' name='payment_token' :value='nonce' />
      <div class="payment-form-block">
        <h2 class="payment-form-block-title">{{t('admin.payments.new.payment_method')}}</h2>
        <div class='payment-method-container'>
          <div id='dropin-container' v-show='changePaymentMethod'></div>
          <div class='existing-payment-method' v-show='!changePaymentMethod'>
            <div class='row'>
              <div class='col-9'>
                {{maskedCardNumber}}
              </div>
              <a href='#' class='btn btn-bordered btn-primary text-uppercase btn-sm mt-2 col-2' @click.prevent='switchChangePaymentMethod'>
                {{t('change')}}
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="payment-form-block mb-4">
        <h2 class="payment-form-block-title">{{t('admin.payments.new.billing_info')}}</h2>
        <div class='row'>
          <bootstrap-input-field
            :label="t('custom_models.billing_address.first_name')"
            v-model='billing_address.first_name'
            inputName='billing_address[first_name]'
            inputId='billing_address_first_name'
            :errors='errors.first_name'
            wrapperClass='col-md-6 payment-form-field'
          />
          <bootstrap-input-field
            :label="t('custom_models.billing_address.last_name')"
            v-model='billing_address.last_name'
            inputName='billing_address[last_name]'
            inputId='billing_address_last_name'
            :errors='errors.last_name'
            wrapperClass='col-md-6 payment-form-field'
          />
        </div>
        <div class='row'>
          <bootstrap-input-field
            :label="t('custom_models.billing_address.company')"
            v-model='billing_address.company'
            inputName='billing_address[company]'
            inputId='billing_address_company'
            :errors='errors.company'
            wrapperClass='col-md-6 payment-form-field'
          />
          <bootstrap-input-field
            :label-tooltip= "t('components.billing.payment_form.vat_tooltip')"
            :label="t('custom_models.billing_address.vat_number')"
            v-model='billing_address.vat_number'
            inputName='billing_address[vat_number]'
            inputId='billing_address_vat_number'
            :errors='errors.vat_number'
            wrapperClass='col-md-6 payment-form-field'
          />
        </div>
        <div class='row'>
          <bootstrap-input-field
            :label="t('custom_models.billing_address.line1')"
            v-model='billing_address.line1'
            inputName='billing_address[line1]'
            inputId='billing_address_line1'
            :errors='errors.line1'
            wrapperClass='col-md-6 payment-form-field'
          />
          <bootstrap-input-field
            :label="t('custom_models.billing_address.line2')"
            v-model='billing_address.line2'
            inputName='billing_address[line2]'
            inputId='billing_address_line2'
            :errors='errors.line2'
            wrapperClass='col-md-6 payment-form-field'
          />
        </div>
        <div class='row'>
          <bootstrap-input-field
            :label="t('custom_models.billing_address.city')"
            v-model='billing_address.city'
            inputName='billing_address[city]'
            inputId='billing_address_city'
            :errors='errors.city'
            wrapperClass='col-md-6 payment-form-field'
          />
          <bootstrap-input-field
            :label="t('custom_models.billing_address.state')"
            v-model='billing_address.state'
            inputName='billing_address[state]'
            inputId='billing_address_state'
            :errors='errors.state'
            wrapperClass='col-md-6 payment-form-field'
          />
        </div>
        <div class='row'>
          <bootstrap-input-field
            :label="t('custom_models.billing_address.zip')"
            v-model='billing_address.zip'
            inputName='billing_address[zip]'
            inputId='billing_address_zip'
            :errors='errors.zip'
            wrapperClass='col-md-6 payment-form-field'
          />
          <bootstrap-select
            :label="t('custom_models.billing_address.country')"
            v-model='billing_address.country'
            :options='countriesOptions'
            inputName='billing_address[country]'
            inputId='billing_address_country'
            :errors='errors.country'
            wrapperClass='col-md-6 payment-form-field'
          />
        </div>
      </div>
      <input type='submit' id='submit-button' :value="t('admin.payments.new.save')" class="btn btn-warning btn-small-text btn-lg"/>
      <a href="/admin/billing" class="billing-cancel-plan">{{t('cancel')}}</a>
    </form>
  </div>
</template>
<script>
  import axios from 'axios'
  import dropIn from 'braintree-web-drop-in'
  import BootstrapInputField from '../utilityComponents/BootstrapInputField'
  import BootstrapSelect from '../utilityComponents/BootstrapSelect'
  import LoadingIndicator from '../utilityComponents/LoadingIndicator'
  import { AUTH_TOKEN } from '../helpers/globals'
  export default {
    props: [
      'braintreeToken',
      'totalPricePerMonth',
      'currentManagerEmail',
      'countriesOptions',
      'billingInfo',
      'maskedCardNumber'
    ],

    created() {
      if(this.changePaymentMethod){
        this.createBraintreeDropin()
      } else {
        this.loading = false
      }
    },

    data() {
      return {
        braintreeInstance: null,
        changePaymentMethod: !!!this.maskedCardNumber,
        errors: {},
        loading: true,
        nonce: '',
        billing_address: {
          first_name: this.billingInfo.first_name,
          last_name: this.billingInfo.last_name,
          line1: this.billingInfo.line1,
          line2: this.billingInfo.line2,
          city: this.billingInfo.city,
          state: this.billingInfo.state,
          zip: this.billingInfo.zip,
          country: this.billingInfo.country,
          vat_number: this.billingInfo.vat_number,
          company: this.billingInfo.company
        }
      }
    },

    computed: {
      authToken() {
        return AUTH_TOKEN
      }
    },

    methods: {
      switchChangePaymentMethod() {
        this.changePaymentMethod = true
        this.createBraintreeDropin()
      },

      createBraintreeDropin() {
        dropIn.create({
          authorization: this.braintreeToken,
          container: '#dropin-container',
          threeDSecure: true
        }).then(instance => {
          this.braintreeInstance = instance
          this.loading = false
        })
      },

      submitPaymentMethod(e) {
        e.preventDefault()
        this.loading = true
        this.errors = {}
        this.fetchPaymentToken()
          .then(this.validatePaymentInformation)
          .then(this.submitPaymentInformation)
      },

      submitPaymentInformation() {
        axios({
          method: 'POST',
          url: '/admin/payment',
          data: {
            authenticity_token: this.authToken,
            billing_address: this.billing_address,
            payment_token: this.nonce
          }
        })
        .then(() => {
          this.errors['card'] = null
          window.location = '/admin/billing'
        })
        .catch(response => {
          this.loading = false
          const responseErrors = response.response.data
          this.errors['card'] =
            responseErrors.base
              ? `Something went wrong - ${responseErrors.base.join(", ")}`
              : 'Something went wrong'
          })
        },

      fetchPaymentToken() {
        return new Promise((resolve) => {
          if(this.changePaymentMethod) {
            const params = {
              threeDSecure: {
                amount: this.totalPricePerMonth,
                billingAddress: { }
              }
            }
            this.braintreeInstance.requestPaymentMethod(params, (requestPaymentMethodErr, payload) => {
              if(payload && payload.nonce) {
                this.nonce = payload.nonce
                resolve()
              } else {
                this.loading = false
                console.log(requestPaymentMethodErr)
              }
            })
          } else {
            // If payment method should not be changed, no need to request payment method
            resolve()
          }
        })
      },

      validatePaymentInformation() {
        return new Promise((resolve, reject) => {
          axios({
            method: 'POST',
            url: '/admin/payment/validate',
            data: {
              authenticity_token: this.authToken,
              billing_address: this.billing_address,
              payment_token: this.nonce
            }
          })
          .then(() => resolve())
          .catch(response => {
            this.errors = response.response.data
            this.loading = false
          })
        })
      }
    },

    components: {
      BootstrapInputField,
      BootstrapSelect,
      LoadingIndicator
    }
  }
</script>
