<template>
  <div class='stage-row department-row'>
    <div>
      {{department.name}}
    </div>
    <div class='remove-department remove-row' @click='removeDepartment' data-action='remove-department'>
      <i class='fa fa-trash fa-lg'></i>
    </div>
  </div>
</template>
<script>
  export default {
    props: ['department'],
    methods: {
      removeDepartment() {
        this.$store.dispatch('deleteDepartment', this.department)
      }
    }
  }
</script>
