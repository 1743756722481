<template>
  <div class='row'>
    <div class='departments-select' :class='departmentsSelectClasses'>
      <label class='control-label'>
        {{label}}
      </label>
      <v-select multiple
        :value='value'
        @input='propogateInput'
        :options='filteredDepartmentOptions'
        placeholder='Enter team...'
        id='departments_select'
      />
      <span v-if='hasErrors' class='help-text'>{{errors[0]}}</span>
      <div class='department-tags-holder'>
        <div class='department-tag' v-for='department in value'>
          {{department.label}}
          <i v-if='canRemoveDepartment(department)' class='fa fa-times pointer' @click='removeDepartment(department)'></i>
        </div>
      </div>
    </div>
    <div v-if='hasAnalysisGroups' class='col-md-5'>
      <div class='control-label analysis-groups-title'>
        {{analysisGroupsTitle}}
      </div>

      <div v-for='group in analysisGroups' class='custom-checkbox analysis-group-checkbox' @click='toggleGroupDepartments(group.departments, checked(group.departments))'>
        <span class='custom-checkbox-box' :class='{checked: checked(group.departments)}'>
          <i v-if='checked' class='fa fa-check' />
        </span>
        <span class='custom-checkbox-label'>{{group.name}}</span>
      </div>
    </div>
  </div>
</template>
<script>
  import vSelect from 'vue-select'
  import { difference } from 'lodash'

  export default {
    props: ['value', 'errors', 'analysisGroups', 'departmentOptions', 'selectableDepartmentOptions', 'analysisGroupsTitle', 'label'],

    computed: {
      selectableDepartmentValues() {
        // In case specific selectable department options are not provided
        // we allow to select all passed department options
        if (this.selectableDepartmentOptions) {
          return this.selectableDepartmentOptions.map(department => department.value)
        } else {
          return this.departmentOptions.map(department => department.value)
        }        
      },
      filteredDepartmentOptions() {
        return this.departmentOptions.filter(department => (
          this.selectableDepartmentValues.includes(department.value)
        ))
      },
      hasErrors() {
        return this.errors && this.errors.length > 0
      },

      hasAnalysisGroups() {
        return this.analysisGroups.length > 0
      },

      departmentsSelectClasses() {
        return {
          "has-errors": this.hasErrors,
          "col-md-7 right-bordered": this.hasAnalysisGroups,
          "col-6": !this.hasAnalysisGroups
        }
      }
    },

    methods: {
      canRemoveDepartment(department) {
        return this.selectableDepartmentValues.includes(department.value)
      },
      removeDepartment(department) {
        const tempValue = this.value
        const departmentIndex = this.value.findIndex(d => d.value == department.value)
        tempValue.splice(departmentIndex, 1)
        this.$emit('input', tempValue)
      },

      propogateInput(value) {
        this.$emit('input', value)
      },

      checked(departments) {
        return difference(departments.map(d => d.id), this.value.map(d => d.value)) == 0
      },

      toggleGroupDepartments(departments, checked) {
        const departmentIds = departments.map(d => d.id)
        let tempValue = this.value
        if(checked) {
          tempValue = this.value.filter(d => departmentIds.findIndex(id => d.value == id) < 0)
        } else {
          const addableOptions = this.departmentOptions.filter(d => departmentIds.findIndex(id => d.value == id) > -1)
          addableOptions.forEach(option => {
            if(this.value.findIndex(d => d.value == option.value) < 0) {
              tempValue.push(option)
            }
          })
        }

        this.$emit('input', tempValue)
      }
    },

    components: { vSelect }
  }
</script>
