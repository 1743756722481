<template>
  <div class="response-rate card card-dashboard">
    <div class="response-rate-title">
      <strong>{{t('analysis.summary.index.response')}}</strong>
    </div>
    <ResponseRateDiagram :color='color' :value='value' />
  </div>
</template>
<script>
  import { uniqueId } from 'lodash'
  import ChangeIndicator from './ChangeIndicator'
  import ResponseRateDiagram from './ResponseRateDiagram'

  export default {
    mounted() {
      const $progress = $(`#${this.progressId}`)
      const stringValue = `${this.value}%`;

      setTimeout(function() {
        $progress.css('height', stringValue)
      }, 400)
    },
    props: ['value', 'color'],
    computed: {
      progressId() {
        return `progress-${uniqueId()}`
      }
    }
  }
</script>
