import Loc from '../i18n/i18n'
const I18nInVue = {
  install(Vue, options) {
    Vue.mixin({
      methods: {
        t(string, options) {
          return I18n.t(string, options)
        },
        l(date, opts= {}) {
          if(!date) { return }
          let format = opts.format || 'date.formats.default'
          return I18n.l(format, date);
        }
      }
    })
  }
}

export default I18nInVue
