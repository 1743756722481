<template>
  <div class='improved-select' :id='id'>
    <v-select
      :value.sync='computedValue'
      @input='emitValue'
      :options='options'
      :placeholder='placeholder'
      :clearable='clearable'/>
    <input type='hidden' :value='computedValue' :name='name'  />
  </div>
</template>
<script>
  import vSelect from 'vue-select'

  export default {
    props: ['options', 'value', 'id', 'name', 'placeholder', 'clearable', 'defaultValue'],
    computed: {
      selectValue() {
        return this.options.find(o => o.value == this.value) || null
      }
    },

    data() {
      return {
        selectedValue: this.value
      }
    },

    computed: {
      computedValue(){
        if (this.defaultValue && !this.selectedValue && this.selectedValue === '')
          return this.defaultValue
        return this.selectedValue
      }
    },

    methods: {
      emitValue(val) {
        let value = typeof val == 'object' && val != null ? val.value : val
        if(val) {
          this.$emit('input', value)
          this.selectedValue = value
        } else if(this.clearable) {
          this.$emit('input', null)
          this.selectedValue = null
        }
      }
    },

    components: { vSelect }
  }
</script>
