<template>
  <div id='summary-stage' class='summary-stage'>
    <div class="stage-description text-center">
      <img src="/managers_logo.svg" alt="">
      <h2 class="stage-description-title">{{t('components.onboarding.summary_stage.title')}}</h2>
      <h4 class="stage-description-subtitle">
        {{t('components.onboarding.summary_stage.subtitle_1')}}
        <br />
        <br />
        {{t('components.onboarding.summary_stage.subtitle_2')}}
      </h4>
    </div>

    <div class="stage-content border-radius-0">
      <div class="summary-stage-buttons">
        <a class='summary-stage-button' href='#' @click.prevent='switchToStage(0)'>
          {{departmentsHeaderText}}
        </a>
        <a class='summary-stage-button' href='#' @click.prevent='switchToStage(1)'>
          {{usersHeaderText}}
        </a>
        <a class='summary-stage-button' href='#' @click.prevent='switchToStage(2)'>
          {{managersHeaderText}}
        </a>
      </div>

      <div class="row">
        <div class="col-10 offset-1">
          <div class='stage-list'>
            <div class='stage-row' v-for='departmentObject in departmentObjects' :class='{empty: departmentObject.empty}'>
              {{departmentObject.name}} ({{departmentObject.user_text}})
            </div>
          </div>
        </div>
      </div>

      <wizard-buttons
        :allow-back='true'
        :hide-next='true' />
    </div>
  </div>
</template>
<script>
  import WizardButtons from './WizardButtons'
  import { map } from 'lodash'
  export default {
    computed: {
      departmentsHeaderText() {
        const departments = this.$store.state.departments
        return this.t('components.onboarding.summary_stage.departments_header_text', { count: departments.length })
      },
      managersHeaderText() {
        const managers = this.$store.state.managers
        return this.t('components.onboarding.summary_stage.managers_header_text', { count: managers.length })
      },

      usersHeaderText() {
        const users = this.$store.state.users
        return this.t('components.onboarding.summary_stage.users_header_text', { count: users.length })
      },

      departmentObjects() {
        let departmentMap = map(this.$store.state.departments, (d) => {
          let userCount = this.$store.state.users.filter( u => u.department_id == d.id ).length
          return {
            name: d.name,
            user_text: `${userCount} users`,
            empty: userCount == 0
          }
        })
        const noDepartmentUsers = this.$store.state.users.filter( u => !u.department_id )
        if(noDepartmentUsers.length > 0){
          departmentMap.unshift({
            name: 'No department',
            user_text: `${noDepartmentUsers.length} users`,
            empty: false
          })
        }
        return departmentMap
      }
    },

    methods: {
      switchToStage(stageNumber) {
        this.$store.commit('moveToSpecificStage', stageNumber)
      },

      completeOnboarding() {
        this.$store.dispatch('markOnboadingAsCompleted')
      }
    },

    components: {
      WizardButtons
    }
  }
</script>
