import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import { filter, cloneDeep, minBy, find } from 'lodash'
import { AUTH_TOKEN } from '../../helpers/globals'
import { getRandomColor } from '../../helpers/chart_helpers'

Vue.use(Vuex)

const getReferenceDate = (monthDiff) => {
  const currentDate = new Date()

  return new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() - monthDiff,
    currentDate.getDate() - 1
  )
}

const timePeriodFilters = [
  {
    label: '1 month',
    referenceDate: getReferenceDate(1),
    active: false

  },
  {
    label: '3 months',
    referenceDate: getReferenceDate(3),
    active: true

  },
  {
    label: '6 months',
    referenceDate: getReferenceDate(6),
    active: false,
  },
  {
    label: '12 months',
    referenceDate: getReferenceDate(12),
    active: false
  },
  {
    label: 'All',
    referenceDate: null,
    active: false
  }
]

export default new Vuex.Store({
  state: {
    departments: [],
    analysisGroups: [],
    benchmark: 0,
    componentId: null,
    chartLabels: [],
    timePeriodFilters: cloneDeep(timePeriodFilters.reverse()),
    surveyQuestionTimePeriodFilters: cloneDeep(timePeriodFilters),
    surveyQuestionDepartmentIds: [],
    feedbackCycles: [],
    statisticQuestions: [],
    feedbackQuestions: [],
    feedbackDepartments: [],
    feedbackAnalysisGroups: [],
    managerId: null,
    unitStepSize: 22,
    displayFormats: { day: 'MMM YYYY' }
  },

  getters: {
    filteredLabels(state, getters) {
      const labels = []
      let { referenceDate } = state.timePeriodFilters.find((f) => f.active)
      let data = getters.visibleData.flatMap((d) => d.chartData)

      if (referenceDate) {
        data = data.filter(({ cycle_date }) => referenceDate <= cycle_date)
      } else {
        referenceDate = minBy(data, 'cycle_date').cycle_date
      }

      if (data.length) {
        data.forEach((d) => labels.push(d.cycle_date))
      } else {
        labels.push(referenceDate)
      }

      return [...labels, new Date()]
    },

    filteredQuestionDates(state) {
      const { referenceDate } = state.surveyQuestionTimePeriodFilters.find((f) => f.active)
      const dates = referenceDate ? state.chartLabels.filter((cycleDate) => referenceDate <= cycleDate.date) : state.chartLabels

      return dates.map((cycleDate) => cycleDate.string)
    },

    filteredLineChartData(state, getters) {
      const { referenceDate } = state.timePeriodFilters.find((f) => f.active)

      return getters.visibleData.map((dataset) => {
        const data = referenceDate ? dataset.chartData.filter((o) => referenceDate <= o.cycle_date) : dataset.chartData
        return {
          color: dataset.color,
          values: data.map((o) => o.value)
        }
      })
    },

    visibleData(state) {
      return [
        ...filter(state.departments, 'isChecked'),
        ...filter(state.analysisGroups, 'isChecked')
      ]
    },

    currentSurveyQuestionTimePeriodFilter(state) {
      return state.surveyQuestionTimePeriodFilters.find((filter) => filter.active)
    }
  },

  mutations: {
    changeTimePeriod(state, newTimePeriod) {
      state.timePeriodFilters.forEach ( tp => tp.active = false )
      newTimePeriod.active = true
      const newTimePeriodIndex = state.timePeriodFilters.findIndex( tp => tp.label == newTimePeriod.label )
      state.timePeriodFilters.splice(newTimePeriodIndex, 1, newTimePeriod)

      let { referenceDate } = state.timePeriodFilters.find((f) => f.active)
      if (!referenceDate) {
        referenceDate = minBy(state.chartLabels, 'date').date
      }
      const diffDays = (new Date() - referenceDate) / (1000 * 3600 * 24)

      if (diffDays > 368) {
        state.displayFormats = { day: 'YYYY' }
        // unitStepSize controls the frequency of labels on the X axis
        // calculate the frequency dynamically when user selects “All” filter
        state.unitStepSize = diffDays / 5.5
      } else if (diffDays < 40) {
        state.displayFormats = { day: 'DD MMM YYYY' }
        state.unitStepSize = 7
      } else {
        state.displayFormats = { day: 'MMM YYYY' }
        state.unitStepSize = 30
      }
    },

    changeQuestionTimePeriod(state, newTimePeriod) {
      state.surveyQuestionTimePeriodFilters.forEach ( tp => tp.active = false )
      newTimePeriod.active = true
      const newTimePeriodIndex = state.surveyQuestionTimePeriodFilters.findIndex( tp => tp.label == newTimePeriod.label )
      state.surveyQuestionTimePeriodFilters.splice(newTimePeriodIndex, 1, newTimePeriod)
    },

    setManagerId(state, managerId) {
      state.managerId = Number(managerId)
    },

    setSurveyQuestionDepartmentIds(state, departmentIds) {
      state.surveyQuestionDepartmentIds = departmentIds
    }
  },

  actions: {
    postFilterData({ commit, state }, data) {
      const departmentIds = data.department_ids

      state.surveyQuestionDepartmentIds = departmentIds

      axios.post(
        '/sessions/filtered_department_ids',
        {
          authenticity_token: AUTH_TOKEN,
          department_ids: departmentIds
        }
      )
    },

    fetchSurveyDates({ state, dispatch }) {
      return new Promise(
        function(resolve, reject){
          axios.get('/analysis/data/survey_cycles').
            then(({ data }) => {
              state.chartLabels = data.map((string) => ({ string, date: new Date(string) }))
              Promise.all([dispatch('fetchAnalysisGroups'), dispatch('fetchDepartments')]).then(resolve)
            })
        }
      )
    },

    fetchDepartments({ state }) {
      return new Promise(
        function(resolve, reject){
          axios.get(`/analysis/components/${state.componentId}/departments`).
          then(({ data }) => {
            data.map((d) => {
              d.chartData = []
              d.isChecked = false
              d.color = getRandomColor()
            })
            state.departments = data
            resolve(state.departments)
          })
        }
      )
    },

    fetchAnalysisGroups({ state }) {
      return new Promise(
        function(resolve, reject){
          axios.get(`/analysis/components/${state.componentId}/analysis_groups`).
          then(({ data }) => {
            data.map((g) => {
              g.chartData = []
              g.isChecked = false
              g.color = getRandomColor()
            })
            state.analysisGroups = data
            resolve(state.analysisGroups)
          })
        }
      )
    },

    toggleDepartmentChecked({ dispatch, state }, department_id) {
      let departmentIndex = state.departments.findIndex((d) => d.id == department_id)
      let department = state.departments[departmentIndex]
      department.isChecked = !department.isChecked
      if(department.isChecked && department.chartData.length < 1) {
        dispatch('fetchDepartmentChartData', department)
      }
    },

    toggleAnalysisGroupChecked({ dispatch, state }, analysis_group_id) {
      let analysisGroupIndex = state.analysisGroups.findIndex((g) => g.id == analysis_group_id)
      let analysisGroup = state.analysisGroups[analysisGroupIndex]
      analysisGroup.isChecked = !analysisGroup.isChecked
      if(analysisGroup.isChecked && analysisGroup.chartData.length < 1) {
        dispatch('fetchAnalysisGroupChartData', analysisGroup)
      }
    },

    fetchDepartmentChartData({state}, department) {
      // user || to fetch All department data (where department.id == null)
      axios.get(`/analysis/components/${state.componentId}/data/departments/${department.id || ''}`,{}).
        then(({ data }) => {
          department.chartData = state.chartLabels.map((cycleDate) => {
            const existingData = find(data, { cycle_date: cycleDate.string })

            return { cycle_date: cycleDate.date, value: existingData ? existingData.value : null }
          })
        })
    },

    fetchAnalysisGroupChartData({state}, analysisGroup) {
      axios.get(`/analysis/components/${state.componentId}/data/analysis_groups/${analysisGroup.id || ''}`,{}).
        then(({ data }) => {
          analysisGroup.chartData = state.chartLabels.map((cycleDate) => {
            const existingData = find(data, { cycle_date: cycleDate.string })

            return { cycle_date: cycleDate.date, value: existingData ? existingData.value : null }
          })
        })
    },

    fetchFeedback({ state }) {
      axios.get('/analysis/feedbacks', { params: { component_id: state.componentId }}).
        then(({ data }) => {
          state.feedbackCycles = data
        })
    },

    fetchQuestionStatistics({ state }) {
      axios.get('/analysis/component_question_statistics', { params: { component_id: state.componentId }}).
        then(({ data }) => {
          state.statisticQuestions = data
        })
    },

    planFeedbackQuestion({ state }, checkedDepartments) {
      return new Promise(
        function(resolve, reject){
          axios.post('/analysis/feedback_questions/set_for_survey', {
            authenticity_token: AUTH_TOKEN,
            question_id: state.feedbackQuestions[0].id,
            department_ids: checkedDepartments
          }).
          then((response) => {
            state.feedbackDepartments = response.data
            resolve(response)
          }).
          catch((response) => reject(response))
      })
    },

    fetchFeedbackQuestions({state}) {
      axios.get('/analysis/feedback_questions', { params: { component_id: state.componentId } }).
        then(({ data }) => state.feedbackQuestions = data )
    }
  }
})
