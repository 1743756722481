<template>
  <modal @close='$emit("close")'>
    <div slot='header'>
      {{t('components.management.department_modal.edit')}}
    </div>
    <div slot='body'>
      <div class='row'>
        <bootstrap-input-field
          :label="t('activerecord.attributes.department.name')"
          v-model='department.name'
          inputName='department[name]'
          inputId='department_name'
          :errors='department.errors.name'
          wrapperClass='col-12'
        />
      </div>

      <div v-show='isGroupAdmin' class='form-group'>
        <multiple-analysis-group-select
          class='analysis-group-select-holder'
          v-model='selectedAnalysisGroups'
          :errors='department.errors.analysis_group_ids'
          :analysis-group-options='analysisGroupOptions'
          :label="t('activerecord.attributes.manager.analysis_groups')" />
      </div>
    </div>
    <div slot='footer'>
      <input type='submit' :value='t("save")' @click.prevent='persistDepartment' class='btn btn-primary btn-lg pull-right' :disabled='loading'>
      <button class='btn btn-primary btn-bordered btn-lg pull-right' @click='$emit("close")'>
        {{t('cancel')}}
      </button>
    </div>
  </modal>
</template>
<script>
  import Modal from '../../utilityComponents/Modal'
  import BootstrapInputField from '../../utilityComponents/BootstrapInputField'
  import MultipleAnalysisGroupSelect from '../../utilityComponents/MultipleAnalysisGroupSelect'
  import { cloneDeep } from 'lodash'
  export default {
    props: ['passedDepartment'],

    created() {
      this.selectedAnalysisGroups = this.analysisGroupOptions.filter(group => this.department.analysis_group_ids.includes(group.value))
    },

    data() {
      return {
        department: cloneDeep(this.passedDepartment),
        loading: false,
        selectedAnalysisGroups: []
      }
    },

    methods: {

      persistDepartment() {
        const departmentParams = {
          ...this.department,
          ...(this.isGroupAdmin && { analysis_group_ids: this.selectedAnalysisGroups.map(ag => ag.value) })
        }
        this.loading = true
        this.$store.dispatch('persistDepartment', departmentParams).
          then(() => {
            this.loading = false
            this.department.errors = {}
            this.selectedAnalysisGroups = []
            this.$root.$emit('flash', {notice: this.t('components.management.department_modal.saved')})
            this.$emit('close')
          }).catch(({response}) => {
            this.loading = false
            this.department.errors = response.data
          })
      }
    },

    computed: {
      analysisGroupOptions() {
        return this.$store.state.analysisGroupOptions
      },

      manager() {
        return this.$store.state.manager
      },

      isGroupAdmin() {
        return this.manager.role === 'group_admin'
      }
    },

    components: {
      Modal,
      BootstrapInputField,
      MultipleAnalysisGroupSelect
    }
  }
</script>
