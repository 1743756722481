import '../config/SingleValueDoughnutChartConfig'
import Chart from 'chart.js'
import 'chartjs-plugin-deferred/src/plugin.js'

export default class FatSingleValueDoughnut {
  constructor (domObject, value = 0) {
    let val = $(domObject).data('value') || value
    this.chart = new Chart(domObject, {
        type: 'SingleValueDoughnut',
        responsive: true,
        data: { datasets: [{ data: [val] }] },
        options: {
          cutoutPercentage: 70,
          offsetBackgroundCircle: false,
          valuePostfix: '%',
          aspectRatio: 1,
          plugins: {
            deferred: true
          }
        }
    })
  }
}
