<template>
  <form>
    <div class='row'>
      <div class='col-md-6'>
        <bootstrap-input-field
          :label="t('activerecord.attributes.user.first_name')"
          v-model='user.first_name'
          inputName='user[first_name]'
          inputId='user_first_name'
          :errors='user.errors.first_name'
        />
        <bootstrap-input-field
          :label="t('activerecord.attributes.user.email')"
          v-model='user.email'
          inputName='user[email]'
          inputId='user_email'
          :errors='user.errors.email'
        />
        <bootstrap-select
          :label="t('activerecord.attributes.user.locale')"
          v-model='user.locale'
          inputName='user[locale]'
          inputId='user_locale'
          :errors='user.errors.locale'
          :options='localeOptions'
          :clearable='false'
        />
      </div>
      <div class='col-md-6'>
        <bootstrap-input-field
          :label="t('activerecord.attributes.user.last_name')"
          v-model='user.last_name'
          inputName='user[last_name]'
          inputId='user_last_name'
          :errors='user.errors.last_name'
        />
        <bootstrap-select
          :label="t('activerecord.attributes.user.department')"
          v-model='user.department_id'
          inputName='user[department_id]'
          inputId='user_department_id'
          :errors='user.errors.department_id'
          :options='departmentOptions'
          :clearable='true'
        />
      </div>
    </div>
    <input type='submit' :value='t("save")' @click.prevent='persistUser' class='btn btn-primary btn-lg pull-right' :disabled='loading'>
    <button type='reset' class='btn btn-primary btn-bordered btn-lg pull-right'>{{t('reset')}}</button>
  </form>
</template>
<script>
  import BootstrapInputField from '../../utilityComponents/BootstrapInputField'
  import BootstrapSelect from '../../utilityComponents/BootstrapSelect'
  export default {
    props: ['defaultLocale'],

    data() {
      return {
        user: this.initializeNewUser(),
        loading: false,
        errors: {},
        selectedAnalysisGroups: [],
      }
    },
    computed: {
      localeOptions() {
        return this.$store.state.localeOptions
      },
      departmentOptions() {
        return this.$store.state.departmentOptions
      },
      manager() {
        return this.$store.state.manager
      },
    },
    methods: {
      initializeNewUser() {
        return {
          first_name: null,
          last_name: null,
          email: null,
          department_id: null,
          locale: this.defaultLocale || 'en',
          errors: {}
        }
      },
      persistUser() {
        this.loading = true
        this.$store.dispatch('persistUser', this.user).
          then(() => {
            this.loading = false
            this.$root.$emit('flash', {notice: this.t('components.management.user_row.successful_create_message', { user_name: this.user.email})})
            this.user = this.initializeNewUser()
            this.$emit('userPersisted')

          }).catch(({response}) => {
            this.loading = false
            this.user.errors = response.data
          })
      }
    },

    components: {
      BootstrapSelect,
      BootstrapInputField
    }
  }
</script>
