<template>
  <div class='how-it-works-container'>
    <div class='how-it-works-header' @click='toggleVisibility()'>
      {{ t('funnel.how_it_works.title') }}
      <div class='toggle-how-it-works'>
        <i v-bind:class="['fa', isVisible ? 'fa-chevron-up' : 'fa-chevron-down']" />
      </div>
    </div>
    <div class='how-it-works-body row' :class="{'invisible': !isVisible}">
      <div class='col-2'>
        <div class='image'>
          <img :src='icon1' />
        </div>
        <div class='text'>
          <p>{{ t('funnel.how_it_works.item_1') }}</p>
        </div>
      </div>
      <div class='col-1 arrow'>
        <img :src='arrow' />
      </div>
      <div class='col-2'>
        <div class='image'>
          <img :src='icon2' />
        </div>
        <div class='text'>
          <p>{{ t('funnel.how_it_works.item_2') }}</p>
        </div>
      </div>
      <div class='col-1 arrow'>
        <img :src='arrow' />
      </div>
      <div class='col-2'>
        <div class='image'>
          <img :src='icon3' />
        </div>
        <div class='text'>
          <p>{{ t('funnel.how_it_works.item_3') }}</p>
        </div>
      </div>
      <div class='col-1 arrow'>
        <img :src='arrow' />
      </div>
      <div class='col-2'>
        <div class='image pb-2'>
          <img :src='icon4' />
        </div>
        <div class='text'>
          <p>{{ t('funnel.how_it_works.item_4') }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import icon1 from '../../../assets/images/how-it-works-icon-1.png'
  import icon2 from '../../../assets/images/how-it-works-icon-2.png'
  import icon3 from '../../../assets/images/how-it-works-icon-3.png'
  import icon4 from '../../../assets/images/how-it-works-icon-4.png'
  import arrow from '../../../assets/images/right-arrow.svg'
  export default {
    props: ['sectionVisible'],

    data () {
      return {
        icon1: icon1,
        icon2: icon2,
        icon3: icon3,
        icon4: icon4,
        arrow: arrow,
        isVisible: false
      }
    },

    created() {
      this.isVisible = this.sectionVisible
    },

    methods: {
      toggleVisibility() {
        this.isVisible = !this.isVisible
      }
    }
  }
</script>
<style scoped>
  .how-it-works-container {
    border-style: solid;
    border-color: #55bec5;
    border-width: medium;
    border-radius: 10px;
    margin-bottom: 10px;
  }

  .how-it-works-header {
    background-color: #55bec5;
    height: auto;
    color: white;
    text-align: center;
    align-content: center;
    padding: 10px;
    font-size: 14px;
    font-weight: bold;
    position: static;
    height: 40px;
    .toggle-how-it-works {
      font-size: 16px;
      float: right;
      padding-right: 0.5rem;
      position: relative;
      top: -2px;
    }
  }

  .how-it-works-body {
    justify-content: center;
    padding: 3rem 1rem 1rem;
  }

  .invisible {
    display: none;
  }

  .image {
    height: 80px;
    margin-bottom: 1rem;
    display: flex;
    justify-content: center;
    img {
    align-items: baseline;
    height: 70px;
    }
  }

  .text p {
    display: flex;
    align-items: center;
    text-align: center;
    color: #9e9e9e;
    font-size: 13px;
    font-weight: 500;
  }

  .arrow {
    padding: 1rem 1.5rem;
  }
</style>
