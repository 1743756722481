<template>
  <div>
    <div>
      <router-link class='link-with-icon' :to='{name: "newAnalysisGroup"}'>
        <i class='fa fa-plus-circle fa-3x' />
        {{t('components.management.analysis_groups.index_page.new_group')}}
      </router-link>
    </div>
    <div class='table-responsive'>
      <table class='table mt-5 management-table'>
        <thead>
          <tr>
            <th>
              <span>
                {{t('activerecord.attributes.analysis_group.name')}}
              </span>
            </th>
            <th class='active'>
              <span>
                {{t('activerecord.attributes.analysis_group.users')}}
              </span>
            </th>
            <th>
              <span>
                {{t('activerecord.attributes.analysis_group.departments')}}
              </span>
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <analysis-group-row v-for='group in analysisGroups' :analysis-group='group' :key='group.id' />
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
  import AnalysisGroupRow from './AnalysisGroupRow'
  export default {
    data() {
      return {
        loading: false
      }
    },

    computed: {
      analysisGroups() {
        return this.$store.state.analysisGroups
      }
    },

    components: {
      AnalysisGroupRow
    }
  }
</script>
