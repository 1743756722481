import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

import axios from 'axios'
const AUTH_TOKEN = $('[name="csrf-token"]')[0].content

export default new Vuex.Store({
  state: {
    analysisGroups: [],
    departments: [],
    formErrors: {},
    loading: true
  },

  mutations: {
    removeAnalysisGroup(state, id) {
      const index = state.analysisGroups.findIndex(ag => ag.id == id)
      state.analysisGroups.splice(index, 1)
    },

    addOrReplaceAnalysisGroup(state, group) {
      const index = state.analysisGroups.findIndex(ag => ag.id == group.id)
      if(index > -1) {
        state.analysisGroups.splice(index, 1, group)
      } else {
        state.analysisGroups.push(group)
      }
    }
  },

  actions: {
    initialize({ dispatch }) {
      dispatch('fetchAnalysisGroups')
      dispatch('fetchDepartments')
    },

    fetchAnalysisGroups({ state }) {
      axios.get('/admin/analysis_groups.json').
      then(({ data }) => {
        state.analysisGroups = data
        state.loading = false
      })
    },

    fetchDepartments({ state }) {
      axios.get('/admin/departments.json').
      then(({ data }) => state.departments = data )
    },

    saveAnalysisGroup({ commit, state }, data) {
      state.formErrors = {}
      let method = 'POST'
      let url = '/admin/analysis_groups'
      if(data.id) {
        method = 'PUT'
        url += `/${data.id}`
      }

      return new Promise((resolve, reject) => {
        axios({
          method: method,
          url: url,
          data: {
            authenticity_token: AUTH_TOKEN,
            analysis_group: data
          }
        }).then(({ data }) => {
          commit('addOrReplaceAnalysisGroup', data)
          resolve()
        }).catch(({ response }) => {
          state.formErrors = response.data
          reject()
        })
      })
    },

    deleteAnalysisGroup({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'DELETE',
          url: `/admin/analysis_groups/${id}`,
          data: { authenticity_token: AUTH_TOKEN }
        }).then(() => {
          commit('removeAnalysisGroup', id)
          resolve()
        }).catch(({ response }) => {
          reject()
          throw response
        })
      })
    }
  }
})
