<template>
  <div class='loading-indicator' :class='wrapperClass' v-show='visible'>
    <img src='/loading.gif' />
  </div>
</template>
<script>
  export default {
    props: ['visible', 'wrapperClass']
  }
</script>

<style>
  .loading-indicator {
    text-align: center;
  }
</style>
