<template>
  <table class='table mt-5 management-table'>
    <thead>
      <tr>
        <th>{{t('activerecord.attributes.manager.first_name')}}</th>
        <th>{{t('activerecord.attributes.manager.last_name')}}</th>
        <th>{{t('activerecord.attributes.manager.email')}}</th>
        <th>
          {{t('activerecord.attributes.manager.role')}}
          <i v-tooltip="t('components.management.manager_form.role_tooltip')" class="fa fa-question-circle fa-lg"></i>
        </th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr v-if='loading'>
        <td colspan='5'>
          <loading-indicator  :visible='true' />
        </td>
      </tr>
      <manager-row v-else
        v-for='manager in managers'
        :manager='manager'
        :key='manager.id' />
    </tbody>
  </table>
</template>
<script>
  import ManagerRow from './ManagerRow'
  import LoadingIndicator from '../../utilityComponents/LoadingIndicator'

  export default {
    computed: {
      managers() {
        return this.$store.state.managers
      },

      loading() {
        return this.$store.state.loading
      }
    },

    components: {
      ManagerRow,
      LoadingIndicator
    }
  }
</script>
