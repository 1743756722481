<template>
  <div class='form-group'>
    <label class='control-label' :for='inputId'>
      {{label}}
    </label>

    <datetime
      :input-id='inputId'
      :hidden-name='inputName'
      type='date'
      v-model='date'
      format="dd.MM.yyyy"
      :min-datetime='minDatetime'
      ref='datepicker'
      input-class="vdatetime-input-custom"
    ></datetime>
  </div>
</template>

<script>
  import { Datetime } from "vue-datetime";

  export default {
    components: { datetime: Datetime },
    props: ['value', 'label', 'inputId', 'inputName', 'minDatetime'],
    data() {
      return {
        date: this.value,
      }
    }
  }
</script>

<style scoped>
  ::v-deep input.vdatetime-input-custom {
    padding: 6px 8px;
    font-size: 16px;
    border: solid 1px #ddd;
    color: #444;
  }
</style>