<template>
  <div class='custom-checkbox'>
    <span class='custom-checkbox-box' :class='{checked: checked, error: error}' @click='onClick'>
      <i v-if='checked' class='fa fa-check' />
    </span>
    <span class='custom-checkbox-label' :class='labelClass' @click='onClick' v-html='label'></span>
    <input v-if='addInput' type='hidden' :name='inputName' :id='inputId' :value='selectedValue' />
  </div>
</template>

<script>
  export default {
    props: ['value', 'inputName', 'inputId', 'label', 'checkedValue', 'uncheckedValue', 'checkboxClass', 'labelClass', 'hasError'],
    data() {
      return {
        positiveValue: this.checkedValue !== undefined ? this.checkedValue : '1',
        negativeValue: this.uncheckedValue !== undefined ? this.uncheckedValue : '0',
        selectedValue: this.value,
        error: this.hasError
      }
    },

    computed: {
      checked() {
        return this.selectedValue == this.positiveValue
      },

      addInput() {
        return !!this.inputName || !!this.inputId
      }
    },

    methods: {
      onClick() {
        if(this.checked) {
          this.$emit('input', this.negativeValue)
          this.selectedValue = this.negativeValue
        } else {
          this.$emit('input', this.positiveValue)
          this.selectedValue = this.positiveValue
          this.error = false
        }
      }
    }
  }
</script>
