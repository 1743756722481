<template>
  <div class='stage-row user-row'>
    <div class='data small'>{{number}}</div>
    <div class='data'>{{fullName}}</div>
    <div class='data'>{{user.email}}</div>
    <div class='data'>{{user.department_name}}</div>
    <div class='data'>{{user.translated_locale}}</div>
    <div class='remove-row' @click='removeUser'>
      <i class='fa fa-trash fa-lg'></i>
    </div>
  </div>
</template>
<script>
  export default {
    props: ['user', 'index'],

    computed: {
      fullName() {
        return this.user.first_name + ' ' + this.user.last_name
      },

      number() {
        return this.index + 1
      }
    },

    methods: {
      removeUser() {
        this.$store.dispatch('deleteUser', this.user)
      }
    }
  }
</script>
