<script>
import store from './store'
import FilterResultsDropdown from '../FilterResultsDropdown.vue';
export default {
  extends: FilterResultsDropdown,
  store: store,

  created() {
    this.initSurveyQuestionDepartmentIds()
  },

  methods: {
    /*
      In case `selectedDepartmentIds` prop provided from session (remember filtering).
      Call store mutation, so SurveyQuestion or other components could filter data accordingly.
    */
    initSurveyQuestionDepartmentIds() {
      if (this.selectedDepartmentIds) {
        this.$store.commit('setSurveyQuestionDepartmentIds', this.selectedDepartmentIds)
      }
    }
  }
}
</script>
