<template>
  <div class='manager-row stage-row'>
    <div class='data'>{{fullName}}</div>
    <div class='data'>{{manager.email}}</div>
    <div v-if='notSelf' class='remove-row' @click='deleteManager'>
      <i class='fa fa-trash fa-lg'></i>
    </div>
  </div>
</template>

<script>
  import { currentManagerId } from '../helpers/globals'
  export default {
    props: ['manager'],

    computed: {
      fullName() {
        return this.manager.first_name + ' ' + this.manager.last_name
      },

      notSelf() {
        return currentManagerId != this.manager.id
      }
    },

    methods: {
      deleteManager() {
        this.$store.dispatch('deleteManager', this.manager)
      }
    }
  }
</script>
