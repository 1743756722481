<script>
import store from "./store";
import FilterResultsDropdown from "../analysis/FilterResultsDropdown";
export default {
  extends: FilterResultsDropdown,
  store: store,
  methods: {
    initDepartmentsAndAnalysisGroups() {
      this.departments = this.availableDepartments
      this.analysisGroups = this.availableAnalysisGroups
    }
  }
};
</script>
