<template>
  <div class='card card-dashboard margin-bottom'>
    <div class='card-body'>
      <div class='chart-header'>
        <div class='header'>
          {{title.toUpperCase()}}
        </div>
      </div>
      <div class='body'>

        <canvas v-if='enoughData' :id='canvasId'>
        </canvas>
        <div v-else class='no-data mt-3'>
          <div class="empty-chart-icon"></div>
          {{t('not_enough_data')}}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { uniqueId } from 'lodash'
  import BenchmarkLineChart from './prototypes/BenchmarkLineChart'

  export default {
    props: ['title', 'data' , 'benchmark'],
    mounted() {
      if(this.enoughData){
        new BenchmarkLineChart($(`#${this.canvasId}`), this.data, this.benchmarkValue)
      }
    },
    data() {
      return {
        canvasId: `line-chart-${uniqueId()}`
      }
    },
    computed: {
      benchmarkValue() {
        return this.benchmark || 0
      },
      enoughData() {
        return Object.keys(this.data).length > 1
      }
    }
  }
</script>

