<template>
  <div id='feedback-container'>
    <div v-if='feedbackCycles.length == 0' class='no-feedback-placeholder'>
      {{t('components.analysis.components.feedback_container.no_feedback_placeholder')}}
    </div>
    <feedback-cycle-row v-else v-for='cycle in sortedFeedbackCycles' :cycle='cycle' :key='cycle.cycle_date'/>
  </div>
</template>
<script>
  import FeedbackCycleRow from './FeedbackCycleRow'
  export default {
    created() {
      this.$store.dispatch('fetchFeedback')
    },

    computed: {
      feedbackCycles() {
        return this.$store.state.feedbackCycles
      },

      sortedFeedbackCycles() {
        return this.feedbackCycles.sort( (a, b) => {
          return new Date(b.cycle_date) - new Date(b.cycle_date)
        })
      }
    },

    components: {
      FeedbackCycleRow
    }
  }
</script>
