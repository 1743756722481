<template>
  <div class='row'>
    <div class='col-sm-6 col-md-4' v-for='suggestion in suggestions'>
      <improvement-suggestion
        :suggestion='suggestion'
        @onReadMore='openModal'
        @update='updateSuggestion'
        :key='suggestion.id'
      />
    </div>
    <improvement-suggestion-modal
      v-if='modalOpen'
      :show-star='true'
      :suggestion='modalSuggestion'
      @update='updateSuggestion'
      @close='closeModal'/>
  </div>
</template>
<script>
  import ImprovementSuggestion from './ImprovementSuggestion'
  import ImprovementSuggestionModal from './ImprovementSuggestionModal'

  export default {
    props: ['passedSuggestions'],

    data() {
      return {
        modalOpen: false,
        modalSuggestion: null,
        suggestions: this.passedSuggestions
      }
    },

    methods: {
      openModal(suggestion) {
        this.modalOpen = true
        this.modalSuggestion = suggestion
      },
      closeModal() {
        this.modalOpen = false
        this.modalSuggestion = null
      },

      updateSuggestion(suggestion) {
        let suggestionIndex = this.suggestions.findIndex((s) => s.id == suggestion.id)
        this.suggestions.splice(suggestionIndex, 1, suggestion)

        if(this.modalSuggestion && this.modalSuggestion.id == suggestion.id) {
          this.modalSuggestion = suggestion
        }
      }
    },

    components: {
      ImprovementSuggestion,
      ImprovementSuggestionModal
    }

  }
</script>
