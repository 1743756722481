import '../config/LabeledDoughnutChartConfig'
import Chart from 'chart.js'
import { map } from 'lodash'
import 'chartjs-plugin-deferred/src/plugin.js'

export default class LabeledDoughnut {
  constructor (domObject, objectSet = [], mainValue) {
    let values = map(objectSet, (object) => parseInt(object.value) )
    let colors = map(objectSet, (object) => object.color )
    let labels = map(objectSet, (object) => object.label )
    let legendContainer = domObject.parents('.body').find('.legend-container')

    this.chart = new Chart(domObject, {
      type: 'LabeledDoughnut',
      responsive: true,
      data: {
        mainValue: mainValue,
        labels: labels,
        datasets: [{
          borderWidth: 0,
          data: values,
          backgroundColor: colors
        }]
      },
      options: {
        aspectRatio: 1,
        plugins: {
          plugins: {
            deferred: true
          }
        }
      }
    })
    if(legendContainer.length > 0) {
      legendContainer.html(this.chart.generateLegend())
    }
  }
}
