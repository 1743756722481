import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
import IndexPage from './IndexPage'
import SurveyForm from './SurveyForm'
import ShowPage from './ShowPage'

const routes = [
  { path: '/',         name: 'surveys',       component: IndexPage },
  { path: '/new',      name: 'newSurvey',     component: SurveyForm },
  { path: '/:id/edit', name: 'editSurvey',    component: SurveyForm },
  { path: '/:id',      name: 'survey',        component: ShowPage },
]

export default new VueRouter({ routes })
