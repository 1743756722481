import Chart from 'chart.js'
import { clone } from 'lodash'

Chart.defaults.BenchmarkLineChart = clone(Chart.defaults.line)

class BenchmarkLineChart extends Chart.controllers.line{
  draw (ease) {
    let chart = this.chart.chart
    let ctx = chart.ctx
    Chart.controllers.line.prototype.draw.call(this, ease)
    // draw benchmark line
    for(const dataset of chart.config.data.datasets){
      const benchmark = dataset.benchmark
      if(dataset.showBenchmark && benchmark > 0) {
        let yAxis = this.chart.scales['y-axis-0']
        let xAxis = this.chart.scales['x-axis-0']
        // get 1% increase in pixels and multiply it to get reduction from bottom (bottom > top)
        let lineHeight = yAxis.bottom - yAxis.height / 100 * benchmark

        ctx.beginPath()
        ctx.setLineDash([1, 1])
        ctx.moveTo(yAxis.width, lineHeight)
        ctx.strokeStyle = dataset.benchmarkColor || dataset.color || '#000'

        ctx.lineTo(xAxis.right, lineHeight)
        ctx.stroke()
        ctx.closePath()
        ctx.save()
        ctx.setLineDash([]) // Reset dashed line so other lines are not dashed (did not know a better way to do this)
      }
    }
  }
}
Chart.controllers.BenchmarkLineChart = BenchmarkLineChart
