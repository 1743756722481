import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
import IndexPage from './IndexPage'
import ManagerForm from './ManagerForm'

const routes = [
  { path: '/',         name: 'managers',   component: IndexPage },
  { path: '/new',      name: 'newManager', component: ManagerForm },
  { path: '/:id/edit', name: 'manager',    component: ManagerForm },

]

export default new VueRouter({ routes })
