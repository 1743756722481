<template>
  <div class='answer-comment'>
    <div class='date'>
      {{l(comment.cycle_date)}}
    </div>
    <div class='comment'>
      <div v-if='notReadByManager' class='unread'>
        {{t('components.analysis.components.answer_comments_container.answer_comment.unread')}}
      </div>
      {{comment.comment}}
    </div>
    <div class='value'>
      {{parseInt(comment.value)}}
    </div>
    <div class='department'>
      {{comment.department_name}}
    </div>
  </div>
</template>
<script>
  export default {
    props: ['comment'],

    computed: {
      notReadByManager() {
        return !this.comment.is_read
      }
    }
  }
</script>
