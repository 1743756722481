<template>
  <div class="table-responsive">
    <div class="filters row">
      <div class="col-md-6">
        <span>{{t('activerecord.attributes.filter.by_department')}}</span>
        <v-select id="user_teams" multiple :options="teamOptions" @input="setTeamOptions"></v-select>
      </div>

      <div class="col-md-6">
        <span>{{t('activerecord.attributes.filter.by_group')}}</span>
        <v-select id="user_groups" multiple :options="groupOptions" @input="setGroupOptions"></v-select>
      </div>
    </div>
    <table class="table management-table">
      <thead>
        <th></th>
        <th>
          <span>{{t('activerecord.attributes.user.name')}}</span>
        </th>
        <th class="active">
          <span>{{t('activerecord.attributes.user.email')}}</span>
        </th>
        <th>
          <span>{{t('activerecord.attributes.user.department')}}</span>
        </th>
        <th>
          <span>{{t('activerecord.attributes.user.groups')}}</span>
        </th>
        <th>
          <span>{{t('activerecord.attributes.user.indicator')}}</span>
        </th>
        <th></th>
      </thead>
      <tbody>
        <user-row
          v-for="(user, index) in users"
          :user="user"
          :index="index"
          :key="user.updated_at"
        />
      </tbody>
    </table>
  </div>
</template> 
<script>
import vSelect from "vue-select";
import UserRow from "./UserRow";
import uniqBy from "lodash";
import { mapMutations } from 'vuex'
export default {
  data() {
    return { selectedTeams: [], selectedGroups: [] };
  },
  methods: {
    ...mapMutations([
      'setTeamOptions',
      'setGroupOptions'
    ]),
  },
  computed: {
    users() {
      return this.$store.getters.sortedUsers;
    },

    teamOptions() {
      let arr = [];
      this.$store.state.users.map(user => {
        arr.push({ label: user.department_name });
      });

      return _.uniqBy(arr, "label");
    },

    groupOptions() {
      let arr = [];
      this.$store.state.users.map(user => {
        user.groups.map(group => {
          arr.push({ label: group });
        });
      });

      return _.uniqBy(arr, "label");
    }
  },
  components: {
    vSelect,
    UserRow
  }
};
</script>
 <style scoped>
.filters {
  margin-top: 20px;
  margin-left: 0;
  margin-right: 0;
}
</style>