<template>
  <div class='suggestion-list-item col-md-6'>
    <a class='suggestion-list-item-link' href='#' @click.prevent='emitClick'>
      <i class='fa fa-star fa-2x mr-2' @click.prevent.stop='emitRemove'></i>
      <div class='suggestion-list-item-inner'>
        <img class='suggestion-list-item-image mr-2' :src='item.picture_url'/>
        <div>
          <div class='suggestion-list-item-component'>{{item.component_name}}</div>
          <div class='suggestion-list-item-text'>{{item.title}}</div>
        </div>
      </div>
      <i class='fa fa-chevron-right fa-lg'></i>
    </a>
  </div>
</template>
<script>
  export default {
    props: ['item'],
    methods: {
      emitRemove() {
        this.$emit('onRemove', this.item.id)
      },
      emitClick() {
        this.$emit('click', this.item)
      }
    }
  }
</script>
