<template>
  <div class='col-12'>
    <div class='card card-dashboard mb-4'>
      <div class='card-header'>
        <div class='row'>
          <div class='col-md-6'>
            <strong class='text-uppercase'>{{t('components.analysis.summary.feedback_questions_list.title')}}</strong>
          </div>
          <div class='col-md-6 text-md-right'>
            <a class='summary-overview-link' :href='componentsLink'>
              {{t('components.analysis.summary.feedback_questions_list.see_more')}}
            </a>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class='loading-indicator' v-if='isLoading'></div>
        <div v-if='!isLoading && questions.length > 0' class='feedback-questions-list'>
          <div class='feedback-questions-list-item' v-for='question in questions' :key='question.id'>
            <a class='feedback-questions-list-item-link d-block' :href='question.component_link'>
              <div class='feedback-questions-list-item-date'>{{l(question.date)}}</div>
              <div class='feedback-questions-list-item-component'>{{question.component_title}} <span class='grey'>({{question.departments}})</span></div>
              <p class='feedback-questions-list-item-text mt-1 mb-0'>{{question.title}}</p>
              <i class='fa fa-chevron-right fa-lg mb-1'></i>
            </a>
          </div>
        </div>

        <div v-if='!isLoading && questions.length == 0' class='summary-list-placeholder text-center'>
          <div class="fa-stack fa-3x mb-2">
            <i class="fa fa-comment-o fa-stack-2x"></i>
            <i class="fa fa-question fa-stack-1x"></i>
          </div>
          <strong class='summary-list-placeholder-heading mb-1'>
            {{t('components.analysis.summary.feedback_questions_list.placeholder_heading')}}
          </strong>
          <p class='summary-list-placeholder-text mb-0'>
            {{t('components.analysis.summary.feedback_questions_list.placeholder_text')}}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import axios from 'axios'

  export default {
    props: ['componentsLink'],

    mounted() {
      this.fetchQuestions()
    },

    data() {
      return {
        isLoading: true,
        questions: []
      }
    },

    methods: {
      fetchQuestions() {
        axios.get('/analysis/feedback_answers', {params: { latest_questions: true }}).
          then(({data}) => {
            this.questions = data
            this.isLoading = false
          })
      }
    }

  }
</script>
