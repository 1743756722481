<template>
  <div class='card card-dashboard improvement-suggestion-item'>
    <i class='fa fa-3x fa-star'></i>
    <div class='improvement-suggestion-image-holder'>
      <img :src='item.picture_url' />
    </div>
    <h5 class="improvement-suggestion-title">
      {{item.title}}
    </h5>

    <a href='#' class='improvement-suggestion-link' @click.prevent='emitClick'>
      {{this.t('components.analysis.components.improvement_suggestion.read_more')}}
    </a>
  </div>
</template>
<script>
  export default {
    props: ['item'],
    methods: {
      emitClick() {
        this.$emit('click', this.item)
      }
    }
  }
</script>
