<template>
  <div>
    <div class='card card-dashboard margin-bottom'>
      <div class='card-body'>
        <div class='row progress-chart'>
          <div class='col-12'>
            <div class='chart-header'>
              <div class='header'>
                {{title}}
              </div>
            </div>
          </div>
          <div class='col-3 col-xl-2 vertical-border-right f-center'>
            <div class='chart-value'>
              <span class="actual-value">{{chartValue}}</span>
              <span class="max-value">/ 100</span>
            </div>
          </div>
          <div class='col-9 col-xl-10'>
            <div class='progress progress-chart' :id='chartId'>
              <div class='progress-bar progress-bar-chart' :class='moodClass' role="progressbar">
              </div>
              <div class='progress-bar progress-bar-chart unfilled' role="progressbar">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <slot></slot>
      </div>
    </div>
  </div>
</template>
<script>
  import { uniqueId } from 'lodash'
  import { moodName } from '../helpers/chart_helpers'

  export default {
    props: ['title', 'value', 'includedMetrics', 'dataExists'],
    mounted() {
      let $chart = $(`#${this.chartId}`)
      $chart.find('.progress-bar-chart:not(.unfilled)').css('width',`${this.value}%`)
      $chart.find('.progress-bar-chart.unfilled').css('width', `${100 - this.value}%`)
    },

    computed: {
      chartValue() {
        if(this.dataExists) {
          return this.value
        } else {
          return '-'
        }
      },

      chartId() {
        return `progress-chart-${uniqueId()}`
      },
      moodClass() {
        return moodName(this.value)
      }
    }
  }
</script>
<style scoped>
  .filter-faded {
    display: none;
  }
</style>
