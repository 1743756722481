<template>
  <div class='managers-stage'>
    <div class="stage-description text-center">
      <img src="/managers_logo.svg" alt="">
      <h2 class="stage-description-title">{{t('components.onboarding.managers_stage.title')}}</h2>
      <h4 class="stage-description-subtitle">{{t('components.onboarding.managers_stage.subtitle')}}</h4>
    </div>

    <transition name="component-max-height" appear v-on:appear="matchHeight" v-on:after-appear="removeHelper">
      <div class='overflow-hidden' ref="infoBox" v-bind:style='{maxHeight: maxHeightHelper}'>
        <div class="stage-content">
          <manager-form />
          <div class='stage-list'>
            <manager-row v-for='manager in managers' :manager='manager' :key='manager.id' />
          </div>
          <wizard-buttons
            :allow-back='true'
            :allow-next='true'/>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
  import ManagerRow from './ManagerRow'
  import ManagerForm from './ManagerForm'
  import WizardButtons from './WizardButtons'

  export default {
    data() {
      return {
        maxHeightHelper: '1000px'
      }
    },

    computed: {
      managers() {
        return this.$store.state.managers
      }
    },

    methods: {
      matchHeight() {
        this.maxHeightHelper = `${this.$refs.infoBox.scrollHeight}px`
      },

      removeHelper() {
        this.maxHeightHelper = 'none'
      }
    },

    components: {
      ManagerRow,
      ManagerForm,
      WizardButtons
    }
  }
</script>
