<script>
import store from './store'
import FilterResultsDropdown from '../FilterResultsDropdown.vue';
import { includes } from 'lodash'
export default {
  extends: FilterResultsDropdown,
  store: store,
  methods: {
    initDepartmentsAndAnalysisGroups() {
      this.departments = Object.values(JSON.parse(this.$store.state.availableDepartments))
      this.analysisGroups = Object.values(JSON.parse(this.$store.state.availableAnalysisGroups))
    },
    initSelectedAndFilteredDepartments() {
      if(this.$store.state.selectedDepartmentIds) {
        this.selectedDepartments = this.departments.filter(d => includes(this.$store.state.selectedDepartmentIds, d.id))
        this.filteredDepartments = Object.assign([], this.selectedDepartments)
        this.analysisGroups = this.analysisGroups.filter((a) => a.departments.map(d => d.id), this.selectedDepartments.map(d => d.id))
      }
    }
  }
}
</script>