<template>
  <div class='filter-results-dropdown col-lg-2 col-md-3 col-sm-4 mb-3' v-if='hasFilterables' v-click-outside='cancelFilter'>
    <div class="dropdown-header" @click='toggleDropdown()'>
      <div class='dropdown-header-icon'>
        <img :src='filterIcon' />
      </div>
      <span>{{t('analysis.summary.index.select_teams_and_groups')}}</span>
      <div v-if='isAnyDepartmentFiltered()' class='dropdown-header-selected-department-block'>
        <span>{{filteredDepartmentCount()}}</span>
        <span>selected</span>
        <div class='dropdown-header-reset-icon' @click='resetFilter()'>
          <img :src='filterResetIcon' />
        </div>
      </div>
    </div>
    <div v-if='dropdownVisible' class='dropdown-body'>
      <div class='dropdown-whole-company custom-checkbox' @click='toggleWholeCompany()'>
        <span class='custom-checkbox-label' :class='{checked: wholeCompany}'>{{t('analysis.summary.index.whole_company')}}</span>
        <span class='custom-checkbox-box' :class='{checked: wholeCompany}'>
          <i v-if='wholeCompany' class='fa fa-check' data-action='filter-results-dropdown-checkbox-click' />
        </span>
      </div>
      <div class='dropdown-departments-header'>{{t('analysis.summary.index.departments')}}</div>
      <div class='dropdown-departments custom-checkbox' v-for='(department,i) in this.departments' @click='toggleDepartment(department)' v-bind:key='"dep_"+i'>
        <span class='custom-checkbox-label' :class='{checked: isDepartmentSelected(department)}'>{{department.name}}</span>
        <span class='custom-checkbox-box' :class='{checked: isDepartmentSelected(department)}'>
          <i v-if='isDepartmentSelected(department)' class='fa fa-check' data-action='filter-results-dropdown-checkbox-click' />
        </span>
      </div>
      <div class='dropdown-analysis-groups-header'>{{t('analysis.summary.index.analysis_groups')}}</div>
      <div class='dropdown-analysis-groups custom-checkbox' v-for='(analysisGroup,i) in this.analysisGroups' @click='toggleAnalysisGroup(analysisGroup)' v-bind:key='"ag_"+i'>
        <span class='custom-checkbox-label' :class='{checked: isAnalysisGroupSelected(analysisGroup)}'>{{analysisGroup.name}}</span>
        <span class='custom-checkbox-box' :class='{checked: isAnalysisGroupSelected(analysisGroup)}'>
          <i v-if='isAnalysisGroupSelected(analysisGroup)' class='fa fa-check' data-action='filter-results-dropdown-checkbox-click' />
        </span>
      </div>
    </div>
    <div v-if='dropdownVisible' class='action-buttons'>
      <span class='btn btn-primary btn-sm-text btn-bordered pointer cancel-button' @click='cancelFilter()'>{{t('analysis.summary.index.cancel')}}</span>
      <span class='btn btn-primary btn-sm-text pointer apply-button' @click='postFilterData'>{{t('analysis.summary.index.apply')}}</span>
    </div>
  </div>
</template>

<script>
  import { includes, difference } from 'lodash'
  import filterIcon from '../../../assets/images/filter_icon.svg'
  import filterResetIcon from '../../../assets/images/filter_reset_icon.svg'
  
  // Register directive "v-click-outside" for out of dropdown click
  import Vue from 'vue'
  // This variable will hold the reference to
  // document's click handler
  let handleOutsideClick
  Vue.directive('clickOutside', {
    bind (el, binding, vnode) {
      handleOutsideClick = (e) => {
        e.stopPropagation()

        // We check to see if the clicked element is not in dropdown
        if (!el.contains(e.target) && e.target.getAttribute('data-action') != 'filter-results-dropdown-checkbox-click') {
          // If the clicked element is outside the dialog
          // then call the outside-click handler
          vnode.context[binding.expression](e);
        }
      }
      // Register click/touchstart event listeners on the whole page
      document.addEventListener('click', handleOutsideClick)
      document.addEventListener('touchstart', handleOutsideClick)
    },
    unbind () {
      // If the element that has v-click-outside is removed, then
      // unbind click/touchstart listeners from the whole page
      document.removeEventListener('click', handleOutsideClick)
      document.removeEventListener('touchstart', handleOutsideClick)
    }
  })  

  export default {
    store: null, // Should be added in extended classes
    props: ['availableDepartments', 'availableAnalysisGroups', 'selectedDepartmentIds', 'section'],

    data() {
      return {
        departments: [],
        analysisGroups: [],
        selectedDepartments: [],
        filteredDepartments: [],
        wholeCompany: true,
        dropdownVisible: false,
        resetLinkClicked: false,
        filterIcon: filterIcon,
        filterResetIcon: filterResetIcon
      }
    },

    created() {
      this.initDepartmentsAndAnalysisGroups()
      this.initSelectedAndFilteredDepartments()
      this.wholeCompany = this.selectedDepartments.length < 1
    },

    computed: {
      hasFilterables() {
        return this.departments.length > 0
      },

      errors() {
        return this.$store.state.formErrors
      }
    },

    methods: {
      initDepartmentsAndAnalysisGroups() {
        this.departments = Object.values(JSON.parse(this.availableDepartments))
        this.analysisGroups = Object.values(JSON.parse(this.availableAnalysisGroups))
      },

      initSelectedAndFilteredDepartments() {
        if(this.selectedDepartmentIds) {
          this.selectedDepartments = this.departments.filter(d => includes(this.selectedDepartmentIds, d.id))
          this.filteredDepartments = Object.assign([], this.selectedDepartments)
        }
      },

      filteredDepartmentCount() {
        return this.filteredDepartments.length;
      },

      isAnyDepartmentFiltered() {
        return this.filteredDepartmentCount() > 0;
      },

      toggleDropdown() {
        // As this is toggled on header click - it will also be called when we click reset button which is in header.
        // Small hack to prevent dropdown opening when we click reset X in header
        if (this.resetLinkClicked) {
          this.dropdownVisible = false
          this.resetLinkClicked = false
          return
        }

        if (this.dropdownVisible) {
          this.cancelFilter()
        } else {
          this.dropdownVisible = true
        }
      },

      cancelFilter() {
        this.selectedDepartments = Object.assign([], this.filteredDepartments)
        this.wholeCompany = this.selectedDepartments.length < 1
        this.dropdownVisible = false
      },

      resetFilter() {
        this.resetLinkClicked = true
        this.wholeCompany = true
        this.selectedDepartments = []
        this.filteredDepartments = []

        this.postFilterData()
      },

      addSelectedDepartment(department) {
        if (!this.isDepartmentSelected(department)) {
          this.selectedDepartments.push(department)
        }
      },

      removeSelectedDepartment(department) {
        this.selectedDepartments = this.selectedDepartments.filter(d => d.id !== department.id)
      },

      toggleDepartment(department) {
        if (this.isDepartmentSelected(department)) {
          this.removeSelectedDepartment(department)
        } else {
          this.addSelectedDepartment(department)
        }
        this.wholeCompany = this.selectedDepartments.length < 1
      },

      toggleAnalysisGroup(analysisGroup) {
        if (this.isAnalysisGroupSelected(analysisGroup)) {
          analysisGroup.departments.forEach(d => this.removeSelectedDepartment(d))
        } else {
          analysisGroup.departments.forEach(d => this.addSelectedDepartment(d))
        }
        this.wholeCompany = this.selectedDepartments.length < 1
      },

      isDepartmentSelected(department) {
        return includes(this.selectedDepartments.map(d => d.id), department.id)
      },

      isAnalysisGroupSelected(analysisGroup) {
        const diff = difference(analysisGroup.departments.map(d => d.id), this.selectedDepartments.map(d => d.id))
        return diff.length === 0
      },

      toggleWholeCompany() {
        this.wholeCompany = !this.wholeCompany
        this.selectedDepartments = []
      },

      postFilterData() {
        const department_ids = this.selectedDepartments.length > 0 ? this.selectedDepartments.map(d => d.id).sort() : []
        this.$store.dispatch('postFilterData', {
          department_ids: department_ids,
          section: this.section,
        })
        this.filteredDepartments = Object.assign([], this.selectedDepartments)
        this.dropdownVisible = false
      }
    }
  }
</script>
<style scoped>
  .filter-results-dropdown {
    width: 100%;
    height: 40px;
    display: flex;
    z-index: 100;
    justify-content: right;
    position: relative;
  }

  .dropdown-header {
    background-color: #fff;
    border: 0.75px solid #D4D4D4;
    height: 40px;
    text-transform: uppercase;
    color: black;
    display: flex;
    justify-content: left;
    align-items: left;
    padding-left: 20px;
  }
  
  .dropdown-header-icon {
    padding-right: 15px;
  }

  .dropdown-header-icon img {
    padding-bottom: 2px;
  }

  .dropdown-header-reset-icon {
    padding-left: 5px;
    cursor: pointer;
    display: inline;
  }

  .dropdown-header-reset-icon img {
    padding-bottom: 2px;
  }

  .dropdown-header-selected-department-block {
    padding-left: 15px;
    text-transform: lowercase;
    color: #73BBC4;
    font-weight: bold;
  }

  .dropdown-body {
    background-color: #F5F5F5;
    padding: 7px;
    position: absolute;
    top: 40px;
    overflow: scroll;
    min-height: 400px;
    max-height: 400px;
    min-width: 534px;
    right: 15px;
  }
  
  .action-buttons {
    border-top: 0px;
    background-color: #F5F5F5;
    box-shadow: 0px -2px 10px 0px #1C324B0D;
    padding: 7px 14px 7px 7px;
    position: absolute;
    top: 410px;
    min-width: 534px;
    right: 15px;
    text-align: right;
  }

  .action-buttons .apply-button {
    width: 128px;
    margin-left: 10px;
  }

  .action-buttons .cancel-button {
    width: 125px;
  }

  .dropdown-departments-header,
  .dropdown-analysis-groups-header {
    font-weight: bold;
    padding: inherit;
    color: #73BBC4;
  }

  .dropdown-departments,
  .dropdown-analysis-groups,
  .dropdown-whole-company {
    padding: inherit;
    display: inherit !important;
  }

  .custom-checkbox-box {
    float: right;
  }

  .custom-checkbox-label {
    float: left;
    margin-left:7px;
  }

  .dropdown-whole-company .custom-checkbox-label,
  .dropdown-departments .custom-checkbox-label,
  .dropdown-analysis-groups .custom-checkbox-label {
    font-weight: 600;
  }

  .custom-checkbox-label::v-deep.checked {
    color: #0B1D3D;
  }

  .custom-checkbox .custom-checkbox-label.group-department-label {
    font-weight: normal;
    float: none;
  }

  .group-departments .custom-checkbox {
    margin: 5px 0 5px 0;
  }
  .group-departments .dropdown-analysis-groups {
    margin-bottom:0;
  }
</style>
