<template>
  <div class='row'>
    <div class='analysis-group-select col-md-12' :class="{ 'has-errors': hasErrors }">
      <label class='control-label'>
        {{label}}
      </label>

      <v-select multiple
        :value='value'
        @input='propogateInput'
        :options='filteredAnalysisGroupOptions'
        placeholder='Enter analysis group...'
        id='analysis_groups_select'
      />
      <span v-if='hasErrors' class='help-text'>{{errors[0]}}</span>
      <div class='analysis-group-tags-holder'>
        <div class='analysis-group-tag' v-for='analysisGroup in value'>
          {{analysisGroup.label}}
          <i v-if='canRemoveAnalysisGroup(analysisGroup)' class='fa fa-times pointer' @click='removeAnalysisGroup(analysisGroup)'></i>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import vSelect from 'vue-select'
  import { difference } from 'lodash'

  export default {
    props: ['value', 'errors', 'analysisGroupOptions', 'selectableAnalysisGroupOptions', 'label'],

    computed: {
      selectableAnalysisGroupValues() {
        // In case specific selectable analysis groups options are not provided
        // we allow to select all passed analysis group options
        if (this.selectableAnalysisGroupOptions) {
          return this.selectableAnalysisGroupOptions.map(group => group.value)
        } else {
          return this.analysisGroupOptions.map(group => group.value)
        }        
      },
      filteredAnalysisGroupOptions() {
        return this.analysisGroupOptions.filter(group => (
          this.selectableAnalysisGroupValues.includes(group.value)
        ))
      },
      hasErrors() {
        return this.errors && this.errors.length > 0
      }
    },

    methods: {
      canRemoveAnalysisGroup(group) {
        return this.selectableAnalysisGroupValues.includes(group.value)
      },
      removeAnalysisGroup(analysisGroup) {
        const tempValue = this.value
        const analysisGroupIndex = this.value.findIndex(group => group.value == analysisGroup.value)
        tempValue.splice(analysisGroupIndex, 1)
        this.$emit('input', tempValue)
      },

      propogateInput(value) {
        this.$emit('input', value)
      },
    },

    components: { vSelect }
  }
</script>
