<template>
  <div>
    <div class="row progress-chart">
      <div class="col-9 col-xl-10">
        <div class="progress progress-chart" :id="chartId">
          <div class="progress-bar progress-bar-chart" :class="moodClass" role="progressbar"></div>
          <div class="progress-bar progress-bar-chart unfilled" role="progressbar"></div>
        </div>
      </div>
      <div class="col-3 col-xl-2 f-center">
        <div class="chart-value">
          <span class="actual-value">{{value}}</span>
          <span class="max-value">/ 100</span>
          <div v-if="withAverage" class="sub-text">{{t('analysis.components.show.average')}}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { uniqueId } from "lodash";
import { moodName } from "../helpers/chart_helpers";

export default {
  props: {
    value: { default: null, type: [String, Number] },
    withAverage: { default: false, type: Boolean }
  },
  mounted() {
    let $chart = $(`#${this.chartId}`);
    $chart
      .find(".progress-bar-chart:not(.unfilled)")
      .css("width", `${this.value}%`);
    $chart
      .find(".progress-bar-chart.unfilled")
      .css("width", `${100 - this.value}%`);
  },

  computed: {
    chartId() {
      return `progress-chart-${uniqueId()}`;
    },
    moodClass() {
      return moodName(this.value);
    }
  }
};
</script>
<style scoped>
.filter-faded {
  display: none;
}
.sub-text {
  text-transform: uppercase;
  color: #6A6F77;
  opacity: 0.8;
  font-size: 8px;
}
</style>
