<template>
  <div class='really-skip-screen'>
    <div class="stage-description text-center">
      <h2 class="stage-description-title">{{t('components.onboarding.really_skip_screen.title')}}</h2>
      <h4 class="stage-description-subtitle">{{t('components.onboarding.really_skip_screen.subtitle')}}</h4>
    </div>
    <div class='button-container final-button-container'>
      <a href='#' @click.prevent='hide' class='btn btn-warning btn-small-text btn-lg mr-2'>
        {{t('components.onboarding.really_skip_screen.no_button')}}
      </a>
      <a href='#' @click.prevent='finishOnboarding' class='btn btn-warning btn-bordered btn-small-text btn-lg'>
        {{t('components.onboarding.really_skip_screen.yes_button')}}
      </a>
    </div>
  </div>
</template>
<script>
  export default {
    methods: {
      hide() {
        this.$store.commit('hideReallySkipScreen')
      },

      finishOnboarding() {
        this.$store.dispatch('finishOnboarding', false)
      }
    }
  }
</script>
