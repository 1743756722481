<template>
  <div class='row card-body'>
    <div class='col-6 offset-3'>
      <div class='demo-data'>
        <p>{{ t('funnel.demo_data.demo_data') }}</p>
      </div>
    </div>
    <div class='col-3'>
      <div class='f-right tooltip-icon'>
        <i v-tooltip='t("funnel.demo_data.tooltip")' class='fa fa-lg fa-question-circle'></i>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
  }
</script>

<style>
  .filter-faded {
    -moz-filter: grayscale(100%);
    -webkit-filter: grayscale(100%);
    filter: gray; /* IE6-9 */
    filter: grayscale(100%);
  }

  .demo-data {
    font-size: 14px;
    text-transform: uppercase;
    color: #dedede;
    text-align: center;
  }

</style>
