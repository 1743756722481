<template>
  <div class="response-rate-diagram">
      <i class="response-rate-icon">
        <div class="response-rate-progress" :id='progressId'>
          <svg width="59px" height="124px" viewBox="0 0 59 124" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <g id="search_results_3" transform="translate(-22.000000, -903.000000)" :fill="color">
                <path d="M52.3530996,947 L48.8215501,947 L48.2091671,944.550468 C47.9342197,943.450678 48.6028864,942.336234 49.7026759,942.061286 C49.8654919,942.020582 50.0326853,942 50.2005122,942 L50.9741375,942 C52.1077746,942 53.0267691,942.918994 53.0267691,944.052632 C53.0267691,944.220458 53.0061866,944.387652 52.9654826,944.550468 L52.3530996,947 Z M52.1727859,949 L53.9912222,979.913417 C54.0245069,980.479257 53.5927852,980.964944 53.0269448,980.998229 C53.0068774,980.999409 52.9867796,981 52.9666775,981 L48.2079722,981 C47.6411536,981 47.1816564,980.540503 47.1834275,979.913417 L49.0018637,949 L52.1727859,949 Z M64.9923018,932 L66,932 C74.2842712,932 81,938.715729 81,947 L81,973 C81,981.284271 74.2842712,988 66,988 L37,988 L37,988 C28.7157288,988 22,981.284271 22,973 L22,947 C22,939.040861 28.1989296,932.52956 36.0321626,932.030722 C39.421921,936.278322 44.6431195,939 50.5,939 C56.3709917,939 61.6032346,936.265191 64.9923018,932 Z M50.2407592,940 C49.9059251,940 49.5723548,940.041064 49.247518,940.122274 C47.0533103,940.670826 45.7192427,942.894272 46.2677947,945.088479 L47.0432939,948.190476 L45.1869566,979.748211 C45.1834239,979.808266 45.1816564,979.868412 45.1816564,979.928571 C45.1816564,981.624875 46.5567818,983 48.253085,983 L53.0001695,983 C53.0603286,983 53.1204747,982.998233 53.18053,982.9947 C54.8739059,982.89509 56.1659083,981.441587 56.0662979,979.748211 L54.2099606,948.190476 L54.9854598,945.088479 C55.066669,944.763642 55.1077334,944.430072 55.1077334,944.095238 C55.1077334,941.833501 53.2742328,940 51.0124953,940 L50.2407592,940 Z M50.5,936 C41.3873016,936 34,928.612698 34,919.5 C34,910.387302 41.3873016,903 50.5,903 C59.6126984,903 67,910.387302 67,919.5 C67,928.612698 59.6126984,936 50.5,936 Z M31,991 L72,991 L72,1019 C72,1023.41828 68.418278,1027 64,1027 L39,1027 L39,1027 C34.581722,1027 31,1023.41828 31,1019 L31,991 Z" id="Combined-Shape"></path>
              </g>
            </g>
          </svg>
        </div>
      </i>
    <div class="response-rate-count">{{value}}<sup class="response-rate-percent">{{displayUnit}}</sup></div>
  </div>
</template>
<script>
  import { uniqueId } from 'lodash'
  export default {
    props: ['value', 'color', 'percentagePoint'],
    mounted() {
      this.updateProgress()
    },
    watch: {
      color(newColor) {
        this.updateProgress()
      }
    },
    computed: {
      progressId() {
        return `progress-${uniqueId()}`
      },
      displayUnit() {
        return this.percentagePoint ? 'pp' : '%'
      }
    },
    methods: {
      updateProgress() {
        const $progress = $(`#${this.progressId}`)
        const stringValue = `${this.value}%`
        if (this.percentagePoint) {
          setTimeout(function() {
            $progress.css('height', '100%')
          }, 400)
        } else {
          setTimeout(function() {
            $progress.css('height', stringValue)
          }, 400)
        }
      }
    }
  }
</script>
