import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
import {
  sortBy,
  findIndex,
  uniqBy,
  intersection
} from 'lodash'
import axios from 'axios'
const AUTH_TOKEN = $('[name="csrf-token"]')[0].content

const filteredUsersByTeam = (users, filters) => {
  if (filters.length == 0) return users
  let teamUsers = [];
  users.forEach(user => {
    filters.forEach(team => {
      if (user.department_name == team.label) {
        teamUsers.push(user);
      }
    });
  });
  return teamUsers
}

const filteredUsersByGroup = (users, filters) => {
  if (filters.length == 0) return users
  let groupUsers = [];
  users.forEach(user => {
    user.groups.forEach(group => {
      filters.forEach(filter => {
        if (group == filter.label) {
          groupUsers.push(user);
        }
      })
    })
  });
  return groupUsers
}

export default new Vuex.Store({
  state: {
    departmentOptions: [],
    localeOptions: [],
    users: [],
    sortedBy: 'email',
    teamOptions: [],
    groupOptions: [],
    manager: null
  },
  mutations: {
    setDepartmentOptions(state, departmentOptions) {
      state.departmentOptions = departmentOptions
      if (!state.departmentOptions.find(d => d.value == '')) {}
    },

    setLocaleOptions(state, localeOptions) {
      state.localeOptions = localeOptions
    },

    setTeamOptions(state, teamOptions) {
      state.teamOptions = teamOptions
    },

    setGroupOptions(state, groupOptions) {
      state.groupOptions = groupOptions
    },

    setManager(state, manager) {
      state.manager = manager
    },

    addOrReplaceUser(state, user) {
      const userIndex = findIndex(state.users, (u) => u.id == user.id)
      if (userIndex > -1) {
        state.users.splice(userIndex, 1, user)
      } else {
        state.users.push(user)
      }
    },
    removeUser(state, userId) {
      const userIndex = findIndex(state.users, (u) => u.id == userId)
      state.users.splice(userIndex, 1)
    }
  },

  getters: {
    sortedUsers(state) {
      let userArr = intersection(filteredUsersByTeam(state.users, state.teamOptions), filteredUsersByGroup(state.users, state.groupOptions))
      userArr = uniqBy(userArr, "id")

      if (state.sortedBy) {
        return sortBy(userArr, state.sortedBy)
      } else {
        return userArr
      }
    }
  },

  actions: {
    fetchUsers({
      state
    }) {
      axios.get('/admin/users.json').then(({
        data
      }) => state.users = data)
    },

    persistUser({
      state,
      commit
    }, user) {
      return new Promise((resolve, reject) => {
        axios({
            method: user.id ? 'PUT' : 'POST',
            url: `/admin/users/${user.id || ''}`,
            data: {
              authenticity_token: AUTH_TOKEN,
              user: user
            }
          })
          .then((response) => {
            commit('addOrReplaceUser', response.data)
            resolve(user)
          })
          .catch(reject)
      })
    },

    destroyUser({
      state,
      commit
    }, user) {
      return new Promise((resolve, reject) => {
        axios({
            method: 'DELETE',
            url: `/admin/users/${user.id}`,
            data: {
              authenticity_token: AUTH_TOKEN
            }
          })
          .then((response) => {
            commit('removeUser', user.id)
            resolve(user)
          })
          .catch(reject)
      })
    }
  }
})