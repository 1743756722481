<template>
  <div class="table-cell mood-cell">
    <div v-if="dataExists" class="visualized-value" :style="{'background-color': color}">
      {{ value }}
    </div>
    <div v-else class="visualized-value no-data">
      {{ t('no_data') }}
    </div>
  </div>
</template>

<script>
export default {
  props: ['dataExists', 'value', 'color']
}
</script>
