const moodName = function(value) {
  if(value < 31) {
    return 'bad'
  } else if (value < 41) {
    return 'below-average'
  } else if (value < 61) {
    return 'normal'
  } else if(value < 71) {
    return 'above-average'
  } else {
    return 'good'
  }
}

const npsMoodName = function(value) {
  if(value < -40) {
    return 'bad'
  } else if (value < -10) {
    return 'below-average'
  } else if (value < 11) {
    return 'normal'
  } else if(value < 41) {
    return 'above-average'
  } else {
    return 'good'
  }
}

const metricIcons = {
  engagement: 'fa-trophy',
  satisfaction: 'fa-thumbs-up',
  culture: 'fa-bank',
  loyalty: 'fa-handshake-o'
}

const getRandomColor = function() {
  var letters = '0123456789ABCDEF';
  var color = '#';
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

export { moodName, npsMoodName, metricIcons, getRandomColor }
