<template>
  <div class='buttons-row'>
    <div v-if='!hideBack' class='button-wrapper back-button'>
      <button type='button' :disabled='!allowBack' @click='onBack' data-action='back'>
        <i class="fa fa-arrow-left"></i>
      </button>
    </div>
    <div v-if='!hideNext' class='button-wrapper next-button'>
      <button type='button' :disabled='!allowNext && !allowSkip' @click='onNext' data-action='next'>
        <i class="fa fa-arrow-right"></i>
      </button>
    </div>
  </div>
</template>
<script>
  export default {
    props: ['allowNext', 'allowSkip', 'allowBack', 'hideNext', 'hideBack', 'manuallySetNextStage'],
    methods: {
      onNext() {
        if(this.allowNext || this.allowSkip){
          this.$emit('next')
          if(!this.manuallySetNextStage) {
            this.$store.dispatch('moveToNextStage')
          }
        }
      },

      onBack() {
        if(this.allowBack){
          this.$emit('back')
          this.$store.commit('moveToPreviousStage')
        }
      }
    }
  }
</script>
