import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
import IndexPage from './IndexPage'
import AnalysisGroupForm from './AnalysisGroupForm'

const routes = [
  { path: '/',         name: 'analysisGroups', component: IndexPage },
  { path: '/new',      name: 'newAnalysisGroup', component: AnalysisGroupForm },
  { path: '/:id/edit', name: 'editAnalysisGroup', component: AnalysisGroupForm },

]

export default new VueRouter({ routes })
