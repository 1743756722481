<template>
  <tr :data-object-id="user.id">
    <td>{{number}}</td>
    <td>{{user.full_name}}</td>
    <td>{{user.email}}</td>
    <td>{{user.department_name}}</td>
    <td class="group-overflow">
      <div v-tooltip="groups">{{(groups)}}</div>
    </td>
    <td>
      <div v-tooltip="user.indicator_message" class="indicator" :class="user.indicator_class"></div>
    </td>
    <td>
      <a href="#" @click.prevent="openModal" data-action="edit">
        <i class="edit-icon"></i>
      </a>
      <a href="#" @click.prevent="deleteUser" data-action="delete">
        <i class="close-icon grey"></i>
      </a>
    </td>
    <user-modal v-if="modalOpen" :passed-user="user" @close="closeModal"/>
  </tr>
</template>
<script>
import UserModal from "./UserModal";
export default {
  props: ["user", "index"],

  data() {
    return { modalOpen: false };
  },

  computed: {
    number() {
      return this.index + 1;
    },

    groups() {
      return this.user.groups.join(", ")
    }
  },

  methods: {
    flashObject(action, type = "success") {
      let flashType = "success";
      let messagePrefix = "successful";
      if (type == "fail") {
        flashType = "error";
        messagePrefix = "failed";
      }
      return {
        notice: this.t(
          `components.management.user_row.${messagePrefix}_${action}_message`,
          { user_name: this.user.full_name }
        ),
        type: flashType
      };
    },

    openModal() {
      this.modalOpen = true;
    },
    closeModal() {
      this.modalOpen = false;
    },
    deleteUser() {
      if (
        confirm(
          this.t("components.management.user_row.delete_confirmation", {
            user_email: this.user.email
          })
        )
      ) {
        this.$store
          .dispatch("destroyUser", this.user)
          .then(() => this.$root.$emit("flash", this.flashObject("destroy")))
          .catch(() =>
            this.$root.$emit("flash", this.flashObject("destroy", "fail"))
          );
      }
    }
  },
  components: {
    UserModal
  }
};
</script>
<style scoped>
.indicator {
  margin-left: 20px;
  margin-top: 4px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #8adb0d;
}

.indicator.grey {
  background-color: #aaaaaa;
}

.close-icon {
  margin-right: 10px;
}

.group-overflow div{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 400px;
} 
</style>

