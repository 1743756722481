<template>
  <div id='managers-container'>
    <div class='manager-container-buttons'>
      <router-link v-if='canCreate' class='link-with-icon' :to="{name: 'newManager'}">
        <i class='fa fa-plus-circle fa-3x' />
        {{t('admin.managers.index.new_manager')}}
      </router-link>
      <import-button v-if='canImport' />
      <a href="/people.xlsx" v-if='canImport' class="link-with-icon">
        <i class='fa fa-file-excel-o fa-3x' />
        {{t('admin.managers.index.download_xlsx_sample')}}
      </a>
    </div>
    <managers-table />
  </div>
</template>
<script>
  import ManagersTable from './ManagersTable'
  import ImportButton from './ImportButton'
  import store from './store.js'

  export default {
    store: store,

    computed: {
      canCreate() {
        return this.$store.state.canCreate
      },
      canImport() {
        return this.$store.state.canImport
      },
    },

    components: {
      ManagersTable,
      ImportButton
    }
  }
</script>
<style scoped>
.manager-container-buttons {
  display: flex;
  flex-direction: row;
  gap: 16px;
}
</style>
