import Vue from 'vue'
import Vuex from 'vuex'
import { AUTH_TOKEN } from '../../helpers/globals'
import axios from 'axios'
Vue.use(Vuex)

export default new Vuex.Store({

  state: {
    analysisGroups: [],
    departments: [],
    metrics: [],
    components: [],
    npsComponents: [],
    rollingResponseRates: [],
    selectedDepartments: [],
    filteredDepartments: [],
    selectedDepartmentIds: [],
    availableDepartments: [],
    availableAnalysisGroups: [],
    totalValues: []
  },
  actions: {
    postFilterData({ commit, state }, data) {
      const departmentIds = data.department_ids
      state.selectedDepartmentIds = departmentIds
      axios.post(
        '/sessions/filtered_department_ids',
        {
          authenticity_token: AUTH_TOKEN,
          department_ids: departmentIds
        }
      )
    }
  }
})