<template>
  <div class='analysis-form'>
    <h2 class='sub-title mb-4'>
      {{t('components.management.analysis_group_form.title')}}
    </h2>
    <div class='row'>
      <bootstrap-input-field
        label='Name'
        v-model='name'
        :errors='errors.name'
        input-id='analysis_group_name'
        wrapper-class='col-md-7 col-12'/>
    </div>
    <div class='row'>
      <custom-checkbox
        class='ml-3'
        value='0'
        v-model='useGroupNameInSurvey'
        input-name='analysis-group[use_group_name_in_survey]'
        input-id='use_group_name_in_survey'
        :label="t('components.management.analysis_group_form.use_group_name_in_survey')" />
    </div>
    <div class='form-separator'></div>
    <multiple-departments-select
      class='departments-select-holder'
      v-model='selectedDepartments'
      :analysis-groups='persistedAnalysisGroups'
      :department-options='departmentOptions'
      :errors='errors.department_ids'
      :analysis-groups-title="t('components.management.analysis_group_form.add_from_groups')"
      :label="t('activerecord.attributes.analysis_group.departments')" />
    <div class='action-buttons pt-3'>
      <router-link class='btn btn-primary btn-bordered btn-lg pointer mr-3' :to='{name: "analysisGroups"}'>{{t('cancel')}}</router-link>
      <span class='btn btn-primary btn-lg pointer' @click='saveAnalysisGroup'>{{t('save')}}</span>
    </div>
  </div>
</template>
<script>
  import BootstrapInputField from '../../utilityComponents/BootstrapInputField'
  import CustomCheckbox from '../../utilityComponents/CustomCheckbox'
  import MultipleDepartmentsSelect from '../../utilityComponents/MultipleDepartmentsSelect'
  export default {
    created() {
      this.updateState()
    },

    beforeRouteUpdate(to, from, next) {
      this.updateState(to.params.id)
      next()
    },

    data() {
      return {
        selectedDepartments: [],
        name: '',
        id: null,
        useGroupNameInSurvey: undefined
      }
    },

    computed: {
      departmentOptions() {
        return this.$store.state.departments.map(d => { return { label: d.name, value: d.id } })
      },

      persistedAnalysisGroups() {
        // Don't include editable group (self)
        return this.$store.state.analysisGroups.filter(ag => ag.id != this.id)
      },

      errors() {
        return this.$store.state.formErrors
      }
    },

    methods: {
      saveAnalysisGroup() {
        this.$store.dispatch('saveAnalysisGroup', {
          name: this.name,
          department_ids: this.selectedDepartments.map(d => d.value),
          id: this.id,
          use_group_name_in_survey: this.useGroupNameInSurvey == 1 ? true : false
        }).
        then(() => {
          this.$root.$emit('flash', { notice: this.t('components.management.analysis_group_form.successfully_saved') })
          this.$router.push('/')
        }).catch(() => {})
      },

      updateState(nextId) {
        const id = nextId || this.$route.params.id
        if(id) {
          const group = this.persistedAnalysisGroups.find(ag => ag.id == parseInt(id))
          if(group) {
            this.selectedDepartments = group.departments.map(d => { return {label: d.name, value: d.id} })
            this.name = group.name
            this.id = group.id
            this.useGroupNameInSurvey = group.use_group_name_in_survey
          } else {
            // If can't find analysisGroup, go back to index
            this.$router.replace('/')
          }
        }
      },

      toggleGroupDepartments(departmentIds, checked) {
        if(checked) {
          this.selectedDepartments = this.selectedDepartments.filter(d => departmentIds.findIndex(id => d.value == id) < 0)
        } else {
          const addableOptions = this.departmentOptions.filter(d => departmentIds.findIndex(id => d.value == id) > -1)
          addableOptions.forEach(option => {
            if(this.selectedDepartments.findIndex(d => d.value == option.value) < 0) {
              this.selectedDepartments.push(option)
            }
          })
        }
      }
    },

    components: {
      BootstrapInputField,
      CustomCheckbox,
      MultipleDepartmentsSelect
    }
  }
</script>
