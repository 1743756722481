<template>
  <div>
    <div v-if="surveysLength === 0" class="w-100">
      <div class="empty-notification">
        <img :src="image">
        <h4 class="empty-title font-weight-bold">{{t('components.ad_hoc_surveys.index_page.no_data')}}</h4>
        <div class="info-text">{{t('components.ad_hoc_surveys.index_page.no_data_descr')}}</div>
        <router-link
          class="btn btn-warning my-4"
          :to='{name: "newSurvey"}'
        >{{t('components.ad_hoc_surveys.index_page.new_survey')}}</router-link>
      </div>
    </div>
    <div v-else>
      <router-link class="link-with-icon" :to='{name: "newSurvey"}'>
        <i class="fa fa-plus-circle fa-3x"/>
        {{t('components.ad_hoc_surveys.index_page.new_survey')}}
      </router-link>
      <surveys-table/>
    </div>
  </div>
</template>
<script>
import SurveysTable from "./SurveysTable";
import BootstrapInputField from "../utilityComponents/BootstrapInputField";
import img from "./images/ahs-data-icon.svg";

export default {
  data() {
    return {
      image: img
    };
  },

  components: {
    SurveysTable,
    BootstrapInputField
  },

  computed: {
    surveysLength() {
      return this.$store.state.surveys.length;
    }
  }
};
</script>

<style>
.empty-notification {
  text-align: center;
  margin-top: 25vh;
}

.empty-title {
  margin: 10px 0 10px 0;
}
</style>
