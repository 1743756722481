<template>
  <div class='chart-block-inner-container'>
    <div class='row'>
      <div class='col-md-6'>
        <div class='time-period-select'>
          <span v-for='period in periodFilters' class='pointer' :class='{active: period.active}' @click='changeTimePeriod(period)'>
            {{period.label}}
          </span>
        </div>
        <div class='chart-container'>
          <multi-line-chart
            :data-sets='filteredLineChartData'
            :labels='filteredLabels'
            :benchmark-value='benchmark'/>
        </div>
      </div>
      <div class='col-md-6'>
        <div class='toggle-actions'>
          <span class='action-item' @click='toggleStateAll(true)'>
            {{t('components.analysis.components.line_chart_block.turn_all_on')}}
          </span>
          <span class='action-item' @click='toggleStateAll(false)'>
            {{t('components.analysis.components.line_chart_block.turn_all_off')}}
          </span>
        </div>
        <div class="department-switch" :class='{disabled: !totalsDepartment.isChecked}'>
          <label class="switch small">
            <input :checked='totalsDepartment.isChecked' @change='toggleDepartmentChecked(totalsDepartment.id)' type="checkbox" class="switch-input">
            <span class="switch-label"></span>
            <span class="switch-handle"></span>
          </label>
          <div class='department-value' :style='{backgroundColor: totalsDepartment.color}'>
            {{parseInt(totalsDepartment.value) || 0}}
          </div>
          <div class='department-name'>
            {{totalsDepartment.name || t('analysis.summary.index.whole_company')}}
          </div>
        </div>
        <div class='department-container'>
          <h6>
            {{t('components.analysis.components.line_chart_block.teams')}}
          </h6>
          <div class="row">
            <div v-for='department in departments' class='col-md-6'>
              <div class="department-switch" :class='{disabled: !department.isChecked}'>
                <label class="switch small">
                  <input :checked='department.isChecked' @change='toggleDepartmentChecked(department.id)' type="checkbox" class="switch-input">
                  <span class="switch-label"></span>
                  <span class="switch-handle"></span>
                </label>
                <div class='department-value' :style='{backgroundColor: department.color}'>
                  {{parseInt(department.value)}}
                </div>
                <div class='department-name'>
                  {{department.name}}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class='analysis-group-container'>
          <h6>
            {{t('components.analysis.components.line_chart_block.analysis_groups')}}
          </h6>
          <div class="row">
            <div v-for='analysisGroup in analysisGroups' class='col-md-6'>
              <div class="department-switch" :class='{disabled: !analysisGroup.isChecked}'>
                <label class="switch small">
                  <input :checked='analysisGroup.isChecked' @change='toggleAnalysisGroupChecked(analysisGroup.id)' type="checkbox" class="switch-input">
                  <span class="switch-label"></span>
                  <span class="switch-handle"></span>
                </label>
                <div class='department-value' :style='{backgroundColor: analysisGroup.color}'>
                  {{parseInt(analysisGroup.value)}}
                </div>
                <div class='department-name'>
                  {{analysisGroup.name}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import MultiLineChart from '../../charts/MultiLineChart'

  export default {
    computed: {
      departments() {
        return this.$store.state.departments.filter(d => !!d.id)
      },

      analysisGroups() {
        return this.$store.state.analysisGroups.filter(g => !!g.id)
      },

      benchmark() {
        return this.$store.state.benchmark
      },

      filteredLabels() {
        return this.$store.getters.filteredLabels
      },

      totalsDepartment() {
        return this.$store.state.departments.find(d => !d.id) || {}
      },

      totalsAnalysisGroup() {
        return this.$store.state.analysisGroups.find(g => !g.id) || {}
      },

      filteredLineChartData() {
        return this.$store.getters.filteredLineChartData || []
      },

      periodFilters() {
        return this.$store.state.timePeriodFilters
      }
    },
    methods: {
      toggleDepartmentChecked(department_id) {
        this.$store.dispatch('toggleDepartmentChecked', department_id)
      },

      toggleAnalysisGroupChecked(analysis_group_id) {
        this.$store.dispatch('toggleAnalysisGroupChecked', analysis_group_id)
      },

      toggleStateAll(state) {
        this.$store.state.departments.forEach( (d) => {
          if (d.isChecked != state) {
            this.$store.dispatch('toggleDepartmentChecked', d.id)
          }
        })
        this.$store.state.analysisGroups.forEach( (g) => {
          if (g.isChecked != state) {
            this.$store.dispatch('toggleAnalysisGroupChecked', g.id)
          }
        })
      },

      changeTimePeriod(timePeriod) {
        this.$store.commit('changeTimePeriod', timePeriod)
      }
    },
    components: { MultiLineChart }
  }
</script>
