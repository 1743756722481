<template>
  <div class='stage-form user-form'>
    <bootstrap-input-field
      :placeholder="t('activerecord.attributes.user.first_name')"
      v-model='user.first_name'
      input-name='user[first_name]'
      :input-id='inputId("first_name")'
      :errors='user.errors.first_name'
    />
    <bootstrap-input-field
      :placeholder="t('activerecord.attributes.user.last_name')"
      v-model='user.last_name'
      input-name='user[last_name]'
      :input-id='inputId("last_name")'
      :errors='user.errors.last_name'
    />
    <bootstrap-input-field
      :placeholder="t('activerecord.attributes.user.email')"
      v-model='user.email'
      input-name='user[email]'
      :input-id='inputId("email")'
      :errors='user.errors.email'
    />
    <bootstrap-select
      :placeholder="t('activerecord.attributes.user.department')"
      v-model='user.department_id'
      :options='departmentOptions'
      :clearable='true'
      :input-id='inputId("department_id")'
      :errors='user.errors.department_id'
    />

    <bootstrap-select
      :placeholder="t('activerecord.attributes.user.locale')"
      v-model='user.locale'
      :options='localeOptions'
      :clearable='false'
      :input-id='inputId("locale")'
      :errors='user.errors.locale'
    />
    <div class='button-holder'>
      <a href='#' class='onboarding-add-button' @click.prevent='addUser' data-action='save-user'>
        <div class="onboarding-add-button-icon-holder">
        <i class='fa fa-plus'></i>
        </div>
        {{t('components.onboarding.add')}}
      </a>
    </div>
  </div>
</template>

<script>
  import BootstrapInputField from '../utilityComponents/BootstrapInputField'
  import BootstrapSelect from '../utilityComponents/BootstrapSelect'

  export default {
    data() {
      return {
        user: this.initializeUser()
      }
    },

    computed: {
      departmentOptions() {
        return this.$store.getters.visibleDepartments.map( d => {
          return { label: d.name, value: d.id }
        })
      },

      localeOptions() {
        return this.$store.state.localeOptions
      }
    },

    methods: {
      inputId(name) {
        return `user_${name}`
      },

      addUser() {
        this.$store.dispatch('persistUser', this.user)
        .then(() => this.resetUserInputs())
        .catch(({ response }) => this.user.errors = response.data )
      },

      initializeUser() {
        let emptyUser = {
          first_name: '',
          last_name: '',
          email: '',
          department_id: null,
          locale: 'en',
          errors: {}
        }
        // Leave last locale used
        emptyUser.locale = this.user ? this.user.locale : 'en'
        return emptyUser
      },

      resetUserInputs() {
        this.user = this.initializeUser()
      }
    },

    components: {
      BootstrapSelect,
      BootstrapInputField
    }
  }
</script>
