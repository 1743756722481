import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
Vue.use(Vuex)
const AUTH_TOKEN = $('[name="csrf-token"]')[0].content
import { uniqueId } from 'lodash'

export default new Vuex.Store({
  state: {
    currentStage: 0,
    users: [],
    managers: [],
    departments: [],
    localeOptions: [],
    startingStage: 0,
    reallySkipScreenVisible: false
  },

  getters: {
    visibleDepartments(state) {
      return state.departments.filter(d => !d.destroyed)
    },

    saveableManagers(state) {
      return state.managers.filter(u => {
        return u.first_name || u.last_name || u.email
      })
    }
  },

  mutations: {
    setLocaleOptions(state, localeOptions) {
      state.localeOptions = localeOptions
    },

    showReallySkipScreen(state) {
      state.reallySkipScreenVisible = true
    },

    hideReallySkipScreen(state) {
      state.reallySkipScreenVisible = false
    },

    setStartingStage(state, stageNumber) {
      state.startingStage = stageNumber
      state.currentStage = stageNumber
    },

    removeUser(state, user) {
      const userIndex = state.users.findIndex(u => u.id  == user.id)
      state.users.splice(userIndex, 1)
    },

    removeManager(state, manager) {
      const managerIndex = state.managers.findIndex(m => m.id  == manager.id)
      state.managers.splice(managerIndex, 1)
    },

    removeDepartment(state, department) {
      const departmentIndex = state.departments.findIndex((d) => d.id == department.id)
      state.departments.splice(departmentIndex, 1)
    },

    moveToPreviousStage(state) {
      state.currentStage -= 1
    },

    moveToSpecificStage(state, stageNumber) {
      state.currentStage = stageNumber
    },

    adjustUserDepartments(state, department) {
      // Nullify user department_id that was destroyed
      let adjustableUsers = state.users.filter(u => u.department_id == department.id)
      adjustableUsers.forEach( user => {
        user.department_id = null
        user.department_name = null
      })
    }
  },

  actions: {
    moveToNextStage({ state, dispatch }) {
      state.currentStage += 1
      if(state.startingStage < state.currentStage) {
        axios({
          url: '/onboarding',
          method: 'PUT',
          data: {
            authenticity_token: AUTH_TOKEN,
            onboarding: { onboarding_stage: state.currentStage}
          }
        })
      }
    },

    fetchDepartments({ state }) {
      axios.get('/admin/departments.json')
      .then(({ data }) => state.departments = data )
    },

    persistDepartment({ state }, department) {
      return new Promise((resolve, reject) => {
        axios({
          url: '/admin/departments',
          method: 'POST',
          data: {
            authenticity_token: AUTH_TOKEN,
            department: department
          }
        })
        .then(({ data }) => {
          state.departments.push(data)
          resolve()
        })
        .catch(reject)
      })
    },

    deleteDepartment({ commit }, department) {
      return new Promise((resolve, reject) => {
        axios({
          url: '/admin/departments/'+ department.id,
          method: 'DELETE',
          data: { authenticity_token: AUTH_TOKEN }
        })
        .then(({ data }) => {
          commit('removeDepartment', department)
          commit('adjustUserDepartments', department)
        })
        .catch(reject)
      })
    },

    fetchUsers({ state }) {
      axios.get('/admin/users.json').then(({ data }) => state.users = data )
    },

    persistUser({ state, getters }, user) {
      return new Promise((resolve, reject) => {
        axios({
          url: '/admin/users',
          method: 'POST',
          data: {
            authenticity_token: AUTH_TOKEN,
            user: user
          }
        })
        .then(({ data }) => {
          state.users.push(data)
          resolve()
        })
        .catch(reject)
      })
    },

    deleteUser({ commit }, user) {
      return new Promise((resolve, reject) => {
        axios({
          url: '/admin/users/'+ user.id,
          method: 'DELETE',
          data: { authenticity_token: AUTH_TOKEN }
        })
        .then(({ data }) => commit('removeUser', user))
        .catch(reject)
      })
    },

    fetchManagers({ state }) {
      axios.get('/admin/managers.json')
      .then((response) => {
        state.managers = response.data
      })
    },

    persistManager({ state }, manager) {
      return new Promise((resolve, reject) => {
        axios({
          url: '/admin/managers/',
          method: 'POST',
          data: {
            authenticity_token: AUTH_TOKEN,
            manager: manager
          }
        })
        .then(({ data }) => {
          state.managers.push(data)
          resolve()
        })
        .catch(reject)
      })
    },

    deleteManager({ commit }, manager) {
      return new Promise((resolve, reject) => {
        axios({
          url: '/admin/managers/'+ manager.id,
          method: 'DELETE',
          data: { authenticity_token: AUTH_TOKEN }
        })
        .then(({ data }) => commit('removeManager', manager))
        .catch(reject)
      })
    },

    finishOnboarding({}, sendSurvey) {
      return new Promise((resolve, reject) => {
        axios({
          url: '/onboarding/finish',
          method: 'POST',
          data: {
            authenticity_token: AUTH_TOKEN,
            send_survey: sendSurvey
          }
        })
        .then(() => {
          resolve()
          window.location = '/'
        })
        .catch(reject)
      })
    }
  }
})
