import Chart from 'chart.js'
import { clone } from 'lodash'

Chart.defaults.LabeledDoughnut = clone(Chart.defaults.doughnut)
Chart.defaults.LabeledDoughnut.tooltips = { enabled: false }
Chart.defaults.LabeledDoughnut.legend = { display: false }

class LabeledDoughnut extends Chart.controllers.doughnut{
  initialize (chart, datasetIndex) {
    chart.config.data.datasets[0].borderWidth = 0
    chart.options.cutoutPercentage = 70
    chart.options.legendCallback = this.legendCallback
    Chart.controllers.doughnut.prototype.initialize.call(this, chart, datasetIndex)
  }

  getCenterCoordinates () {
    let chart = this.chart
    let chartArea = chart.chartArea
    let opts = chart.options
    let arcOpts = opts.elements.arc
    let availableWidth = chartArea.right - chartArea.left - arcOpts.borderWidth
    let availableHeight = chartArea.bottom - chartArea.top - arcOpts.borderWidth
    return [ availableWidth / 2 , availableHeight / 2]
  }

  legendCallback (chart) {
    let text = [];
    let data = chart.data
    let datasets = data.datasets
    let values = datasets[0].data
    let labels = data.labels

    if (datasets.length) {
      for (let i = 0; i < datasets[0].data.length; ++i) {
        text.push(
          `<div class="label-container">
            <div class="color-box" style="background-color:${datasets[0].backgroundColor[i]}"></div>
            <div class='label'>${values[i]}% ${labels[i]}</div>
          </div>`
        )
      }
    }

    text.push('</div>');
    return text.join('');
  }

  draw (ease) {
    let chart = this.chart
    let meta = this.getMeta()
    let chartArea = chart.chartArea
    let ctx = chart.chart.ctx
    let coordinates = this.getCenterCoordinates()
    let x = coordinates[0]
    let y = coordinates[1]
    let value = chart.config.data.mainValue


    Chart.controllers.doughnut.prototype.draw.call(this, ease)
  }
}
Chart.controllers.LabeledDoughnut = LabeledDoughnut
