<template>
  <div class="questions-list">
    <draggable :list="questions" handle=".move-icon" animation="150">
      <transition-group>
        <div v-for="(question, i) in questions" v-bind:key="i + 0" class="question-inputs">
          <div class="row">
            <div class="w-100">
              <div class="main-inputs">
                <div class="move-icon" v-if="!blockRemoval">
                  <i class="fa fa-th fa-2x"></i>
                </div>
                <div class="question-number">{{"Question " + (i + 1)}}</div>
                <div class="title-input">
                  <bootstrap-input-field
                    placeholder="Question title"
                    v-model="question.title"
                    inputName="ahsq[title]"
                    :inputId="`ahsq_${i}_title`"
                    :errors='errors && errors[i] && (errors[i].title && [t("components.ad_hoc_surveys.survey.blank")])'
                  />
                </div>
                <div class="type-input">
                  <bootstrap-select
                    placeholder="Choose answer"
                    v-model="question.type"
                    :options="questionOptions"
                    inputName="ahsq[type]"
                    :inputId="`ahsq_${i}_type`"
                    :errors='errors && errors[i] && (errors[i].type && [t("components.ad_hoc_surveys.survey.blank")])'
                  />
                </div>
                <div class="option-input" v-if="!blockRemoval">
                  <div
                    :data-action="`remove_question_${i}`"
                    class="delete-icon"
                    @click="removeQuestion(i)"
                  >
                    <i class="fa fa-times"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <question-custom-input
            v-if='question.type == "labeled_step_slider_1" ||
            question.type == "labeled_step_slider_2"||
            question.type =="step_slider"'
            v-bind:options="defaultOptions[question.type]"
            v-bind:disabled="true"
            v-bind:questionIndex="i"
            @clearErrors="clearErrors"
          />
          <question-custom-input
            v-if='question.type === "custom"'
            v-bind:options="question.question_options"
            v-bind:errors="errors[i] && errors[i].question_options"
            v-bind:disabled="false"
            v-bind:questionIndex="i"
            @clearErrors="clearErrors"
          />
        </div>
      </transition-group>
    </draggable>
    <div data-action="add-question" class="link-with-icon pointer mt-3" @click="addQuestion()">
      <i class="fa fa-plus-circle fa-3x"/> Add question
    </div>
  </div>
</template>

<script>
import BootstrapInputField from "../utilityComponents/BootstrapInputField";
import QuestionCustomInput from "./QuestionCustomInput";
import draggable from "vuedraggable";

export default {
  name: "questionForm",
  props: ["questions", "errors", "defaultOptions"],
  data() {
    return {
      blockRemoval: true,
      questionOptions: [
        { value: "labeled_step_slider_1", label: "Disagree - Agree" },
        { value: "labeled_step_slider_2", label: "Never - Always" },
        { value: "step_slider", label: "Scale 1 to 5" },
        { value: "open_ended", label: "Open ended" },
        { value: "custom", label: "Custom options" }
      ]
    };
  },
  components: {
    BootstrapInputField,
    QuestionCustomInput,
    draggable
  },
  watch: {
    questions() {
      this.blockRemoval = this.questions.length <= 1;
    }
  },
  methods: {
    clearErrors() {
      this.$emit("clearErrors");
    },
    addQuestion() {
      this.clearErrors();
      let checkEmptyQuestions = this.questions.filter(
        question => question.title === null
      );

      if (checkEmptyQuestions.length >= 1 && this.questions.length > 0) return;

      this.questions.push({
        title: "",
        type: "",
        question_options: []
      });
    },

    removeQuestion(questionId) {
      this.clearErrors();
      if (!this.blockRemoval) this.questions.splice(questionId, 1);
    }
  },
  mounted() {
    this.addQuestion();
  }
};
</script>
<style>
.move-icon {
  cursor: move;
  padding-right: 10px;
}
</style>
