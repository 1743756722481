<template>
  <div class='card card-dashboard'>
    <div class='card-body'>
      <div class='header'>
        {{title}}
        <div v-if='description' class='f-right tooltip-icon'>
          <i v-tooltip='{content: description}' class='fa fa-question-circle'></i>
        </div>
      </div>
      <div class='body'>
        <template v-if='canCreateChart'>
          <div class='row'>
            <div class='col-12'>
              <div class='row'>
                <div class="col-md-5 offset-md-1">
                  <canvas :id='canvasId' class='labeled-doughnut' :class='moodClass'></canvas>
                </div>
                <div class="col-md-5">
                  <div class="labeled-doughnut-value-wrapper">
                    <span class="actual-value">{{parseInt(value)}}</span>
                    <span class="max-value">-100 / 100</span>
                  </div>
                </div>
              </div>
            </div>
            <div class='col-12 legend-container'></div>
          </div>
        </template>
        <template v-else>
          <div class='row'>
            <div class='no-data mt-5 pt-2'>
              <div class="empty-chart-icon"></div>
              {{t('not_enough_data')}}
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
  import LabeledDoughnut from './prototypes/LabeledDoughnut'
  import { uniqueId } from 'lodash'
  import { npsMoodName } from '../helpers/chart_helpers'

  export default {
    props: ['title', 'labelData', 'value', 'description'],
    mounted() {
      if(this.canCreateChart) {
        new LabeledDoughnut($(`#${this.canvasId}`), this.labelData, this.value )
      }
    },
    computed: {
      canCreateChart() {
        return this.value != null
      },
      moodClass() {
        return npsMoodName(this.value)
      }
    },
    data() {
      return {
        canvasId: `labeled-doughnut-${uniqueId()}`
      }
    }


  }
</script>
