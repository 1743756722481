<template>
  <div class='row'>
    <table class='table mt-3 surveys-table'>
      <thead>
        <tr>
          <th>{{t('activerecord.attributes.ad_hoc_survey.name')}}</th>
          <th>{{t('activerecord.attributes.ad_hoc_survey.questions')}}</th>
          <th>{{t('activerecord.attributes.ad_hoc_survey.responses')}}</th>
          <th>{{t('activerecord.attributes.ad_hoc_survey.response_rate')}}</th>
          <th>{{t('activerecord.attributes.ad_hoc_survey.status')}}</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-if='loading'>
          <td colspan='5'>
            <loading-indicator  :visible='true' />
          </td>
        </tr>
        <survey-row
          v-else
          v-for='survey in surveys'
          :survey='survey'
          @onSendSurvey='openModal'
          :key='survey.id'/>
      </tbody>
    </table>

    <send-survey-modal
      v-if='modalOpen'
      :survey='modalSurvey'
      @update='closeModal'
      @close='closeModal'/>
  </div>
</template>
<script>
  import LoadingIndicator from '../utilityComponents/LoadingIndicator'
  import SurveyRow from './SurveyRow'
  import SendSurveyModal from './SendSurveyModal'

  export default {
    computed: {
      loading() {
        return this.$store.state.loading
      },

      surveys() {
        return this.$store.state.surveys
      }
    },

    data() {
      return {
        modalOpen: false,
        modalSuggestion: null
      }
    },

    methods: {
      openModal(survey) {
        this.modalOpen = true
        this.modalSurvey = survey
      },

      closeModal() {
        this.modalOpen = false
      },

      sendSurvey(survey) {
        // alert(survey.name)
      }
    },

    components: {
      LoadingIndicator,
      SurveyRow,
      SendSurveyModal
    }
  }
</script>
