<template>
  <div class='component-block-item question-row'>
    <div class='header-line' @click='toggleExpanded' :class='{opened: expanded}'>
      <div class="row">
        <div class="col-8 d-flex">
          <div class="question-block ml-1">
            <div class='question'>
              {{question.title}}
            </div>
          </div>
        </div>
        <div class='col-4 main-statistics' >
          <div class="question-meta average mr-1">
            <div class='content'>
              <div class='count'>{{value}}</div>/ 100
              <div class='sub-text'>{{t('analysis.components.show.average')}}</div>
            </div>
          </div>
          <div class="question-meta mr-1">
            <div class='content mr-3'>
              <div class='count'>
                {{answers.length}}
              </div>
              <div class='sub-text'>{{t('analysis.components.show.responses')}}</div>
            </div>
          </div>
          <div class="question-meta mr-1">
            <div class='content'>
              <div class='count comments'>
                {{comments.length}}
                <div v-if='unreadCommentsCount > 0' class='unread'>
                  {{ unreadCommentsCount }}
                </div>
              </div>
              <div class='sub-text'>{{t('analysis.components.show.comments')}}</div>
            </div>
            <i class="fa fa-chevron-down"></i>
          </div>
        </div>
      </div>
    </div>
    <div class='answer-list' v-if='expanded'>
      <div v-for='option in options' class='option-row'>
        <div class='row'>
          <div class='col-2'>
            <div class='title float-right'>
              {{option.title}}
            </div>
          </div>
          <div class='progress'>
            <div class='filled' :style='{width: answerPercentage(option) + "%"}'></div>
          </div>
          <div class='col-3'>
            <div class='percentage'>
              {{answerPercentage(option)}}%
            </div>
          </div>
        </div>
      </div>
      <answer-comments-container  v-if='comments.length > 0' :comments='comments' :allExpanded='allExpanded' />
    </div>
  </div>
</template>
<script>
  import AnswerCommentsContainer from './AnswerCommentsContainer'

  export default {
    props: ['question'],

    data() {
      return {
        expanded: false,
        allExpanded: false
      }
    },

    computed: {
      options() {
        if(this.question.question_type == 'step_slider' || this.question.question_type == 'star') {
          const min = this.question.question_type == 'star' ? 1 : this.question.slider_min
          const max = this.question.question_type == 'star' ? 5 : this.question.slider_max
          return Array.from(new Array(max), (val, index) => {
            const value = index + min
            return {
              id: value,
              sequence: value,
              title: value
            }
          })
        } else {
          return this.question.options.sort( o => o.sequence)
        }
      },

      value() {
        const scores = this.answers.map( a => parseFloat(a.value))
        if (scores.length > 0 && this.answers.length > 0) {
          const totalValue = scores.reduce((t, v) => t + v)
          return parseInt(totalValue / this.answers.length);
        } else {
          return 0;
        }
      },

      departmentIds() {
        return this.$store.state.surveyQuestionDepartmentIds;
      },

      answers() {
        const filteredDates = this.$store.getters.filteredQuestionDates
        return this.question.answers
          .filter(a => filteredDates.findIndex(d => d == a.cycle_date) > -1 )
          .filter(a => (
            !this.departmentIds ||
            this.departmentIds.length == 0 ||
            this.departmentIds.findIndex(id => a.department_id === id) > -1
          ))
      },

      comments() {
        return this.answers.filter(a => !!a.comment)
      },

      unreadCommentsCount() {
        return this.comments.filter(c => !c.is_read).length
      }
    },

    methods: {
      answerPercentage(option) {
        const count = this.answers.filter(a => a.option_number == option.sequence).length
        const totalAnswerCount = this.answers.length
        return Math.round(count / totalAnswerCount * 100)
      },

      toggleExpanded() {
        this.expanded = !this.expanded
      },

      toggleComments() {
        this.allExpanded = !this.allExpanded
      }
    },

    components: { AnswerCommentsContainer }
  }
</script>
